import React, { useEffect, useState } from "react";
import { Modal, Button } from 'react-bootstrap'
import { connect } from 'react-redux';
import Select, { components } from "react-select";
import Loader from "../../../../components/Loader";
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { GetStudentPlanId, getConnectedStudents, courseAcessStudent, AddParentAccess, getConnectedFriendSwitch, setChildSwichStatus, courseAcessStudentStatus, courseAcessStudentError, setConnectedStudentStatus, AddStudentAccess, AddStudentAccessStatus, AddStudentAccessErrorStatus, RemoveStudentAccess, RemoveStudentAccessStatus, RemoveStudentAccessErrorStatus } from "../../../../redux/actions";
import "./managestudent.less"
import { useImageUrl } from "../../../../utils/UseImageURL";

const { ValueContainer, Placeholder } = components;
const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>{props.selectProps.placeholder}</Placeholder>
            {React.Children.map(children, child => child && child.type !== Placeholder ? child : null)}
        </ValueContainer>
    );
};

const Index = (props) => {
    const [loading, setloading] = useState(false)
    const [studentOptions, setStudentOptions] = useState([])
    const [selectedStudent, setselectedStudent] = useState(null);

    useEffect(() => {
        setloading(true)
        if (props?.userProfile?.currentRole === "parent") {
            props?.getConnectedFriendSwitch(localStorage.getItem("parentViaChild"))
        } else {
            props?.getConnectedStudents();
        }
        let payload = {
            "planId": props?.data?.planId,
            "courseId": props?.data?.courseName ? props?.data?.id : '',
            "activityId": props?.data?.activityName ? props?.data?.id : '',
        }
        props?.courseAcessStudent(payload)
    }, [])


    useEffect(() => {
        if (props?.accessbleStudentStatus) {
            setloading(false)
            props?.courseAcessStudentStatus(false)
        }
        if (props?.accessbleStudentError) {
            setloading(false)
            props?.courseAcessStudentError(false)
        }
        if (props?.connectedStudentsStatus) {
            props.setConnectedStudentStatus(false)
            setloading(false)
            setIntialvalue()
        }
        if (props?.connectedFriendStatus) {
            setloading(false)
            props?.setChildSwichStatus(false)
            setIntialvalue()
        }
        if (props?.addStudentStatus) {
            props?.getConnectedStudents();
            let payload = {
                "planId": props?.data?.planId,
                "courseId": props?.data?.courseName ? props?.data?.id : '',
                "activityId": props?.data?.activityName ? props?.data?.id : '',
            }
            props?.courseAcessStudent(payload)
            props?.AddStudentAccessStatus(false)
            props?.GetStudentPlanId(props?.data?.planId)
            setselectedStudent(null)
            handleSetSelect()
        }
        if (props?.accessbleStudent) {
            handleSetSelect(props?.accessbleStudent)
        }
        if (props?.addStudentError) {
            props?.getConnectedStudents();
            let payload = {
                "planId": props?.data?.planId,
                "courseId": props?.data?.courseName ? props?.data?.id : '',
                "activityId": props?.data?.activityName ? props?.data?.id : '',
            }
            props?.courseAcessStudent(payload)
            props?.AddStudentAccessErrorStatus(false)
            props?.GetStudentPlanId(props?.data?.planId)
        }
        if (props?.removeStudentStatus) {
            props?.RemoveStudentAccessStatus(false)
            let payload = {
                "planId": props?.data?.planId,
                "courseId": props?.data?.courseName ? props?.data?.id : '',
                "activityId": props?.data?.activityName ? props?.data?.id : '',
            }
            props?.courseAcessStudent(payload)
            props?.GetStudentPlanId(props?.data?.planId)
        }
        if (props?.removeStudentError) {
            setloading(false)
            props?.RemoveStudentAccessErrorStatus(false)
            let payload = {
                "planId": props?.data?.planId,
                "courseId": props?.data?.courseName ? props?.data?.id : '',
                "activityId": props?.data?.activityName ? props?.data?.id : '',
            }
            props?.courseAcessStudent(payload)
            props?.GetStudentPlanId(props?.data?.planId)
        }
    }, [props?.accessbleStudent, props?.accessbleStudentStatus, props?.accessbleStudentError, props?.connectedStudentsStatus,
    props?.addStudentStatus, props?.removeStudentStatus, props?.removeStudentError, props?.connectedFriendStatus])

    const setIntialvalue = () => {
        if (props?.connectedStudents?.length > 0 || props?.connectedStudentSwitch?.length > 0) {
            const accessibleStudentId = props?.accessbleStudent?.map(
                (student) => student?.userProfile?.id
            );
            let filteredStudents;
            if (props?.userProfile?.currentRole === "parent") {
                filteredStudents = props?.connectedStudentSwitch?.filter(
                    (student) => !accessibleStudentId?.includes(student?.id)
                );
            } else {
                filteredStudents = props?.connectedStudents?.filter(
                    (student) => !accessibleStudentId?.includes(student?.id)
                );
            }

            console.log(filteredStudents, "filteredStudents")
            const options = filteredStudents?.map((student) => ({
                label: student?.fullName,
                value: student?.id,
            }));
            console.log(options, "optionsoptions")
            setStudentOptions(options);
            setloading(false);
        }
        else {
            setloading(false);
        }
    }

    const handleSetSelect = () => {
        if (props?.accessbleStudent) {
            const accessibleStudentId = props?.accessbleStudent?.map(
                (student) => student?.userProfile?.id
            );

            let filteredStudents;
            if (props?.userProfile?.currentRole === "parent") {
                filteredStudents = props?.connectedStudentSwitch?.filter(
                    (student) => !accessibleStudentId?.includes(student?.id)
                );
            } else {
                filteredStudents = props?.connectedStudents?.filter(
                    (student) => !accessibleStudentId?.includes(student?.id)
                );
            }
            const options = filteredStudents?.map((student) => ({
                label: student?.fullName,
                value: student?.id,
            }));
            setStudentOptions(options);
        }
    }

    const handleAddStudent = (selectedOption) => {
        setselectedStudent(selectedOption)
        let StudentId = selectedOption?.value
        let payload = {
            "activityIds": [],
            "courseIds": [],
            "planId": props?.data?.planId,
            "studentId": StudentId
        }
        if (props?.detaildata === "Course") {
            payload?.courseIds?.push(props?.data?.id);
        } else if (props?.detaildata === "Activity") {
            payload.activityIds.push(props?.data?.id);
        }
        if (props?.userProfile?.currentRole === "parent") {
            payload["pointOfViewStudentId"] = localStorage.getItem("parentViaChild");
            props?.AddParentAccess(payload)
        } else {
            props?.AddStudentAccess(payload)
        }
        setloading(true)
    }
    const handleRemoveStudent = (e, data) => {        
        e.preventDefault();
        let Payload = {
            "activityIds": [],
            "courseIds": [],
            "planId": data?.planAccess?.planId,
            "studentId": data?.userProfile?.id
        };
        console.log("data", data);
        
        if (props?.data?.courseName) {
            Payload.courseIds.push(...(data?.planAccess?.courseIds || []));
        } else if (props?.data?.activityName) {
            Payload.activityIds.push(...(data?.planAccess?.activityIds || []));
        }
        props?.RemoveStudentAccess(Payload);
    }
    
    const AvatarWithUrl = ({ avatarUrl }) => {
        const imageUrl = useImageUrl(avatarUrl);
        return <img src={imageUrl} alt="profileimage" className="userprofile" />;
    };
    return (
        <Modal {...props} size={'lg'} centered={true}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Student access for {props?.data?.courseName || props?.data?.activityName}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loading && <Loader />}
                <>
                    <div style={{ "padding": "0px 40px" }} >
                        <div className="AccesibleAdvisorList">
                            {props?.accessbleStudent?.length > 0 && props?.accessbleStudent?.map((data, index) => {
                                return (
                                    <div className="AccesibleAdvisorList__card" key={index}>
                                        <div className="AccesibleAdvisorList__card__Usersection">
                                        {data?.userProfile?.avatarPath ? (
                                                        <AvatarWithUrl avatarUrl={data?.userProfile?.avatarPath} />
                                                    ) : (
                                                        <AccountBoxIcon sx={{ fill: 'grey' }} className="userprofile" />
                                                    )}
                                            <p className="username">
                                                <span className="username-text">{data?.userProfile?.fullName}</span>
                                                <span className="icon">
                                                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="9.5" cy="9.5" r="9.5" fill="#F4F5F8" />
                                                        <path d="M14 5H6C5.45 5 5 5.45 5 6V15L7 13H14C14.55 13 15 12.55 15 12V6C15 5.45 14.55 5 14 5ZM14 12H7L6 13V6H14V12Z" fill="#6B6C6D" />
                                                    </svg>
                                                </span>
                                            </p>
                                        </div>
                                        <div className="AccesibleAdvisorList__card__Btnsection">
                                            <Button className="secondary_btn" onClick={(e) => handleRemoveStudent(e, data)}>Remove Access</Button>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div>
                        <div className="addConnectionText" style={{ "display": "flex" }}>
                            <div className="input-floating-label">
                                <Select options={studentOptions} value={selectedStudent} onChange={handleAddStudent} placeholder={"Add Connected Students"} closeMenuOnSelect={true} isClearable={false}
                                    isSearchable={false} classNamePrefix="mySelect"
                                    components={{ ValueContainer: CustomValueContainer, IndicatorSeparator: () => null }}
                                    styles={{ option: (provided, state) => ({ ...provided, cursor: 'pointer', }), container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible" }), valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px' }), placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10, display: state.isFocused || state.isSelected || state.selectProps.inputValue ? 'none' : 'block', }) }} />
                            </div>
                        </div>
                        <div style={{ "display": "flex", "justifyContent": "center", "alignItems": "center", "marginBottom": "30px" }}>
                            <div className="myWork-planCard__ctaSection__flat-btn">
                            </div>
                        </div>
                    </div>
                </>
            </Modal.Body>
        </Modal>
    )
}

const mapStateToProps = ({ commonData, userProfileData, studentData }) => {
    const { connectedStudents, connectedStudentsStatus, addStudentError, addStudentStatus, removeStudentError, removeStudentStatus, connectedStudentSwitch, connectedFriendStatus } = commonData
    const { userProfile } = userProfileData
    const { accessbleStudent, accessbleStudentStatus, accessbleStudentError } = studentData;
    return { userProfile, accessbleStudent, accessbleStudentStatus, accessbleStudentError, connectedStudents, connectedStudentsStatus, addStudentError, addStudentStatus, removeStudentError, removeStudentStatus, connectedStudentSwitch, connectedFriendStatus }
};

export default connect(mapStateToProps, { getConnectedStudents, AddParentAccess, setChildSwichStatus, getConnectedFriendSwitch, courseAcessStudent, courseAcessStudentStatus, GetStudentPlanId, courseAcessStudentError, setConnectedStudentStatus, AddStudentAccess, AddStudentAccessStatus, AddStudentAccessErrorStatus, RemoveStudentAccess, RemoveStudentAccessStatus, RemoveStudentAccessErrorStatus, })(Index);

