export const USER_PROFILE_SUCCESS = 'USER_PROFILE_SUCCESS';
export const USER_PROFILE_DATA = 'USER_PROFILE_DATA';
export const USER_ROLE_SAVED = "USER_ROLE_SAVED";
export const SET_PROFILE_SAVED_STATUS = "SET_PROFILE_SAVED_STATUS";
export const UPDATE_GENERAL_INFO = "UPDATE_GENERAL_INFO";
export const UPDATE_GENERAL_INFO_ERROR = "UPDATE_GENERAL_INFO_ERROR";
export const UPDATE_CURRENT_STATUS = "UPDATE_CURRENT_STATUS";
export const UPDATE_CURRENT_STATUS_ERROR = "UPDATE_CURRENT_STATUS_ERROR";
export const USER_PASSWORD_UPDATE = "USER_PASSWORD_UPDATE";
export const USER_ONBOARDED = 'USER_ONBOARDED';
export const PROFILE_PICTURE_UPDATE_SUCCESS =" PROFILE_PICTURE_UPDATE_SUCCESS";
export const SET_CHILD_PROFILE_SAVED_STATUS = "SET_CHILD_PROFILE_SAVED_STATUS";
export const SENT_INVITE_STATUS = "SENT_INVITE_STATUS";
export const GET_INVITE_SPOUSE = "GET_INVITE_SPOUSE";
export const VERIFY_INVITES = "VERIFY_INVITES";
export const VERIFY_INVITES_STATUS = "VERIFY_INVITES_STATUS";
export const VERIFY_INVITES_STATUS_DATA = "VERIFY_INVITES_STATUS_DATA";
export const COPY_INVITE_LINK = "COPY_INVITE_LINK";
export const USER_CHILD_PROFILE_SUCCESS = "USER_CHILD_PROFILE_SUCCESS";
export const GET_INVITE_PARENT_STATUS = "GET_INVITE_PARENT_STATUS";
export const GET_INVITE_PARENT = "GET_INVITE_PARENT";
export const SENT_INVITE_ERROR_STATUS = "SENT_INVITE_ERROR_STATUS"
export const USER_CHILD_PROFILE_STATUS = "USER_CHILD_PROFILE_STATUS"
export const USER_PASSWORD_UPDATE_ERROR = "USER_PASSWORD_UPDATE_ERROR"
export const USER_PARENT_PROFILE_SUCCESS ="USER_PARENT_PROFILE_SUCCESS"
export const SET_PROFILE_SAVED_STATUS_ERROR = "SET_PROFILE_SAVED_STATUS_ERROR"
export const SET_CHILD_EDIT_PROFILE_SAVED_STATUS = "SET_CHILD_EDIT_PROFILE_SAVED_STATUS"
export const USER_PROFILE_SUCCESS_STATUS = "USER_PROFILE_SUCCESS_STATUS"
export const SET_CHILD_PROFILE_ERROR_STATUS = "SET_CHILD_PROFILE_ERROR_STATUS"
export const SET_CHILD_EDIT_PROFILE_ERROR_STATUS = "SET_CHILD_EDIT_PROFILE_ERROR_STATUS"
export const PROFILE_PICTURE_SWITCH_SUCCESS = "PROFILE_PICTURE_SWITCH_SUCCESS"
export const COPY_INVITE_LINK_STATUS = "COPY_INVITE_LINK_STATUS"
export const DISCONNECT_USER = "DISCONNECT_USER"
export const COPY_INVITE_ERROR_STATUS = "COPY_INVITE_ERROR_STATUS"
export const PROFILE_PICTURE_SWITCH_DELETE = "PROFILE_PICTURE_SWITCH_DELETE"
export const DISCONNECTED_FRIEND_SWITCH = "DISCONNECTED_FRIEND_SWITCH"
export const USER_CHILD_PROFILE_USER_ACTIVITY = "USER_CHILD_PROFILE_USER_ACTIVITY"
export const STUDENT_COURSE_ACTIVITY_STATUS = "STUDENT_COURSE_ACTIVITY_STATUS"
export const STUDENT_PUBLIC_COURSE_ACTIVITY_DATA = "STUDENT_PUBLIC_COURSE_ACTIVITY_DATA"