import React from "react";
import { BrowserRouter, Routes, Route, Navigate, useLocation } from "react-router-dom";

import DashboardLayout from '../layoutsNew/Student/DashboardLayout';
import DefaultLayout from '../layoutsNew/Student/DefaultLayout';
import { RequireAuth } from "../utils/AuthUtils"

/*import Student pages */
import Dashboard from "../pages/Student/Dashboard/index";
import MyWork from "../pages/Student/MyWork";
import PhaseDetails from "../pages/Student/MyWork/Phase"
import CourseMilestone from "../pages/Student/MyWork/MilestonePage/courseMilestone"
import ActivityMilestone from "../pages/Student/MyWork/MilestonePage/activityMilestone"
import MyNetwork from "../pages/Student/MyNetwork";
import ExploreAdvisor from "../pages/Student/MyNetwork/ExploreAdvisorNew";
import ExploreStudent from "../pages/Student/MyNetwork/ExploreStudentsNew";
import Library from "../pages/Student/Library";
import Schedule from "../pages/Student/Schedule";
import Notification from "../pages/Student/Notification";
import New_Profile from "../pages/Student/Profile"
import Profile from "../pages/Student/UserProfile"

const StudentRoutes = () => (
  <Routes>
    {/* STUDENT DASHBOARD */}
    <Route exact path="student" element={<DashboardLayout />} >
      <Route path="dashboard" element={<RequireAuth><Dashboard /></RequireAuth>} />
    </Route>
    {/* Student otherpages */}
    <Route exact path="student" element={<DefaultLayout />} >
      <Route path="mywork" element={<RequireAuth><MyWork /></RequireAuth>} />
      <Route path="mywork/plan/:id" element={<RequireAuth><PhaseDetails /></RequireAuth>} />
      <Route path=":id/mywork" element={<RequireAuth><MyWork /></RequireAuth>} />
      <Route path=":id/mywork/plan/:id" element={<RequireAuth><PhaseDetails /></RequireAuth>} />
      <Route path=":id/mywork/plan/:id/courseMilestone/:courseId" element={<RequireAuth><CourseMilestone /></RequireAuth>} />
      <Route path=":id/mywork/plan/:id/activityMilestone/:activityId" element={<RequireAuth><ActivityMilestone /></RequireAuth>} />
      <Route path="mywork/plan/:id/courseMilestone/:courseId" element={<RequireAuth><CourseMilestone /></RequireAuth>} />
      <Route path="mywork/plan/:id/activityMilestone/:activityId" element={<RequireAuth><ActivityMilestone /></RequireAuth>} />
      <Route path="mynetwork" element={<RequireAuth><MyNetwork /></RequireAuth>} />
      <Route path="mynetwork/exploreAdvisor" element={<RequireAuth><ExploreAdvisor /></RequireAuth>} />
      <Route path="mynetwork/exploreStudent" element={<RequireAuth><ExploreStudent /></RequireAuth>} />
      <Route path="library" element={<RequireAuth><Library /></RequireAuth>} />
      <Route path="schedule" element={<RequireAuth><Schedule /></RequireAuth>} />
      <Route path="notification" element={<RequireAuth><Notification /></RequireAuth>} />
      <Route path="myprofile" element={<RequireAuth><New_Profile /></RequireAuth>} />
      <Route path="profile/:id" element={<RequireAuth><Profile /></RequireAuth>} />
    </Route>
  </Routes>
);

export default StudentRoutes;