import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { clearMessage, getChildProfile, bookSession, bookPaidSession, bookSessionStatus, bookPaidSessionErrorStatus, getUserCount, bookPaidSessionStatus } from '../../../redux/actions'
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import CarouselComponent from './CarouselComponent';
import { Card } from '@mui/material';
import PillSection from "../../../componentsNew/Common/PillSection"
import { Button } from "react-bootstrap";
import "./dashboard.less";
import ScheduleCard from "../../../componentsNew/Dashboard/ScheduleListCard";

const Index = (props) => {
    const pillsData = ["Standford Universities", "Abcott College", "MIT College",]
    const advisorActivities = props?.isgetusergoals?.activities || [];
    const advisorCourses = props?.isgetusergoals?.courses || [];

    const combinedData = [...advisorActivities, ...advisorCourses];

    const mappedData = combinedData.map(item => {
        if (item) {
            return item;
        }
        return null;
    });

    const scheduleData = [
        { id: 1, title: 'Session with Brian', time: '10:00 AM - 11:00 AM' },
        { id: 2, title: 'Meeting with Team', time: '12:00 PM - 01:00 PM' },
        { id: 3, title: 'Client Call', time: '03:00 PM - 04:00 PM' },
    ];


    return (
        <>
            {/* <div className="studentDashboard">
                <div className="dashboard-top-text">
                    <h2>Welcome {props.userProfile && props.userProfile.firstName}! </h2>
                    <p className="mb-4 dash-subText"><span>Your next steps👇  </span></p>
                    <h3 className="dashTitle">Build your network and follow updates  🕸️</h3>
                </div>
                <div> <CarouselComponent data={[]} /> </div>
                <div>
                    <h6 className="dashTitle mb-2">Set your goals ! We recommend the best advisors for you based on these goals 👇</h6>
                    <div>
                        <div class="row">
                            <div className="col-md-6">
                                <Card variant="outlined" sx={{ textAlign: 'center', borderRadius: "10px" }}>
                                    <div className="dashSubContainer" >
                                        <h6 className="dashTitle" style={{ textAlign: 'center' }}>
                                            Set your target universities 🎯
                                        </h6>
                                        <Button className="btn cta--rounded cta-primary" style={{ borderRadius: '10px', marginRight: '1rem' }}>
                                            <span style={{ paddingLeft: '20px', paddingRight: '20px' }}>Update</span>
                                        </Button>
                                    </div>
                                    <div className="p-3">
                                        <PillSection data={props?.isgetusergoals?.universities} maxWidth={500} maxHeight={75} />
                                    </div>
                                </Card>
                            </div>

                            <div class="col-md-6">
                                <Card variant="outlined" sx={{ textAlign: 'center', borderRadius: "10px" }}>
                                    <div className="dashSubContainer" >
                                        <h6 className="dashTitle" style={{ textAlign: 'center' }}>
                                            Set your career goals 🎯
                                        </h6>
                                        <Button className="btn cta--rounded cta-primary" style={{ borderRadius: '10px', marginRight: '1rem' }}>
                                            <span style={{ paddingLeft: '20px', paddingRight: '20px' }}>Update</span>
                                        </Button>
                                    </div>
                                    <div className="p-3">
                                        <PillSection data={props?.isgetusergoals?.professions} maxWidth={369} maxHeight={75} />
                                    </div>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-3">
                    <Card variant="outlined" sx={{ textAlign: 'center', borderRadius: "10px" }}>
                        <div className="dashSubContainer" >
                            <h6 className="dashTitle" style={{ textAlign: 'center' }}>
                                Set your courses and Activities 🎯
                            </h6>
                            <Button className="btn cta--rounded cta-primary" style={{ borderRadius: '10px', marginRight: '1rem' }}>
                                <span style={{ paddingLeft: '20px', paddingRight: '20px' }}>Update</span>
                            </Button>
                        </div>
                        <div className="p-3">
                            <PillSection data={mappedData} maxWidth={1000} maxHeight={75} />
                        </div>
                    </Card>
                </div>
                <div className="mt-3">
                    <div className="dashSubContainer" >
                        <h6 className="dashTitle" style={{ textAlign: 'left' }}>
                            Connect with advisors who have achieved your goals🚀
                        </h6>
                        <Button className="btn cta--rounded cta-primary" style={{ borderRadius: '10px', marginRight: '1rem' }}>
                            <span >Invite an advisor</span>
                        </Button>
                        <Button className="btn cta--rounded cta-primary" style={{ borderRadius: '10px', marginRight: '1rem' }}>
                            <span >Your Advisors</span>
                        </Button>
                        <Button className="btn cta--rounded cta-primary" style={{ borderRadius: '10px', }}>
                            <span >Explore Advisors</span>
                        </Button>
                    </div>
                    <CarouselComponent
                        data={[]}
                    />
                </div>
                <div className="mt-3">
                    <div className="dashSubContainer" >
                        <h6 className="dashTitle" style={{ textAlign: 'left' }}>
                            Connect with students with similar goals 🎓
                        </h6>
                        <Button className="btn cta--rounded cta-primary" style={{ borderRadius: '10px', marginRight: '1rem' }}>
                            <span >Invite a Friend</span>
                        </Button>
                        <Button className="btn cta--rounded cta-primary" style={{ borderRadius: '10px', marginRight: '1rem' }}>
                            <span >Your Friends</span>
                        </Button>
                        <Button className="btn cta--rounded cta-primary" style={{ borderRadius: '10px', }}>
                            <span >Explore Students</span>
                        </Button>
                    </div>
                    <CarouselComponent
                        data={[]}
                    />
                </div>
                <div>
                    <div class="row">
                        <div className="col-md-6">
                            <Card variant="outlined" sx={{ textAlign: 'center', borderRadius: "10px" }}>
                                <h6 className="dashTitle mt-3" style={{ textAlign: 'center' }}>
                                    Schedule available advisor sessions  🗓️
                                </h6>

                                <div className="p-3">
                                    {scheduleData.map((schedule) => (
                                        <ScheduleCard key={schedule.id} title={schedule.title} time={schedule.time} />
                                    ))}
                                </div>
                            </Card>
                        </div>

                        <div class="col-md-6">
                            <Card variant="outlined" sx={{ textAlign: 'center', borderRadius: "10px" }}>
                                <h6 className="dashTitle  mt-3" style={{ textAlign: 'center' }}>
                                    Scheduled sessions  🗓️
                                </h6>
                                <div className="p-3">
                                    {scheduleData.map((schedule) => (
                                        <ScheduleCard key={schedule.id} title={schedule.title} time={schedule.time} />
                                    ))}
                                </div>
                                <Button className="btn cta--rounded cta-primary mb-3" style={{ borderRadius: '10px', }}>
                                    <span >View schedule</span>
                                </Button>
                            </Card>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="studentDashboard">
                <div className="studentDashboard_Mobile">
                    <div className="studentDashboard_Mobile_Section">
                        <div className="studentDashboard_Mobile_Section_Title">
                            <p>Dashboard</p>
                        </div>
                    </div>
                </div>
                <div className="studentDashboard_Dashboard">
                    <div className="studentDashboard_Dashboard_TopText">
                        <h2>Welcome {props.userProfile && props.userProfile.firstName}! </h2>
                        <p className="studentDashboard_Dashboard_SubText"><span>Your next steps 👇</span></p>
                    </div>
                    <div className="studentDashboard_Dashboard_NetworkCarousel">
                        <h3 className="studentDashboard_Dashboard_Title">Build your network and follow updates 🕸️</h3>
                        <CarouselComponent data={[]} />
                    </div>
                    <div className="studentDashboard_Dashboard_GoalsCarousel">
                        <h6 className="studentDashboard_Dashboard_Title">Set your goals ! We recommend the best advisors for you based on these goals 👇</h6>
                        <div class="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                <Card variant="outlined" sx={{ textAlign: 'center', borderRadius: "10px", marginBottom: "10px" }}>
                                    <div className="CardTitle_Section" >
                                        <h6 className="studentDashboard_Dashboard_Title">
                                            Set your target universities 🎯
                                        </h6>
                                        <Button className="btn cta--rounded cta-primary" style={{ borderRadius: '10px', marginRight: '1rem' }}>
                                            <span style={{ paddingLeft: '20px', paddingRight: '20px' }}>Update</span>
                                        </Button>
                                    </div>
                                    <div className="p-3">
                                        <PillSection data={props?.isgetusergoals?.universities} maxWidth={500} maxHeight={75} />
                                    </div>
                                </Card>
                            </div>

                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                <Card variant="outlined" sx={{ textAlign: 'center', borderRadius: "10px", marginBottom: "10px" }}>
                                    <div className="CardTitle_Section" >
                                        <h6 className="studentDashboard_Dashboard_Title">
                                            Set your career goals 🎯
                                        </h6>
                                        <Button className="btn cta--rounded cta-primary" style={{ borderRadius: '10px', marginRight: '1rem' }}>
                                            <span style={{ paddingLeft: '20px', paddingRight: '20px' }}>Update</span>
                                        </Button>
                                    </div>
                                    <div className="p-3">
                                        <PillSection data={props?.isgetusergoals?.professions} maxWidth={369} maxHeight={75} />
                                    </div>
                                </Card>
                            </div>
                        </div>
                        <Card variant="outlined" sx={{ textAlign: 'center', borderRadius: "10px", marginBottom: "10px" }}>
                            <div className="CardTitle_Section" >
                                <h6 className="studentDashboard_Dashboard_Title">
                                    Set your courses and Activities 🎯
                                </h6>
                                <Button className="btn cta--rounded cta-primary" style={{ borderRadius: '10px', marginRight: '1rem' }}>
                                    <span style={{ paddingLeft: '20px', paddingRight: '20px' }}>Update</span>
                                </Button>
                            </div>
                            <div className="p-3">
                                <PillSection data={mappedData} maxWidth={1000} maxHeight={75} />
                            </div>
                        </Card>
                    </div>
                    <div className="studentDashboard_Dashboard_ExploreCarousel">
                        <div className="Explore_CardTitle_Section" >
                            <h6 className="studentDashboard_Dashboard_Title">
                                Connect with advisors who have achieved your goals🚀
                            </h6>
                            <div className="ExploreCarousel_btnContainer">
                                <Button className="btn cta--rounded cta-primary" style={{ borderRadius: '10px' }}>
                                    <span >Invite an advisor</span>
                                </Button>
                                <Button className="btn cta--rounded cta-primary" style={{ borderRadius: '10px' }}>
                                    <span >Your Advisors</span>
                                </Button>
                                <Button className="btn cta--rounded cta-primary" style={{ borderRadius: '10px' }}>
                                    <span >Explore Advisors</span>
                                </Button>
                            </div>
                        </div>
                        <CarouselComponent data={[]} />
                    </div>
                    <div className="studentDashboard_Dashboard_ExploreCarousel">
                        <div className="Explore_CardTitle_Section" >
                            <h6 className="studentDashboard_Dashboard_Title">
                                Connect with students with similar goals 🎓
                            </h6>
                            <div className="ExploreCarousel_btnContainer">
                                <Button className="btn cta--rounded cta-primary" style={{ borderRadius: '10px', marginRight: '1rem' }}>
                                    <span >Invite a Friend</span>
                                </Button>
                                <Button className="btn cta--rounded cta-primary" style={{ borderRadius: '10px', marginRight: '1rem' }}>
                                    <span >Your Friends</span>
                                </Button>
                                <Button className="btn cta--rounded cta-primary" style={{ borderRadius: '10px', }}>
                                    <span >Explore Students</span>
                                </Button>
                            </div>
                        </div>
                        <CarouselComponent data={[]} />
                    </div>
                    <div>
                        <div class="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                <Card variant="outlined" sx={{ textAlign: 'center', borderRadius: "10px" }}>
                                    <h6 className="dashTitle mt-3" style={{ textAlign: 'center' }}>
                                        Schedule available advisor sessions  🗓️
                                    </h6>

                                    <div className="p-3">
                                        {scheduleData.map((schedule) => (
                                            <ScheduleCard key={schedule.id} title={schedule.title} time={schedule.time} />
                                        ))}
                                    </div>
                                </Card>
                            </div>

                            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                <Card variant="outlined" sx={{ textAlign: 'center', borderRadius: "10px" }}>
                                    <h6 className="dashTitle  mt-3" style={{ textAlign: 'center' }}>
                                        Scheduled sessions  🗓️
                                    </h6>
                                    <div className="p-3">
                                        {scheduleData.map((schedule) => (
                                            <ScheduleCard key={schedule.id} title={schedule.title} time={schedule.time} />
                                        ))}
                                    </div>
                                    <Button className="btn cta--rounded cta-primary mb-3" style={{ borderRadius: '10px', }}>
                                        <span >View schedule</span>
                                    </Button>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = ({ commonData, userProfileData, studentData, }) => {
    const { userProfile } = userProfileData;
    const { isgetusergoals, } = studentData;
    const { message, errorList, connectedAdvisors, paidSessionBooked, bookPaidSessionError, sessionBooked, booksessionerror, totalUserCount } = commonData;
    return { message, errorList, connectedAdvisors, isgetusergoals, userProfile, paidSessionBooked, bookPaidSessionError, sessionBooked, booksessionerror, totalUserCount };
};
export default connect(mapStateToProps, { clearMessage, bookPaidSessionErrorStatus, getChildProfile, getUserCount, bookSession, bookPaidSession, bookSessionStatus, bookPaidSessionStatus })(Index);
