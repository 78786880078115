import React, { Component } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Dropdown } from "react-bootstrap";

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    openDropDown = () => {
        this.setState({
            showDropDown: !this.state.showDropDown,
        });
    };

    closeDropDown = () => {
        this.setState({
            showDropDown: false,
        });
    };

    render() {
        let { milestoneData, DropDownData, dashboard } = this.props;
        let DropDownDataList =
        DropDownData &&
        DropDownData.length > 0 &&
        DropDownData.map((x) => {
            return <li className="pb-2">{x}</li>;
        });
        let Plansdetails = milestoneData.map((element, key) => {
            return (
                <div className="milescard">
                    <div className="miletop">
                        <div className="row">
                            <div className="col">
                                <img src={element.image} />
                            </div>
                            <div className="col-sm-4 col-4 col-md-4 ps-2">
                            <div className="advisor-card">
                                <div className="advisor-card__card adv-lib">
                                <div
                                    className="cardgrid__dot"
                                   
                                >
                                    <Dropdown >
                                        <Dropdown.Toggle
                                            id="dropdown-basic "
                                            drop={"start"}
                                        >
                                            <svg
                                                width="16"
                                                height="4"
                                                viewBox="0 0 16 4"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M1.6 3.2C0.716344 3.2 0 2.48366 0 1.6C0 0.716344 0.716344 0 1.6 0C2.48366 0 3.2 0.716344 3.2 1.6C3.2 2.48366 2.48366 3.2 1.6 3.2ZM7.99844 3.2C7.11478 3.2 6.39844 2.48366 6.39844 1.6C6.39844 0.716344 7.11478 0 7.99844 0C8.88209 0 9.59844 0.716344 9.59844 1.6C9.59844 2.48366 8.88209 3.2 7.99844 3.2ZM12.8 1.6C12.8 2.48366 13.5163 3.2 14.4 3.2C15.2837 3.2 16 2.48366 16 1.6C16 0.716344 15.2837 0 14.4 0C13.5163 0 12.8 0.716344 12.8 1.6Z"
                                                    fill="#1B1C1E"
                                                />
                                            </svg>
                                        </Dropdown.Toggle>
                                        {/* {DropDownData &&
                                            DropDownData.length > 0 && (
                                                <div
                                                    classname="dropDowns"
                                                    style={{ width: "50px" }}
                                                >
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item>
                                                            {DropDownDataList}
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </div>
                                            )} */}
                                    </Dropdown>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className="milemid">
                        <p className="midtitle">{element.goal}</p>
                    </div>
                    <div className="midbtm">
                        <p className="midfrm">
                            Phase:<span>{element.date}</span>
                        </p>
                        <p className="midfrm">
                            Course : <span>{element.coursename}</span>
                        </p>
                        <p className="midfrm">
                            {dashboard === true ? "Milestone:" : "Plan:"}
                            <span>{element.planname}</span>
                        </p>
                    </div>
                    <div className="pgbar">
                        <ProgressBar now={element.percentage} />
                    </div>
                </div>
            );
        });

        return (
            <>
                <div className="plancnt advisor-mile-card mt-0">
                    <div className="cardcnts col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        {milestoneData && milestoneData.length > 0 && (
                            <div className="row">{Plansdetails}</div>
                        )}
                    </div>
                </div>
            </>
        );
    }
}

export default Index;