import React from "react";
import { Outlet } from "react-router-dom";
import { Sidebar } from "../../componentsNew/Parent/parentSidebar";
import ChatBox from "../../components/ChatBox";
import { connect } from "react-redux";
import axios from 'axios';
import { getUserData } from "../../utils/AuthUtils";
import {
    getUserProfile, getUserRoles, getUniversities, getProfessions, getGender, getCountry,
    getStatus, clearMessage, getCourse, getCourses, getActivities, getActivity, getStudentGrades,
    getChildProfile, getPhasetime, getInviteAdvisor, getParentProfile, getGraduationyear, getnotification, getStudentById, getDetailedUserProfile
} from "../../redux/actions";
import { withRouter } from "../../redux/store/navigate";
import { Topbar } from "../../componentsNew/ParentViaChild/parentChildTopbar";


class DefaultLayout extends React.Component {
    callAPIs = () => {
        const { getDetailedUserProfile } = this.props;
        if (localStorage.getItem("parentViaChild")) {
            let payload = {
                pointOfViewStudentId: localStorage.getItem("parentViaChild"),
                userId: localStorage.getItem("parentViaChild")
            };
            getDetailedUserProfile(payload);
        }
    };
    componentDidMount() {
        this.props.clearMessage()
        if (getUserData('accessToken')) {
            axios.defaults.headers.common['Authorization'] = "Bearer " + getUserData('accessToken');
            this.props.getChildProfile();
            this.props.getGender()
            this.props.getStatus()
            this.props.getUserProfile()
            this.props.getCountry()
            this.props.getActivity();
            this.props.getCourse();
            this.props.getActivities();
            this.props.getCourses();
            this.props.getStudentGrades();
            this.props.getInviteAdvisor();
            this.props.getParentProfile();
            this.props.getGraduationyear();
            this.props.getProfessions();
            let payload = {
                "pageNo": 1,
                "limit": 50
            }
            this.props.getnotification(payload)
            this.callAPIs()
        }
    }
    componentDidUpdate() {
        if (this.props.userProfile && this.props.userProfile && this.props.userProfile.isOnboarded !== undefined && !this.props.userProfile.isOnboarded) {
            this.props.navigate('/onboarding/roles');
        }
        if (this.props?.getchildstatus && this.props?.childprofile?.length > 0 && !localStorage.getItem("parentViaChild")) {
            console.log(this.props?.childprofile[0]?.id, "this.props?.childprofile[0]?.id")
            localStorage.setItem("parentViaChild", this.props?.childprofile && this.props?.childprofile[0]?.id);
            localStorage.setItem("childName", this.props?.childprofile && this.props?.childprofile[0]?.fullName);
            this.callAPIs()
        }

    }

    render() {
        const additionalStyle = {};
        if (this.props.compact) {
            additionalStyle.padding = "0px";
        }
        return (
            <div>
                <div id="layoutSidenav ">
                    <Sidebar childprofile={this.props?.childprofile} notification={this.props?.notificationdata} callAPIs={this.callAPIs} userImage={this.props?.userProfile} />
                    <div className='childSwitchDefault mb-4'>
                        <Topbar userProfile={this.props.userProfile?.firstName} name={localStorage.getItem("childName") || this.props?.childprofile && this.props?.childprofile[0]?.fullName} />
                    </div>
                    <div className="center-wrap-default" style={additionalStyle}>
                        {this.props.userProfile && <Outlet />}
                    </div>
                    {!this.props.compact && <ChatBox />}
                </div>
            </div>
        )
    }
}
//export default DashboardLayout;

const mapStateToProps = ({ userProfileData, commonData, parentData }) => {
    const { isUserOnboarded, userProfile, childprofile, getchildstatus } = userProfileData;
    const { notificationdata, detailedUserData } = commonData
    const { studentById } = parentData;
    return { isUserOnboarded, userProfile, childprofile, notificationdata, studentById, detailedUserData, getchildstatus }
};

export default connect(mapStateToProps, {
    getnotification, getUserProfile, getUserRoles, getGraduationyear, getUniversities, getProfessions, getGender, getCountry, getStatus,
    getCourse, getActivity, getCourses, getActivities, getStudentGrades, getParentProfile, clearMessage, getChildProfile, getPhasetime, getInviteAdvisor, getStudentById, getDetailedUserProfile
})(withRouter(DefaultLayout));

