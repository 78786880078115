import React, { useEffect, useState } from 'react'
import "./Sessionpopup.less"
import moment from 'moment/moment';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { connect } from 'react-redux';
import { withRouter } from '../../../../redux/store/navigate';
import { getChildProfile, cancelEvent } from "../../../../redux/actions"
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import Reschedule from "../../../../componentsNew/Common/ReShedule/reschedulePopup"
import Confirmation from "../../../../componentsNew/Common/ConfirmationPopup";
import dayjs from 'dayjs';
import { GetCurrentTimezone } from '../../../../utils/CommonUtils';


const Index = (props) => {
    const [show, setShow] = useState(false);
    const [scheduledata, setscheduledata] = useState()
    const [open, setOpen] = useState(false);
    const [advisordata, setAdvisorData] = useState();
    const [childData, setChildData]= useState();

    const handleOpen = () => setOpen(true);
    const handleConfirmClose = () => setOpen(false);


    const handleDelete = () => {
        props.cancelEvent(props?.data?.id);
    }
    const HandleRescheduleData = () => {
        if (props?.data) {
            let Payload = {
                Starttime: props.data?.start,
                Endtime: props?.data?.end,
                meeting: props?.data?.meetingLocation,
                description: props?.data?.meetingDescription,
                Invitation: props?.data?.invitationStatus,
                AdvisorData: props?.data?.eventParticipents ? props?.data?.eventParticipents : props?.data?.AdvisorData,
                EventId: props?.data?.id,
                EventType: props?.data?.eventType,
                Password: props?.data?.details?.meetingPassword
            }
            let Data = []
            Data.push(Payload)
            setscheduledata(Data)
        }
    }
    const handleClose = () => {
        setShow(false);
        setscheduledata([])
    }
    const handleShow = () => {
        HandleRescheduleData()
        setShow(true)
        props?.onHide()
    };

    useEffect(() => {
        if (props?.data) {
            let Payload = {
                Starttime: props.data?.start,
                Endtime: props?.data?.end,
                meeting: props?.data?.meetingLocation,
                description: props?.data?.meetingDescription,
                Invitation: props?.data?.invitationStatus,
                AdvisorData: props?.data ?? props?.data?.AdvisorData,
                EventId: props?.data?.id,
                meetingPassword: props?.data?.meetingPassword
            }
            if (props?.data && props?.data?.eventParticipents) {
                const advisor = props?.data?.eventParticipents.find(participant => participant?.currentRole === 'advisor');
                if (advisor) {
                    const advisordata = props?.connectedAdvisors?.find((data) => data?.userInfo?.id === advisor?.id);
                    setAdvisorData(advisordata);
                }
            }
            let Data = []
            Data.push(Payload)
            setscheduledata(Data)
        }
        
        if(props?.childId){
            filteredProfiles()
        }
    }, [props,props?.childId])
    
    console.log("props?.childId", props?.childId);

    useEffect(() => {
        handleConfirmClose()
    }, [props?.cancelSession])


    useEffect(() => {
        props?.getChildProfile()
    }, [])

    const CloseDetailpopup = () => {
        props?.onHide()
    }
    const filteredProfiles = () => {
        const childId = props?.childId?.filter(profile => {
            return profile?.id === localStorage?.getItem("parentViaChild");
        });
        
        console.log("Filtered Profiles:", childId);
        
        setChildData(childId);
    };
    
    const AdvisorName = props?.data?.title?.split(',')[0].trim();
    const startTime = dayjs(props?.data?.start);
    const endTime = dayjs(props?.data?.end);
    const duration = endTime?.diff(startTime, 'minute');
    let eventStatus = props?.data?.invitationStatus?.find((status) => status?.userId === localStorage?.getItem("parentViaChild"))

    let currentTime = GetCurrentTimezone(childData && childData[0]?.timezone);
    const isPast = startTime.isBefore(dayjs(currentTime));
    console.log(props?.data, "props?.datasd")
    return (
        <>{console.log("advisordata", props?.data?.eventStatus)}
            <Confirmation show={open} handleSubmit={() => handleDelete()} message={`Are you sure you want to cancel the session with ${AdvisorName}`} btnText="confirm" onHide={handleConfirmClose} />
            <Reschedule show={show} onHide={handleClose} duration={duration} advisorData={advisordata} Data={scheduledata} parent={true} childList={props?.childprofile} DetailClose={CloseDetailpopup} />
            <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered >
                <Modal.Header closeButton >
                    <Modal.Title id="contained-modal-title-vcenter">
                        Session with {AdvisorName}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='modalContent'>
                    <div className="modalContent__section">
                        <div className="modalContent__section__textCont">
                            <p className="modalContent__section__textCont__label">Who</p>
                            <p className="value blue-text">{props?.data?.title}</p>
                        </div>
                        <div className="modalContent__section__textCont">
                            <p className="modalContent__section__textCont__label">When</p>
                            <div className="modalContent__section__textCont__block-value">
                                <div className="modalContent__section__textCont__block-value">
                                    <p className="modalContent__section__textCont__value">{dayjs(props?.data?.start)?.format('hh:mm A')}</p>
                                    <p className="modalContent__section__textCont__value">{`${dayjs(props?.data?.start)?.format('hh:mm A')} to ${dayjs(props?.data?.end)?.format('hh:mm A')}`} (<span>Duration :</span> {duration ? duration : ""} Mins)</p>
                                </div>
                            </div>
                        </div>
                        <div className="modalContent__section__textCont">
                            <p className="modalContent__section__textCont__label">Where</p>
                            <p className="blue-text">{props?.data?.meetingLocation}
                                <a href={props.data.meetingLocation} target="_blank" rel="noopener noreferrer" >
                                    <OpenInNewOutlinedIcon sx={{ fontSize: '1rem', fill: '#7a7676', marginLeft: '4px' }} />
                                </a>
                            </p>
                        </div>
                        <div className="modalContent__section__textCont">
                            <p className="modalContent__section__textCont__label">Passcode</p>
                            <p title={props?.data?.password} className="modalContent__section__textCont__value sessionPassword">{props?.data?.password}

                            </p>
                        </div>
                        <p className='modalContent__section__value'>{props?.data?.meetingDescription}</p>
                        <div className="modalContent__section__bottom">


                            <div className="modalContent__section__bottom__reSchedule-sec" style={{ "display": "flex", "justifyContent": "end", }} >
                                {(eventStatus && eventStatus?.status !== 'rejected' && !isPast) &&
                                    <Button className="cancel-btn btn m-3" onClick={handleOpen}>
                                        Cancel Session
                                    </Button>
                                }
                                {!isPast &&
                                    <Button className="reschedulesave-btn btn m-3" onClick={handleShow} >
                                        Re Schedule
                                    </Button>
                                }
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    )
}

const mapStateToProps = ({ commonData, userProfileData }) => {
    const { errorList, cancelSession, connectedAdvisors } = commonData;
    const { childprofile } = userProfileData;

    return { errorList, childprofile, cancelSession, connectedAdvisors };
};

export default connect(mapStateToProps, { getChildProfile, cancelEvent })(withRouter(Index));
