import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Rating from "@mui/material/Rating";
import { useForm, Controller } from "react-hook-form";
import "./feedback.less"
import { connect } from "react-redux";
import { FeedbackSubmit, clearMessage } from "../../redux/actions";
import CustomAlert from "../CustomAlert";


const FeedbackForm = (props) => {
    const [rating, setRating] = useState(0);
    const [show, setShow] = useState(false);
    const { handleSubmit, control, setValue, reset } = useForm();

    const onSubmit = (data) => {
        let payload = {
            advisorId: props?.advisorDetail?.id,
            rating: rating,
            reviewDescription: data?.messageText

        }
        props?.FeedbackSubmit(payload)
    };

    useEffect(() => {
        if (props?.feadbackSubmit) {
            handleClose()
            props?.clearMessage()
        }

    }, [props?.feadbackSubmit])

    const handleRatingChange = (event, newValue) => {
        setRating(newValue);
    };

    const handleClose = () => {
        reset();
        props.onHide();
        setRating(0)
    };

    console.log(rating, "jdhgsdg")
    return (
        <>

            <Modal show={props.showFeedBack} fullscreen={props.fullscreen} onHide={handleClose} >
                <div style={{ "width": "100%", "display": "flex", "justifyContent": "center", }}> {<CustomAlert />}</div>

                <Modal.Header closeButton></Modal.Header>
                {/* <h1 className="login_title text-center">Give feedback about the advisor</h1> */}
                <p className="feeback-sub text-center">Tell us about your experience working with this advisor. </p>
                <Modal.Body className="feedBackPopup">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group feedrating col-sm-12 col-lg-12 col-xl-12 text-center">
                            <Rating
                                name="rating"
                                value={rating}
                                style={{ fontSize: '3rem' }}
                                onChange={handleRatingChange}
                            />
                        </div>


                        <div className="row m-0 ">
                            <div className="col-md-12 col-sm-12 col-12 p-0">
                                <div className="role">
                                    <div className="role_card">
                                        <div className="goals_page">
                                            <div className="input-floating-label form-group">
                                                <Controller
                                                    name="messageText"
                                                    control={control}
                                                    defaultValue=""
                                                    render={({ field }) => (
                                                        <div>
                                                            <textarea
                                                                {...field}
                                                                className="textarea--primary textbox--rounded input"
                                                                name="Message"
                                                                placeholder="Message"
                                                                style={{ height: "200px" }}
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    if (value.length <= 5000) {
                                                                        field.onChange(value);
                                                                    }
                                                                }}
                                                            />
                                                            <p>{field.value.length}/1000</p>

                                                        </div>
                                                    )}
                                                />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <button className="footer__cta cta--rounded" type="submit">Post feedback</button>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
};

const mapStateToProps = ({ commonData }) => {
    const { feadbackSubmit } = commonData

    return {
        feadbackSubmit
    }
};


export default connect(mapStateToProps, {
    FeedbackSubmit, clearMessage
})(FeedbackForm);
