import { SET_ISPARENTGOAL_SUCCESS_STATUS, SET_PARENT_GOAL_SUCCESS, GET_PARENT_GOALS_STUDENT, GET_PARENT_RECOMMENDED_STATUS, GET_STUDENT_BY_ID_PROFILE, GET_PARENT_GOALS_STATUS, GET_PARENT_GOALS, GET_PARENTS_GOALS_STATUS, GET_PARENT_RECOMMENDED, GET_ADVISOR_COUNT_UPDATES } from "../constants/ParentActionTypes"

const INIT_STATE = {
  isParentGoalSuccess: false,
  // parentGoal: false
  isParentGoalStatus: false,
  isParentAddSuccess: false,
  studentById: [],
  isGoalStudent: [],
  getRecommendParent: [],
  isParentRecommended : false,
  advisorUpdatesCount: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case SET_ISPARENTGOAL_SUCCESS_STATUS: {
      return {
        ...state,
        isParentGoalSuccess: action.payload,
      };
    }
    case SET_PARENT_GOAL_SUCCESS: {
      return {
        ...state,
        isParentAddSuccess: action.payload,
      };
    }
    case GET_PARENT_GOALS_STUDENT: {
      console.log(action.payload, "action.payload")
      return {
        ...state,
        isGoalStudent: action.payload,
      };
    }
    case GET_PARENT_GOALS_STATUS: {
      return {
        ...state,
        isParentGoalStatus: action.payload,
      };
    }
    case GET_PARENT_GOALS: {
      return {
        ...state,
        parentGoal: action.payload,
      };
    }
    case GET_PARENTS_GOALS_STATUS: {
      return {
        ...state,
        parentsstatus: action.payload,
      }
    }
    case GET_STUDENT_BY_ID_PROFILE: {
      return {
        ...state,
        studentById: action.payload?.recordInfo,
      };
    }
    case GET_PARENT_RECOMMENDED: {
      return {
        ...state,
        getRecommendParent: action.payload,
      };
    }
    case GET_PARENT_RECOMMENDED_STATUS: {
      return {
        ...state,
        isParentRecommended: action.payload,
      };
    }

    case GET_ADVISOR_COUNT_UPDATES : {
      return {
        ...state,
        advisorUpdatesCount: action.payload,
      };
    }

    default:
      return state;
  }
}
