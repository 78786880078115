import CloseIcon from '@mui/icons-material/Close';
import CreateIcon from '@mui/icons-material/Create';
import { Button, Checkbox, FormControlLabel, Grid, Modal, Tooltip } from '@mui/material';
import { grey } from '@mui/material/colors';
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from 'react-router';
import CustomAlert from "../../../components/CustomAlert";
import ActivityCard from "../../../componentsNew/Common/Cards/Common/ActivityCard";
import CourseCard from "../../../componentsNew/Common/Cards/Common/CourseCard";
import PlanCard from "../../../componentsNew/Common/Cards/Common/PlanCard";
import { AddAdvisorAcess, GetActivityList, GetStudentPlan, getAdvisorSuggested, getChildProfile, getDetailedUserProfile, getStudentDetail, userConnect, userConnectErrorStatus, userConnectStatus, getAdvisorSuggestedStatus, resetDetailedUserProfileStatus } from "../../../redux/actions";
import "./planAccessModal.less";
import Loader from '../../../components/Loader';
const styles = {
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        position: 'relative',
        backgroundColor: '#f4f5f8',
        boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
        padding: '0',
        borderRadius: '4px',
        width: '100%',
        height: "100%",
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        gap: '40px',
        overflow: 'auto',
        padding: '30px 70px',
        flexGrow: 1,
    },
};

const Index = (props) => {
    let navigate = useNavigate();
    const [loading, setloading] = useState(false)
    const [selectedPlans, setSelectedPlans] = useState([])
    const [globalPlans, setGlobalPlans] = useState([])
    const [selectedCourses, setSelectedCourses] = useState([])
    const [selectedActivities, setSelectedActivities] = useState([])
    const [description, setDescription] = useState('')
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (props?.userConnectSuccess) {
            let payload = {
                "limit": 10,
                "pageNo": 1,
                "studentId":props?.userProfile?.isOnboarded === true ? localStorage.getItem("parentViaChild") : props?.childprofile && props?.childprofile[0]?.id
            }
            let value = {
                userId: props?.data?.userInfo?.id || props.data?.requestInfo?.userId || props?.data?.id,

            }
            if (props?.userProfile?.currentRole === "parent") {
                value["pointOfViewStudentId"] = props?.userProfile?.isOnboarded === true ? localStorage.getItem("parentViaChild") : props?.childprofile && props?.childprofile[0]?.id
            }
            props?.getDetailedUserProfile(value)
            props?.getAdvisorSuggested(payload)
            props?.userConnectStatus(false)
        }
    }, [props?.userConnectSuccess])

    useEffect(() => {
        if (props?.detailedUserData && props?.detailedUserDataSuccess) {
            props?.resetDetailedUserProfileStatus(false)
            setloading(false)
            props?.onClose()
            props?.callApi()
        }
    }, [props?.detailedUserData, props?.detailedUserDataSuccess])

    const handleCheckPlan = (e, id) => {
        if (e.target.checked) {
            if (!selectedPlans.includes(id)) {
                setSelectedPlans([...selectedPlans, id])
            }
            if (globalPlans.includes(id)) {
                setGlobalPlans(globalPlans.filter(planId => planId !== id) || [])
            } else {
                setGlobalPlans([...globalPlans, id])
            }
        } else {
            let isOtherActivitiesFound = props?.studentDetails?.activities
                ?.filter((activityDetails) => activityDetails?.planId === id)
                ?.some(activityDetail => selectedActivities?.includes(activityDetail?.id))
            let isOtherCoursesFound = props?.studentDetails?.courses
                ?.filter((courseDetails) => courseDetails?.planId === id)
                ?.some(courseDetail => selectedCourses?.includes(courseDetail?.id))
            if (!isOtherCoursesFound && !isOtherActivitiesFound) {
                setSelectedPlans(selectedPlans?.filter(planId => planId !== id) || [])
            }
            setGlobalPlans(globalPlans.filter(planId => planId !== id) || [])
        }
    }
    const handleCheckCourse = (e, id, planId) => {
        if (selectedCourses?.includes(id)) {
            let isOtherCoursesFound = props?.studentDetails?.courses
                ?.filter((courseDetails) => courseDetails?.id !== id && courseDetails?.planId === planId)
                ?.some(courseDetail => selectedCourses?.includes(courseDetail?.id))
            let isOtherActivitiesFound = props?.studentDetails?.activities
                ?.filter((activityDetails) => activityDetails?.planId === planId)
                ?.some(activityDetail => selectedActivities?.includes(activityDetail?.id))
            if (!isOtherCoursesFound && !isOtherActivitiesFound) {
                setSelectedPlans((prevPlanIds) => prevPlanIds?.filter(prevPlanId => prevPlanId !== planId));
            }
            setSelectedCourses(selectedCourses?.filter(courseId => courseId !== id) || [])
        } else {
            setSelectedCourses((prevCourses) => [...prevCourses, id])
            if (!selectedPlans?.includes(planId)) {
                setSelectedPlans([...selectedPlans, planId])
            }
        }
    }
    const handleCheckActivities = (e, id, planId) => {
        if (selectedActivities?.includes(id)) {
            let isOtherActivitiesFound = props?.studentDetails?.activities
                ?.filter((activityDetails) => activityDetails?.id !== id && activityDetails?.planId === planId)
                ?.some(activityDetail => selectedActivities?.includes(activityDetail?.id))
            let isOtherCoursesFound = props?.studentDetails?.courses
                ?.filter((courseDetails) => courseDetails?.planId === planId)
                ?.some(courseDetail => selectedCourses?.includes(courseDetail?.id))
            if (!isOtherCoursesFound && !isOtherActivitiesFound) {
                setSelectedPlans((prevPlanIds) => prevPlanIds?.filter(prevPlanId => prevPlanId !== planId));
            }
            setSelectedActivities((prevActivities) => prevActivities?.filter(prevActivityId => prevActivityId !== id))
        } else {
            setSelectedActivities((prevActivities) => [...prevActivities, id])
            if (!selectedPlans?.includes(planId)) {
                setSelectedPlans([...selectedPlans, planId])
            }
        }
    }
    const handleCheckAllCoursesActivities = (e) => {
        if (e.target.checked) {
            props?.studentDetails?.courses?.forEach(courseDetail => {
                if (!selectedCourses?.includes(courseDetail?.id)) {
                    handleCheckCourse(e, courseDetail?.id, courseDetail?.planId);
                }
            })
            props?.studentDetails?.activities?.forEach(activityDetail => {
                if (!selectedActivities?.includes(activityDetail?.id)) {
                    handleCheckActivities(e, activityDetail?.id, activityDetail?.planId);
                }
            })
        } else {
            setSelectedCourses([])
            setSelectedActivities([])
        }
    }
    const getAccessibleCourses = (planId) => {
        let selectedCoursesofPlan = props?.studentDetails?.courses
            ?.filter(courseDetail => (courseDetail?.planId == planId && selectedCourses?.includes(courseDetail?.id)))
            ?.map(courseInfo => courseInfo?.id)
        return selectedCoursesofPlan;

    }
    const getAccessibleActivities = (planId) => {
        let selectedActivitiesofPlan = props?.studentDetails?.activities
            ?.filter(activityDetail => (activityDetail?.planId == planId && selectedActivities?.includes(activityDetail?.id)))
            ?.map(activityInfo => activityInfo?.id)
        return selectedActivitiesofPlan;
    }
    const handleSubmit = (e) => {
        let accessiblePlans = []
        selectedPlans?.forEach((planId) => {
            let accessiblePlansData = {
                planId,
                courseIds: getAccessibleCourses(planId),
                activityIds: getAccessibleActivities(planId)
            }
            accessiblePlans.push(accessiblePlansData);
        })
        globalPlans?.filter(planId => !selectedPlans?.includes(planId))
            ?.forEach((planId) => {
                let accessiblePlansData = {
                    planId,
                    courseIds: getAccessibleCourses(planId),
                    activityIds: getAccessibleActivities(planId)
                }
                accessiblePlans.push(accessiblePlansData)
            })
        let payload = {
            userId: props?.data?.userInfo?.id || props?.data?.id,
            accessiblePlans,
            message: description,
            studentIds: [props?.childprofile && props?.childprofile[0]?.id]
        }
        props?.userConnect(payload);
        setloading(true)
    }

    const handleDescriptionChange = (e) => {
        setDescription(e.target?.value);

    }

    const handleCheckAllPlans = (e) => {
        if (e.target.checked) {
            if (props?.studentPlanData) {
                let selectedPlanIds = props?.studentPlanData?.filter(planData => !globalPlans?.includes(planData?.planInfo?.id))?.map(planData => planData?.planInfo?.id);
                setGlobalPlans([...selectedPlanIds])
            }
            if (props?.getstudentplan) {
                let selectedPlanIds = props?.getstudentplan?.filter(planData => !globalPlans?.includes(planData?.planInfo?.id))?.map(planData => planData?.planInfo?.id);
                setGlobalPlans([...selectedPlanIds])
            }
        } else {
            setGlobalPlans([])
        }
    }

    const handleNavigate = (e, datas) => {
        console.log(datas, "fjhdjfhdj")
        e.preventDefault();
        if (props?.userProfile?.currentRole === "parent") {
            if (props?.userProfile?.isOnboarded ) {
                navigate(`/parent/mywork?openModal=true`, { state: { planData: datas } })

            } else {
                navigate("/onboarding/intialplansParent")
            }
            
        } 
        else if(props?.userProfile?.currentRole === "student"){
            if (props?.userProfile?.isOnboarded ) {
                navigate(`/student/mywork?openModal=true`, { state: { planData: datas } })

            } else {
                navigate("/onboarding/plans")
            }
        }
        else {
            navigate("/onboarding/plans")
        }
    }

    return (
        <>
            <Modal style={styles.modal} open={props.show} onClose={() => props.onClose()} aria-labelledby="modal-title" aria-describedby="modal-description" className="planCourseAccess">
                <div style={styles.paper}>
                    <div style={{ "width": "100%", "display": "flex", justifyContent: "center", marginTop: "10px" }}> {<CustomAlert />}</div>
                    {loading && <Loader />}
                    <div style={styles.content} className="modal-body planAccessModal" >
                        <div style={{ textAlign: 'right' }}>
                            <Button onClick={() => props.onClose()}>
                                <CloseIcon sx={{ fill: 'black' }} />
                            </Button>
                        </div>
                        <p className='planCourseAccess__title' >What would you like {props?.data?.userInfo?.fullName || props?.data?.fullName} to help with ?</p>
                        {(props?.data?.userInfo?.advisorProfile?.helpWithPlan === true || props?.data?.advisorProfile?.helpWithPlan === true) && (
                            <>
                                <div className="planCourseAccess__newPlanSec">
                                    <FormControlLabel
                                        className=" "
                                        style={{ fontSize: '2rem' }}
                                        control={
                                            <Checkbox
                                                id="createNewPlan"
                                                sx={{ '& .MuiSvgIcon-root': { fontSize: 26, borderRadius: 10 } }}
                                            />
                                        }
                                        label={<>Create and maintain a new plan based on my goals
                                            <span style={{ marginLeft: "5px" }} data-tip data-for="registerTip">
                                                <Tooltip  title={`${props?.data?.userInfo?.fullName || props?.data?.fullName} will create and maintain a plan with suggested courses and activities for each semester`}>
                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M8.00008 7.16699C8.27622 7.16699 8.50008 7.39085 8.50008 7.66699V11.0003C8.50008 11.2765 8.27622 11.5003 8.00008 11.5003C7.72394 11.5003 7.50008 11.2765 7.50008 11.0003V7.66699C7.50008 7.39085 7.72394 7.16699 8.00008 7.16699Z" fill="#919293" />
                                                        <path d="M8.00008 6.00033C8.36827 6.00033 8.66675 5.70185 8.66675 5.33366C8.66675 4.96547 8.36827 4.66699 8.00008 4.66699C7.63189 4.66699 7.33341 4.96547 7.33341 5.33366C7.33341 5.70185 7.63189 6.00033 8.00008 6.00033Z" fill="#919293" />
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M2.16675 8.00033C2.16675 4.77866 4.77842 2.16699 8.00008 2.16699C11.2217 2.16699 13.8334 4.77866 13.8334 8.00033C13.8334 11.222 11.2217 13.8337 8.00008 13.8337C4.77842 13.8337 2.16675 11.222 2.16675 8.00033ZM8.00008 3.16699C5.3307 3.16699 3.16675 5.33095 3.16675 8.00033C3.16675 10.6697 5.3307 12.8337 8.00008 12.8337C10.6695 12.8337 12.8334 10.6697 12.8334 8.00033C12.8334 5.33095 10.6695 3.16699 8.00008 3.16699Z" fill="#919293" />
                                                    </svg>
                                                </Tooltip>
                                            </span>
                                        </>}
                                    />

                                </div>
                                {(props?.studentPlanData?.length > 0 || props?.getstudentplan?.length > 0) &&
                                    <div className="planCourseAccess__existingPlanSec">
                                        <FormControlLabel
                                            style={{ fontSize: '2rem' }}
                                            control={
                                                <Checkbox
                                                    id="createNewPlan"
                                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 26, borderRadius: 10 } }}
                                                    onChange={(e) => handleCheckAllPlans(e)}
                                                    checked={globalPlans?.length > 0}
                                                />
                                            }
                                            label={<>
                                                Manage existing plan based on my goals
                                                <span style={{ marginLeft: "5px" }} data-tip data-for="registerTip">
                                                    <Tooltip title={`${props?.data?.userInfo?.fullName || props?.data?.fullName} will review your existing plan and suggest changes`} >
                                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M8.00008 7.16699C8.27622 7.16699 8.50008 7.39085 8.50008 7.66699V11.0003C8.50008 11.2765 8.27622 11.5003 8.00008 11.5003C7.72394 11.5003 7.50008 11.2765 7.50008 11.0003V7.66699C7.50008 7.39085 7.72394 7.16699 8.00008 7.16699Z" fill="#919293" />
                                                            <path d="M8.00008 6.00033C8.36827 6.00033 8.66675 5.70185 8.66675 5.33366C8.66675 4.96547 8.36827 4.66699 8.00008 4.66699C7.63189 4.66699 7.33341 4.96547 7.33341 5.33366C7.33341 5.70185 7.63189 6.00033 8.00008 6.00033Z" fill="#919293" />
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M2.16675 8.00033C2.16675 4.77866 4.77842 2.16699 8.00008 2.16699C11.2217 2.16699 13.8334 4.77866 13.8334 8.00033C13.8334 11.222 11.2217 13.8337 8.00008 13.8337C4.77842 13.8337 2.16675 11.222 2.16675 8.00033ZM8.00008 3.16699C5.3307 3.16699 3.16675 5.33095 3.16675 8.00033C3.16675 10.6697 5.3307 12.8337 8.00008 12.8337C10.6695 12.8337 12.8334 10.6697 12.8334 8.00033C12.8334 5.33095 10.6695 3.16699 8.00008 3.16699Z" fill="#919293" />
                                                        </svg>
                                                    </Tooltip>
                                                </span>
                                            </>}
                                        />
                                        <div className=" mt-4">
                                            <Grid container spacing={5}>
                                                {props?.studentPlanData?.length > 0 ? (
                                                    <Grid item sm={12} md={12} lg={12} className="PlanTile">
                                                        <div style={{ "display": "flex", "flexWrap": "wrap", "gap": "20px", "marginBottom": "50px" }} >
                                                            {props?.studentPlanData?.length > 0 && props?.studentPlanData?.map((element, key) => {
                                                                return (
                                                                    <PlanCard planData={element} key={element?.id}>
                                                                      {props?.studentPlanData?.length === 1 &&  <CreateIcon sx={{ fontSize: 18, color: grey[100] }} style={{ "marginRight": "5px", "cursor": "pointer" }} onClick={(e) => handleNavigate(e, element)} />}
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox sx={{ color: grey[100], '&.Mui-checked': { color: grey[50] } }}
                                                                                    checked={globalPlans?.includes(element?.planInfo?.id)}
                                                                                    size="small" onChange={(e) => handleCheckPlan(e, element?.planInfo?.id)}
                                                                                />
                                                                            }
                                                                        />
                                                                    </PlanCard>
                                                                )
                                                            })}
                                                        </div>
                                                    </Grid>
                                                ) : (
                                                    <Grid item sm={12} md={12} lg={12} className="PlanTile">
                                                        <div style={{ "display": "flex", "flexWrap": "wrap", "gap": "20px", "marginBottom": "50px" }} >
                                                            {props?.getstudentplan?.length > 0 && props?.getstudentplan?.map((element, key) => {
                                                                return (
                                                                    <PlanCard planData={element} key={element?.id}>
                                                                        <CreateIcon sx={{ fontSize: 18, color: grey[100] }} style={{ "marginRight": "5px", "cursor": "pointer" }} onClick={(e) => handleNavigate(e, element)} />
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox sx={{ color: grey[100], '&.Mui-checked': { color: grey[50] } }}
                                                                                    checked={globalPlans?.includes(element?.planInfo?.id)}
                                                                                    size="small" onChange={(e) => handleCheckPlan(e, element?.planInfo?.id)}
                                                                                />
                                                                            }
                                                                        />
                                                                    </PlanCard>
                                                                )
                                                            })}
                                                        </div>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </div>

                                    </div>
                                }
                            </>
                        )}
                        {((props?.data?.userInfo?.advisorProfile?.helpWithSpecificCourseActivity === true || props?.data?.advisorProfile?.helpWithSpecificCourseActivity === true) && props?.studentDetails?.courses?.length > 0 || props?.studentDetails?.activities?.length > 0) && (
                            <div className="planCourseAccess__courseActivitySec">
                                <FormControlLabel
                                    style={{ fontSize: '2rem', marginBottom: '20px' }}
                                    control={
                                        <Checkbox
                                            id="createNewPlan" onChange={(e) => handleCheckAllCoursesActivities(e)}
                                            sx={{ '& .MuiSvgIcon-root': { fontSize: 26, borderRadius: 10 } }}
                                            checked={selectedCourses?.length > 0 || selectedActivities.length > 0}
                                        />
                                    }
                                    label={<>
                                        Mentorship for specific courses/activities
                                        <span style={{ marginLeft: "5px" }} data-tip data-for="registerTip">
                                            <Tooltip title="After first free consultation session with student/parent, you will create/update initial plan with suggested courses/activities for high school year(s) . You will also suggest changes to this plan based on progress towards goals" >
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.00008 7.16699C8.27622 7.16699 8.50008 7.39085 8.50008 7.66699V11.0003C8.50008 11.2765 8.27622 11.5003 8.00008 11.5003C7.72394 11.5003 7.50008 11.2765 7.50008 11.0003V7.66699C7.50008 7.39085 7.72394 7.16699 8.00008 7.16699Z" fill="#919293" />
                                                    <path d="M8.00008 6.00033C8.36827 6.00033 8.66675 5.70185 8.66675 5.33366C8.66675 4.96547 8.36827 4.66699 8.00008 4.66699C7.63189 4.66699 7.33341 4.96547 7.33341 5.33366C7.33341 5.70185 7.63189 6.00033 8.00008 6.00033Z" fill="#919293" />
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M2.16675 8.00033C2.16675 4.77866 4.77842 2.16699 8.00008 2.16699C11.2217 2.16699 13.8334 4.77866 13.8334 8.00033C13.8334 11.222 11.2217 13.8337 8.00008 13.8337C4.77842 13.8337 2.16675 11.222 2.16675 8.00033ZM8.00008 3.16699C5.3307 3.16699 3.16675 5.33095 3.16675 8.00033C3.16675 10.6697 5.3307 12.8337 8.00008 12.8337C10.6695 12.8337 12.8334 10.6697 12.8334 8.00033C12.8334 5.33095 10.6695 3.16699 8.00008 3.16699Z" fill="#919293" />
                                                </svg>
                                            </Tooltip>
                                        </span>
                                    </>}
                                />
                                {props?.studentDetails && props?.studentDetails?.courses?.length > 0 &&
                                    <>
                                        <p className='planCourseAccess__courseActivitySec__title'>Courses</p>
                                        <div className="planCourseAccess__courseActivitySec__courseList">
                                            <Grid container spacing={5}>
                                                {props?.studentDetails && props?.studentDetails?.courses?.map((data, key) => {
                                                    return (
                                                        <Grid item sm={12} md={6} lg={6} key={data?.id}>
                                                            <CourseCard checkable data={data} >
                                                                <FormControlLabel
                                                                    style={{ fontSize: '2rem' }}
                                                                    control={
                                                                        <Checkbox
                                                                            id={`course-${data?.id}`}
                                                                            sx={{ '& .MuiSvgIcon-root': { fontSize: 26, borderRadius: 10 } }}
                                                                            checked={selectedCourses?.includes(data?.id)}
                                                                            onChange={(e) => handleCheckCourse(e, data?.id, data?.planId)}
                                                                        />
                                                                    }
                                                                />
                                                            </CourseCard>
                                                        </Grid>
                                                    );
                                                })}

                                            </Grid>
                                        </div>
                                    </>
                                }
                                {props?.studentDetails && props?.studentDetails?.activities?.length > 0 &&
                                    <>
                                        <p className='planCourseAccess__courseActivitySec__title'>Activities</p>
                                        <div className="planCourseAccess__courseActivitySec__courseList">
                                            <Grid container spacing={5}>
                                                {props?.studentDetails?.activities?.map((data, key) => {
                                                    return (
                                                        <Grid item sm={12} md={6} lg={6}>
                                                            <ActivityCard checkable data={data}>
                                                                <FormControlLabel
                                                                    style={{ fontSize: '2rem' }}
                                                                    control={
                                                                        <Checkbox
                                                                            id="createNewActivity"
                                                                            sx={{ '& .MuiSvgIcon-root': { fontSize: 26, borderRadius: 10 } }}
                                                                            checked={selectedActivities?.includes(data?.id)}
                                                                            onChange={(e) => handleCheckActivities(e, data?.id, data?.planId)}
                                                                        />
                                                                    }
                                                                />
                                                            </ActivityCard>
                                                        </Grid>
                                                    );
                                                })}
                                            </Grid>
                                        </div>
                                    </>
                                }
                            </div>
                        )}
                        <div className="planCourseAccess__commentSection">
                            <p className="planCourseAccess__commentSection__title">
                                Other requests for this advisor
                            </p>
                            <div className={screenWidth < 320 ? 'plandescription' : 'planCourseAccess__commentSection__inputArea input-floating-label'}>
                                <textarea refs="helpwith" maxLength={250} className={"textarea--primary textbox--rounded input"} name="otherComments"
                                    onChange={(e) => handleDescriptionChange(e)}
                                    placeholder=" " style={{ "minHeight": "100px", "resize": "none" }} id="resourceName" />
                                <label >Love to connect with you to go over my plans</label>
                                <div style={{ "display": "flex", "justifyContent": "space-between" }}>
                                    <p className="cta--text mt-0" style={{ "fontSize": "12px", "marginLeft": "20px", "marginBottom": 0 }}>Optional</p>
                                    <p className="gradeLabel mt-1 mb-0" >{description?.length ? description?.length : 0} / 250</p>
                                </div>
                            </div>
                        </div>
                        <div className="planCourseAccess__footerSection">
                            <button className='planCourseAccess__footerSection__btnPrimary' onClick={(e) => handleSubmit(e)} >Request Connection</button>
                        </div>
                    </div>
                </div>

            </Modal>
        </>
    )
}
const mapStateToProps = ({ studentData, commonData, userProfileData }) => {
    const { studentDetails, getstudentplan } = studentData;
    const { userConnectSuccess, userConnectError, studentPlanData, suggestedAdvisor, getSuggestedStatus, detailedUserData, detailedUserDataSuccess } = commonData;
    const { userProfile, childprofile } = userProfileData;
    return { userProfile, childprofile, getstudentplan, userConnectSuccess, userConnectError, studentDetails, studentPlanData, suggestedAdvisor, getSuggestedStatus, detailedUserData, detailedUserDataSuccess }
};
export default connect(mapStateToProps, { getChildProfile, GetStudentPlan, GetActivityList, userConnect, userConnectStatus, userConnectErrorStatus, getDetailedUserProfile, getAdvisorSuggested, getStudentDetail, AddAdvisorAcess, getAdvisorSuggestedStatus, resetDetailedUserProfileStatus })(Index);
