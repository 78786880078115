import React, { useEffect, useState } from 'react'
import "./network.less"
import { Tab, Tabs, Typography, Box, Button } from "@mui/material";
import PropTypes from 'prop-types';
import Select, { components } from "react-select";
import Loader from '../../../components/Loader';
import CustomAlert from '../../../components/CustomAlert';
import Advisor from "./ConnectedAdvisor"
import ConnectedStudents from "./ConnectedStudents";
import SentRequest from "../../../componentsNew/Common/RequestCards/SentRequest"
import RecievedRequest from "../../../componentsNew/Common/RequestCards/RecievedRequest"
import { connect } from "react-redux";
import { clearMessage, getConnectedAdvisors,getNetworkUserCount, getConnectedStudents, setConnectedAdvisorStatus, getPendingRequests, getSentRequests, setConnectedStudentStatus, setSentRequestsStatus, setPendingRequestsStatus, getAdvisorSchedule, getAdvisorScheduleStatus, getUserProfilestatus, getUserProfile } from '../../../redux/actions'
import { useLocation, useNavigate } from "react-router-dom";
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <Typography component="div" role="tabpanel" hidden={value !== index} id={`scrollable-auto-tabpanel-${index}`} aria-labelledby={`scrollable-auto-tab-${index}`} {...other} >
            {value === index && (
                <Box p={3}>{children}</Box>
            )}
        </Typography>
    );
}
TabPanel.propTypes = { children: PropTypes.node, index: PropTypes.any.isRequired, value: PropTypes.any.isRequired };
function a11yProps(index) {
    return { id: `scrollable-auto-tab-${index}`, "aria-controls": `scrollable-auto-tabpanel-${index}` };
}
// Select Styles
const { ValueContainer, Placeholder } = components;
const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>{props.selectProps.placeholder}</Placeholder>
            {React.Children.map(children, child => child && child.type !== Placeholder ? child : null)}
        </ValueContainer>
    );
};

const Index = (props) => {
    const [loading, setloading] = useState(false);
    const [Intial, setIntial] = useState(false);
    const [tabs, settabs] = useState(0);
    const [selectedOption, setSelectedOption] = useState({ value: 0, label: "Advisors" });
    const Menulists = [{ value: 0, label: "Advisors" }, { value: 1, label: "Students" }, { value: 2, label: "Sent Requests" }, { value: 3, label: "Received Requests" }]
    let navigate = useNavigate();
    let {state} = useLocation();
    useEffect(() => {
        setloading(true)
        props?.clearMessage();
        props?.getUserProfile();
        sessionStorage.setItem('netvalue', JSON.stringify(0));
        if (state?.type == "recieveRequest") {
            settabs(3);
            setSelectedOption({ value: 3, label: "Recieved Requests" })
        }
        props?.getNetworkUserCount();
    }, [])

    useEffect(() => {
        setloading(true)
        if (tabs === 0) {
            props?.getConnectedAdvisors();
            if (props?.userProfile?.id ) {
                setloading(true)
                props?.getAdvisorSchedule(props?.userProfile?.id)
                props?.getUserProfilestatus(false)
                props?.getNetworkUserCount()
            }
        }
        if (tabs === 1) { props.getConnectedStudents();props?.getNetworkUserCount() }
        if (tabs === 2) { props?.getSentRequests();props?.getNetworkUserCount() }
        if (tabs === 3) { props?.getPendingRequests();props?.getNetworkUserCount() }
    }, [tabs,props?.userProfile]);

    useEffect(() => {
        if (props?.getprofilestatus && props?.userProfile?.id !==undefined) {
            props?.getAdvisorSchedule(props?.userProfile?.id)
            props?.getUserProfilestatus(false)
        }
    }, [props?.getprofilestatus, props?.userProfile]);

    useEffect(() => {
        if (props?.connectedAdvisorsStatus && props?.connectedAdvisors) {
            props?.setConnectedAdvisorStatus(false)
            setloading(false)
        }
        if (props?.connectedStudentsStatus && props?.connectedStudents) {
            props?.setConnectedStudentStatus(false)
            setloading(false)
        }
    }, [props?.connectedAdvisors, props?.connectedAdvisorsStatus, props?.connectedStudentsStatus, props?.connectedStudents])

    useEffect(() => {
        if (props?.sendRequestStatus) {
            props?.setSentRequestsStatus(false)
            setloading(false)
        }
        if (props?.pendingRequestStatus) {
            props?.setPendingRequestsStatus(false)
            setloading(false)
        }
    }, [props?.sendedRequestList, props?.sendRequestStatus, props?.pendingList, props?.pendingRequestStatus])

    // Tabs Functionality
    function findOptionByValue(value) {
        return Menulists.find((option) => option.value === value);
    }
    const handleSelectChange = (e) => {
        sessionStorage.setItem('netvalue', JSON.stringify(e.value));
        settabs(e.value);
        setSelectedOption(e);
    }
    function handleChange(event, newValue) {
        settabs(newValue);
        setSelectedOption(findOptionByValue(newValue));
        sessionStorage.setItem('netvalue', JSON.stringify(newValue));
    }
    let count = props?.networkcount
    let parentcount = props?.sendedRequestList?.parents
    let recivedcount = props?.pendingList?.parents
    return (
        <>
            {loading && <Loader />}
            <div style={{ "width": "100%", "display": "flex", "justifyContent": "center", }}> {<CustomAlert />}</div>
            <div className='myNetwork'>
                <div className="myNetwork__Top">
                    <div className="myNetwork__Top__Desktop">
                        <div className="myNetwork__Top__Desktop__Title">
                            <p>Your network</p>
                        </div>
                    </div>
                    <div className="myNetwork__Top__Mobile">
                        <div className="myNetwork__Top__Mobile__Title">
                            <p>Your network</p>
                        </div>
                    </div>
                </div>
                <div className="myNetwork__Mid">
                    <div className='myNetwork__Mid__Tabs'>
                        <div className="myNetwork__Mid__Tabs__Mobile">
                            <div className="input-floating-label selectbox">
                                <Select options={Menulists} onChange={handleSelectChange} value={selectedOption}
                                    getOptionLabel={e => (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <span style={{ marginLeft: 10 }}>{e.label}</span>
                                        </div>
                                    )}
                                    placeholder=" " components={{ ValueContainer: CustomValueContainer, IndicatorSeparator: () => null }}
                                    styles={{ option: (provided, state) => ({ ...provided, cursor: 'pointer', }), container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible" }), valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px' }), placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10, display: state.isFocused || state.isSelected || state.selectProps.inputValue ? 'none' : 'block', }) }}
                                />
                            </div>
                            {tabs === 0 &&
                                    <div>
                                        <Button className='ExploreButton' onClick={() => navigate("/student/mynetwork/exploreAdvisor")}>Explore Advisors</Button>
                                    </div>
                                }
                                {tabs === 1 &&
                                    <div>
                                        <Button className='ExploreButton' onClick={() => navigate("/student/mynetwork/exploreStudent")}>Explore Students</Button>
                                    </div>
                                }
                        </div>
                        <div className="myNetwork__Mid__Tabs__Desktop">
                            <div className="d-flex align-items-center" style={{ "gap": "30px","flexWrap":"wrap" }} >
                                <div className="myNetwork__Mid__Tabs__Desktop__Tabs">
                                    <Tabs value={tabs} onChange={handleChange} >
                                        <Tab disableRipple label={`${count.advisorCount ?? 0} Advisors`} className="Tab" {...a11yProps(0)} />
                                        <Tab disableRipple label={`${count.studentCount ?? 0} Students`} className="Tab" {...a11yProps(1)} />
                                        <Tab disableRipple label={`${parentcount?.length > 0 ? count.sentRequestCount-parentcount?.length ?? 0 : count.sentRequestCount ?? 0} Sent Requests`} className="Tab" {...a11yProps(2)} />
                                        <Tab disableRipple label={`${recivedcount?.length > 0 ? count.pendingRequestCount-recivedcount?.length ?? 0 : count.pendingRequestCount ?? 0} Received Requests`} className="Tab" {...a11yProps(3)} />
                                    </Tabs>
                                </div>
                                {tabs === 0 &&
                                    <div>
                                        <Button className='ExploreButton' onClick={() => navigate("/student/mynetwork/exploreAdvisor")}>Explore Advisors</Button>
                                    </div>
                                }
                                {tabs === 1 &&
                                    <div>
                                        <Button className='ExploreButton' onClick={() => navigate("/student/mynetwork/exploreStudent")}>Explore Students</Button>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="myNetwork__Mid__Tabs__Desktop__Container">
                            <TabPanel value={tabs} index={0} className="TabPanels">
                                <Advisor />
                            </TabPanel>
                            <TabPanel value={tabs} index={1} className="TabPanels">
                                <ConnectedStudents />
                            </TabPanel>
                            <TabPanel value={tabs} index={2} className="TabPanels">
                                <SentRequest />
                            </TabPanel>
                            <TabPanel value={tabs} index={3} className="TabPanels">
                                <RecievedRequest />
                            </TabPanel>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = ({ commonData, userProfileData }) => {
    const { message, errorList, connectedAdvisorsStatus, connectedAdvisors, sendedRequestList, connectedStudentsStatus, connectedStudents, sendRequestStatus, pendingList, pendingRequestStatus, scheduledSessionDetails, scheduledSessionDetailStatus,networkcount } = commonData;
    const { userProfile } = userProfileData;
    return { message, errorList, connectedAdvisorsStatus, connectedAdvisors, sendedRequestList, connectedStudentsStatus, connectedStudents, sendRequestStatus, pendingList, pendingRequestStatus, userProfile, scheduledSessionDetails, scheduledSessionDetailStatus,networkcount };
};
export default connect(mapStateToProps, { getUserProfile,clearMessage,getNetworkUserCount, getConnectedAdvisors, getConnectedStudents, setConnectedAdvisorStatus, getPendingRequests, getSentRequests, setConnectedStudentStatus, setSentRequestsStatus, setPendingRequestsStatus, getAdvisorSchedule, getAdvisorScheduleStatus, getUserProfilestatus, getUserProfile })(Index);
