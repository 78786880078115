import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { clearMessage, getChildProfile, bookSession, bookPaidSession, bookSessionStatus, bookPaidSessionErrorStatus, getUserCount, bookPaidSessionStatus } from '../../../redux/actions'
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import CarouselComponent from './CarouselComponent';
import { Card } from '@mui/material';
import PillSection from "../../../componentsNew/Common/PillSection"
import { Button } from "react-bootstrap";
import "./dashboard.less";
import ScheduleCard from "../../../componentsNew/Dashboard/ScheduleListCard";

const Index = (props) => {
  const [advisorCounts, setAdvisorCount] = useState([])

    const pillsData = ["Standford Universities", "Abcott College", "MIT College",]
    const advisorActivities = props?.parentGoal?.activities || [];
    const advisorCourses = props?.parentGoal?.courses || [];

    const combinedData = [...advisorActivities, ...advisorCourses];

    const mappedData = combinedData.map(item => {
        if (item) {
            return item;
        }
        return null;
    });

    const scheduleData = [
        { id: 1, title: 'Session with Brian', time: '10:00 AM - 11:00 AM' },
        { id: 2, title: 'Meeting with Team', time: '12:00 PM - 01:00 PM' },
        { id: 3, title: 'Client Call', time: '03:00 PM - 04:00 PM' },
    ];

   

    let arrayData = [
        {
            "id": "66b99684f0fa2f4bc0c266df",
            "firstName": "jaganAdvisor19082024",
            "lastName": "ram",
            "email": "jagan@yopmail.com",
            "zipCode": "00601",
            "city": "Adjuntas",
            "state": "Puerto Rico",
            "countyName": "Adjuntas",
            "country": "US",
            "timezone": "America/New_York",
            "isEmailVerified": true,
            "isPhoneNoVerified": false,
            "isActive": true,
            "isPublic": true,
            "isOnboarded": true,
            "onboardedAt": "2024-08-12 05:05:43",
            "currentRole": "advisor",
            "roleLocked": false,
            "signedupVia": "default",
            "createdAt": "2024-08-12 04:58:44",
            "updatedAt": "2024-09-03 07:34:03",
            "advisorProfile": {
                "currentlyIn": "highschool",
                "highschoolName": "112 Alc After School & Summer School",
                "highschoolStatus": "High School Junior",
                "universityName": null,
                "companyName": null,
                "companyWebsite": null,
                "operatingSince": null,
                "universityGraduationYear": null,
                "satScore": 1000,
                "actScore": 30,
                "accomplishments": null,
                "isServicesEnabled": true,
                "helpWithPlan": true,
                "helpWithSpecificCourseActivity": true,
                "noOfFreeConsultation": 1,
                "weighted": 3,
                "unWeighted": 4,
                "aspiringToBecome": "Able Seamen,Accountant,Accountants and Auditor,Actor,Actuarie,Acupuncturist,Acute Care Nurse,Adapted Physical Education Specialist,Adjustment Clerk,Administrative Law Judges- Adjudicators- and Hearing Officer,Administrative Services Manager",
                "targetMajor": "Major 1",
                "targetUniversities": "AOMA Graduate School of Integrative Medicine,AMG School of Nursing,A Better U Beauty Barber Academy,A T Still University of Health Sciences,ABC Adult School,ABC Adult School - Cabrillo Lane,ABC Beauty Academy,ABCO Technology,AI Miami International University of Art and Design",
                "studentEmails": [],
                "advisorActivities": [
                    {
                        "id": "e6ccf0251d02e0130d60ae33f356f788",
                        "activityName": "Gymnastics",
                        "activityType": "Sports",
                        "gradeAchieved": "B+",
                        "teacherName": "",
                        "description": ""
                    },
                    {
                        "id": "0a81397a3a07babb74416e1f1dc4fa10",
                        "activityName": "Concert band",
                        "activityType": "Arts",
                        "gradeAchieved": "A",
                        "teacherName": "",
                        "description": ""
                    },
                    {
                        "id": "f74ffa6b5d53421cd34d519e8d8d61c9",
                        "activityName": "Aerobics",
                        "activityType": "Sports",
                        "gradeAchieved": "A+",
                        "teacherName": "",
                        "description": ""
                    }
                ],
                "advisorCourses": [
                    {
                        "id": "b7222963bf47e15474e250000cc3fbd4",
                        "courseName": "Accounting",
                        "courseType": "Academic Accelerated",
                        "gradeAchieved": "A",
                        "teacherName": "",
                        "description": ""
                    },
                    {
                        "id": "9703a69ee29f9e9162a510db68d05bce",
                        "courseName": "Entrepreneurial skills",
                        "courseType": "Academic Regular",
                        "gradeAchieved": "A",
                        "teacherName": "",
                        "description": ""
                    },
                    {
                        "id": "b64d824246e7ce202479945b3bfa0f13",
                        "courseName": "Marketing",
                        "courseType": "All Types",
                        "gradeAchieved": "A",
                        "teacherName": "",
                        "description": ""
                    }
                ],
                "advisorWorkingHours": {
                    "mondayTimesheet": [
                        {
                            "startingTime": "04:00:00",
                            "closingTime": "15:00:00"
                        }
                    ],
                    "tuesdayTimesheet": [
                        {
                            "startingTime": "04:00:00",
                            "closingTime": "15:00:00"
                        }
                    ],
                    "wednesdayTimesheet": [
                        {
                            "startingTime": "04:00:00",
                            "closingTime": "15:00:00"
                        }
                    ],
                    "thursdayTimesheet": [
                        {
                            "startingTime": "04:00:00",
                            "closingTime": "15:00:00"
                        }
                    ],
                    "fridayTimesheet": [
                        {
                            "startingTime": "04:00:00",
                            "closingTime": "15:00:00"
                        }
                    ],
                    "saturdayTimesheet": [
                        {
                            "startingTime": "04:00:00",
                            "closingTime": "15:00:00"
                        }
                    ],
                    "sundayTimesheet": [
                        {
                            "startingTime": "04:00:00",
                            "closingTime": "15:00:00"
                        }
                    ]
                },
                "advisorReviewSummary": {
                    "averageRating": 3,
                    "totalNoofReviews": 3,
                    "noof1StarReviews": 0,
                    "noof2StarReviews": 1,
                    "noof3StarReviews": 1,
                    "noof4StarReviews": 1,
                    "noof5StarReviews": 0
                },
                "defaultMeetingURL": "https://dev.leapbrains.com/onboarding/profile",
                "defaultMeetingPassword": "123456"
            },
            "fullName": "jaganAdvisor19082024 ram"
        },
        {
            "id": "66b9a003f0fa2f4bc0c26759",
            "firstName": "AdithiAdvisor",
            "lastName": "13082024",
            "email": "adithi@yopmail.com",
            "zipCode": "00602",
            "city": "Aguada",
            "state": "Puerto Rico",
            "countyName": "Aguada",
            "country": "US",
            "timezone": "America/Chicago",
            "isEmailVerified": true,
            "isPhoneNoVerified": false,
            "avatarPath": "development/users/profilepic/66b9a003f0fa2f4bc0c26759/foto-sushi-JgXxNIHotZ8-unsplash.jpg",
            "avatarSource": "default",
            "isActive": true,
            "isPublic": true,
            "isOnboarded": true,
            "onboardedAt": "2024-08-12 07:52:41",
            "currentRole": "advisor",
            "roleLocked": true,
            "signedupVia": "default",
            "createdAt": "2024-08-12 05:39:15",
            "updatedAt": "2024-09-02 08:33:47",
            "advisorProfile": {
                "currentlyIn": "highschool",
                "highschoolName": "A C Jones High School",
                "highschoolStatus": "High School Junior",
                "universityName": "",
                "companyName": null,
                "companyWebsite": null,
                "operatingSince": null,
                "universityGraduationYear": null,
                "satScore": 100,
                "actScore": 16,
                "accomplishments": "Paragraphs are the building blocks of papers. Many students define paragraphs in terms of length: a paragraph is a group of at least five sentences, a paragraph is half a page long, etc. In reality, though, the unity and coherence of ideas among sentences is what constitutes a paragraph",
                "isServicesEnabled": true,
                "helpWithPlan": true,
                "helpWithSpecificCourseActivity": true,
                "noOfFreeConsultation": 2,
                "weighted": 1.2,
                "unWeighted": 2.2,
                "aspiringToBecome": "Able Seamen,Accountant,Accountants and Auditor,Actor,Actuarie,Acupuncturist,Acute Care Nurse,Adapted Physical Education Specialist,Adjustment Clerk,Administrative Law Judges- Adjudicators- and Hearing Officer,Adult Literacy- Remedial Education- and GED Teachers and Instructor,Administrative Services Manager",
                "targetMajor": "Major 1,Major 2,Major 3,Major 4",
                "targetUniversities": "A Better U Beauty Barber Academy,A T Still University of Health Sciences,ABC Adult School,ABC Adult School - Cabrillo Lane,ABC Beauty Academy,ABCO Technology,AI Miami International University of Art and Design,AMG School of Nursing",
                "studentEmails": [],
                "advisorActivities": [],
                "advisorCourses": [],
                "advisorWorkingHours": {
                    "mondayTimesheet": [
                        {
                            "startingTime": "05:00:00",
                            "closingTime": "04:30:00"
                        }
                    ],
                    "tuesdayTimesheet": [
                        {
                            "startingTime": "05:00:00",
                            "closingTime": "04:30:00"
                        }
                    ],
                    "wednesdayTimesheet": [
                        {
                            "startingTime": "05:00:00",
                            "closingTime": "04:30:00"
                        }
                    ],
                    "thursdayTimesheet": [
                        {
                            "startingTime": "05:00:00",
                            "closingTime": "04:30:00"
                        }
                    ],
                    "fridayTimesheet": [
                        {
                            "startingTime": "05:00:00",
                            "closingTime": "04:30:00"
                        }
                    ],
                    "saturdayTimesheet": [
                        {
                            "startingTime": "05:00:00",
                            "closingTime": "04:30:00"
                        }
                    ],
                    "sundayTimesheet": [
                        {
                            "startingTime": "05:00:00",
                            "closingTime": "04:30:00"
                        }
                    ]
                },
                "advisorReviewSummary": {
                    "averageRating": 3.3333333333333335,
                    "totalNoofReviews": 3,
                    "noof1StarReviews": 0,
                    "noof2StarReviews": 0,
                    "noof3StarReviews": 2,
                    "noof4StarReviews": 1,
                    "noof5StarReviews": 0
                },
                "defaultMeetingURL": "https://dev.leapbrains.com",
                "defaultMeetingPassword": "AYDSJ123"
            },
            "fullName": "AdithiAdvisor 13082024"
        },
        {
            "id": "66c56edf2389027c67693cd8",
            "firstName": "vignesh",
            "lastName": "kumar",
            "email": "vignesh@yopmail.com",
            "zipCode": "00601",
            "city": "Adjuntas",
            "state": "Puerto Rico",
            "countyName": "Adjuntas",
            "country": "US",
            "timezone": "Pacific/Honolulu",
            "isEmailVerified": true,
            "isPhoneNoVerified": false,
            "avatarPath": "development/users/profilepic/66c56edf2389027c67693cd8/photo-1535713875002-d1d0cf377fde.jpg",
            "avatarSource": "default",
            "isActive": true,
            "isPublic": true,
            "isOnboarded": true,
            "onboardedAt": "2024-08-21 06:02:56",
            "currentRole": "advisor",
            "roleLocked": false,
            "signedupVia": "default",
            "createdAt": "2024-08-21 04:36:47",
            "updatedAt": "2024-08-29 18:01:16",
            "advisorProfile": {
                "currentlyIn": "highschool",
                "highschoolName": "112 Alc After School & Summer School",
                "highschoolStatus": "High School Junior",
                "universityName": null,
                "companyName": null,
                "companyWebsite": null,
                "operatingSince": null,
                "universityGraduationYear": null,
                "satScore": 1000,
                "actScore": 32,
                "accomplishments": "",
                "isServicesEnabled": true,
                "helpWithPlan": true,
                "helpWithSpecificCourseActivity": true,
                "noOfFreeConsultation": 1,
                "weighted": 3,
                "unWeighted": 4,
                "aspiringToBecome": "Accountant,Able Seamen,Accountants and Auditor,Actuarie,Acupuncturist,Actor,Acute Care Nurse,Adjustment Clerk,Administrative Law Judges- Adjudicators- and Hearing Officer,Administrative Services Manager",
                "targetMajor": "Major 1",
                "targetUniversities": "ABC Adult School - Cabrillo Lane",
                "studentEmails": [],
                "advisorActivities": [
                    {
                        "id": "e937d596788532f09498fcd3220f0559",
                        "activityName": "Choir",
                        "activityType": "Arts",
                        "gradeAchieved": "A",
                        "teacherName": "",
                        "description": ""
                    },
                    {
                        "id": "e3ecd3be504a3ef20c85607036dc84e1",
                        "activityName": "Health",
                        "activityType": "Sports",
                        "gradeAchieved": "B+",
                        "teacherName": "",
                        "description": ""
                    },
                    {
                        "id": "0c4423e9ac9ae351226325dad2ef1e2c",
                        "activityName": "Racket sports",
                        "activityType": "Sports",
                        "gradeAchieved": "A",
                        "teacherName": "",
                        "description": ""
                    }
                ],
                "advisorCourses": [
                    {
                        "id": "545134b2736d55d017dfa2db8b62459f",
                        "courseName": "Accounting",
                        "courseType": "Academic Regular",
                        "gradeAchieved": "A+",
                        "teacherName": "",
                        "description": ""
                    },
                    {
                        "id": "407664b0ba81fc0e0dc324ff86f926eb",
                        "courseName": "Business law",
                        "courseType": "Academic Accelerated",
                        "gradeAchieved": "B",
                        "teacherName": "",
                        "description": ""
                    },
                    {
                        "id": "1187687471d03a8ac42bfff2e7a456bd",
                        "courseName": "Business management",
                        "courseType": "Academic Accelerated",
                        "gradeAchieved": "A",
                        "teacherName": "",
                        "description": ""
                    }
                ],
                "advisorWorkingHours": {
                    "mondayTimesheet": [
                        {
                            "startingTime": "10:00:00",
                            "closingTime": "09:00:00"
                        }
                    ],
                    "tuesdayTimesheet": [
                        {
                            "startingTime": "10:00:00",
                            "closingTime": "09:00:00"
                        }
                    ],
                    "wednesdayTimesheet": [
                        {
                            "startingTime": "10:00:00",
                            "closingTime": "09:00:00"
                        }
                    ],
                    "thursdayTimesheet": [
                        {
                            "startingTime": "10:00:00",
                            "closingTime": "09:00:00"
                        }
                    ],
                    "fridayTimesheet": [
                        {
                            "startingTime": "10:00:00",
                            "closingTime": "09:00:00"
                        }
                    ],
                    "saturdayTimesheet": [
                        {
                            "startingTime": "10:00:00",
                            "closingTime": "09:00:00"
                        }
                    ],
                    "sundayTimesheet": [
                        {
                            "startingTime": "10:00:00",
                            "closingTime": "09:00:00"
                        }
                    ]
                },
                "advisorReviewSummary": null,
                "defaultMeetingURL": "https://dev.leapbrains.com/onboarding/profile",
                "defaultMeetingPassword": "ssssssssssss"
            },
            "fullName": "vignesh kumar"
        },
        {
            "id": "66b85dfaf0fa2f4bc0c26672",
            "firstName": "ashik",
            "lastName": "ADV",
            "email": "ashik@yopmail.com",
            "zipCode": "00601",
            "city": "Adjuntas",
            "state": "Puerto Rico",
            "countyName": "Adjuntas",
            "country": "US",
            "timezone": "America/Los_Angeles",
            "isEmailVerified": true,
            "isPhoneNoVerified": false,
            "isActive": true,
            "isPublic": true,
            "isOnboarded": true,
            "onboardedAt": "2024-08-12 04:26:15",
            "currentRole": "advisor",
            "roleLocked": false,
            "signedupVia": "default",
            "createdAt": "2024-08-11 06:45:14",
            "updatedAt": "2024-08-21 06:36:02",
            "advisorProfile": {
                "currentlyIn": "highschool",
                "highschoolName": "112 Alc After School & Summer School",
                "highschoolStatus": "High School Junior",
                "universityName": null,
                "companyName": null,
                "companyWebsite": null,
                "operatingSince": null,
                "universityGraduationYear": null,
                "satScore": 1000,
                "actScore": 30,
                "accomplishments": null,
                "isServicesEnabled": true,
                "helpWithPlan": true,
                "helpWithSpecificCourseActivity": true,
                "noOfFreeConsultation": 1,
                "weighted": 3,
                "unWeighted": 4,
                "aspiringToBecome": "Accountant,Able Seamen,Accountants and Auditor,Actor,Actuarie,Acupuncturist,Acute Care Nurse",
                "targetMajor": "Major 1,Major 2",
                "targetUniversities": "A T Still University of Health Sciences,ABC Adult School,ABC Adult School - Cabrillo Lane,ASI Career Institute,ASPIRA City College,ATA Career Education,ATA College,ATA College-Cincinnati,ATI College-Whittier,Aaniiih Nakoda College",
                "studentEmails": [],
                "advisorActivities": [
                    {
                        "id": "17e5c926e30063d24292f3ae717505a0",
                        "activityName": "Aerobics",
                        "activityType": "Sports",
                        "gradeAchieved": "A+",
                        "teacherName": "",
                        "description": ""
                    },
                    {
                        "id": "8e504395426bd5889f96e5638eded752",
                        "activityName": "Lifeguard training",
                        "activityType": "Sports",
                        "gradeAchieved": "A",
                        "teacherName": "",
                        "description": ""
                    },
                    {
                        "id": "4c1b50af8db2b79e4094977de464e246",
                        "activityName": "Drama",
                        "activityType": "Arts",
                        "gradeAchieved": "A",
                        "teacherName": "",
                        "description": ""
                    }
                ],
                "advisorCourses": [
                    {
                        "id": "174bce299c162fb319c39921c02051aa",
                        "courseName": "Business law",
                        "courseType": "Academic Accelerated",
                        "gradeAchieved": "A+",
                        "teacherName": "",
                        "description": ""
                    },
                    {
                        "id": "05b4765d9fd02c771f4b5894bba0755a",
                        "courseName": "Accounting",
                        "courseType": "Academic Regular",
                        "gradeAchieved": "A",
                        "teacherName": "",
                        "description": ""
                    },
                    {
                        "id": "44fc14cedd489f35382cf1b266ad9c90",
                        "courseName": "Entrepreneurial skills",
                        "courseType": "Academic Regular",
                        "gradeAchieved": "B+",
                        "teacherName": "",
                        "description": ""
                    }
                ],
                "advisorWorkingHours": {
                    "mondayTimesheet": [
                        {
                            "startingTime": "07:00:00",
                            "closingTime": "06:30:00"
                        }
                    ],
                    "tuesdayTimesheet": [
                        {
                            "startingTime": "07:00:00",
                            "closingTime": "06:30:00"
                        }
                    ],
                    "wednesdayTimesheet": [
                        {
                            "startingTime": "07:00:00",
                            "closingTime": "06:30:00"
                        }
                    ],
                    "thursdayTimesheet": [
                        {
                            "startingTime": "07:00:00",
                            "closingTime": "06:30:00"
                        }
                    ],
                    "fridayTimesheet": [
                        {
                            "startingTime": "07:00:00",
                            "closingTime": "06:30:00"
                        }
                    ],
                    "saturdayTimesheet": [
                        {
                            "startingTime": "07:00:00",
                            "closingTime": "06:30:00"
                        }
                    ],
                    "sundayTimesheet": [
                        {
                            "startingTime": "07:00:00",
                            "closingTime": "06:30:00"
                        }
                    ]
                },
                "advisorReviewSummary": null,
                "defaultMeetingURL": "https://dev.leapbrains.com/onboarding/profile",
                "defaultMeetingPassword": "123654"
            },
            "fullName": "ashik ADV"
        },
        {
            "id": "66b99349f0fa2f4bc0c266ad",
            "firstName": "stanley",
            "lastName": "raj",
            "email": "stanley@yopmail.com",
            "zipCode": "00601",
            "city": "Adjuntas",
            "state": "Puerto Rico",
            "countyName": "Adjuntas",
            "country": "US",
            "timezone": "America/Phoenix",
            "isEmailVerified": true,
            "isPhoneNoVerified": false,
            "isActive": true,
            "isPublic": true,
            "isOnboarded": true,
            "onboardedAt": "2024-08-12 04:49:49",
            "currentRole": "advisor",
            "roleLocked": false,
            "signedupVia": "default",
            "createdAt": "2024-08-12 04:44:57",
            "updatedAt": "2024-08-21 03:58:09",
            "advisorProfile": {
                "currentlyIn": "highschool",
                "highschoolName": "21st Century Academy",
                "highschoolStatus": "High School Junior",
                "universityName": null,
                "companyName": null,
                "companyWebsite": null,
                "operatingSince": null,
                "universityGraduationYear": null,
                "satScore": 1000,
                "actScore": 30,
                "accomplishments": null,
                "isServicesEnabled": true,
                "helpWithPlan": true,
                "helpWithSpecificCourseActivity": true,
                "noOfFreeConsultation": 1,
                "weighted": 3,
                "unWeighted": 4,
                "aspiringToBecome": "Accountant,Accountants and Auditor,Actor,Actuarie,Acupuncturist,Acute Care Nurse,Adapted Physical Education Specialist,Adjustment Clerk,Administrative Law Judges- Adjudicators- and Hearing Officer,Administrative Services Manager,Adult Literacy- Remedial Education- and GED Teachers and Instructor,Advanced Practice Psychiatric Nurse,Advertising and Promotions Manager,Advertising Sales Agent",
                "targetMajor": "Major 1",
                "targetUniversities": "AOMA Graduate School of Integrative Medicine,AI Miami International University of Art and Design,AMG School of Nursing,ABC Adult School,ABC Beauty Academy,ABCO Technology",
                "studentEmails": [],
                "advisorActivities": [
                    {
                        "id": "af5e6b838e30a647d0b90fe348d15754",
                        "activityName": "Gymnastics",
                        "activityType": "Sports",
                        "gradeAchieved": "A",
                        "teacherName": "",
                        "description": ""
                    },
                    {
                        "id": "f5acb1a75b0a8f4289df624d41c2d846",
                        "activityName": "Pilates",
                        "activityType": "Sports",
                        "gradeAchieved": "A+",
                        "teacherName": "",
                        "description": ""
                    },
                    {
                        "id": "8a873107354685a100abf139523cbbee",
                        "activityName": "Specialized sports",
                        "activityType": "Sports",
                        "gradeAchieved": "A+",
                        "teacherName": "",
                        "description": ""
                    }
                ],
                "advisorCourses": [
                    {
                        "id": "4b955e66e5a35f3fa14ad6850ad7854a",
                        "courseName": "Accounting",
                        "courseType": "Academic Accelerated",
                        "gradeAchieved": "A",
                        "teacherName": "",
                        "description": ""
                    },
                    {
                        "id": "ed4a14ff231ad92b84b31cef7a4182f9",
                        "courseName": "Accounting",
                        "courseType": "Academic Regular",
                        "gradeAchieved": "A",
                        "teacherName": "",
                        "description": ""
                    },
                    {
                        "id": "b5ca5e2b45373e552774b2af6f490553",
                        "courseName": "Entrepreneurial skills",
                        "courseType": "Academic Regular",
                        "gradeAchieved": "A+",
                        "teacherName": "",
                        "description": ""
                    }
                ],
                "advisorWorkingHours": {
                    "mondayTimesheet": [
                        {
                            "startingTime": "07:00:00",
                            "closingTime": "18:00:00"
                        },
                        {
                            "startingTime": "03:00:00",
                            "closingTime": "06:00:00"
                        }
                    ],
                    "tuesdayTimesheet": [
                        {
                            "startingTime": "07:00:00",
                            "closingTime": "18:00:00"
                        }
                    ],
                    "wednesdayTimesheet": [
                        {
                            "startingTime": "07:00:00",
                            "closingTime": "18:00:00"
                        }
                    ],
                    "thursdayTimesheet": [
                        {
                            "startingTime": "07:00:00",
                            "closingTime": "18:00:00"
                        }
                    ],
                    "fridayTimesheet": [
                        {
                            "startingTime": "07:00:00",
                            "closingTime": "18:00:00"
                        }
                    ],
                    "saturdayTimesheet": [
                        {
                            "startingTime": "07:00:00",
                            "closingTime": "18:00:00"
                        }
                    ],
                    "sundayTimesheet": [
                        {
                            "startingTime": "07:00:00",
                            "closingTime": "18:00:00"
                        }
                    ]
                },
                "advisorReviewSummary": null,
                "defaultMeetingURL": "https://dev.leapbrains.com/onboarding/profile",
                "defaultMeetingPassword": "123456"
            },
            "fullName": "stanley raj"
        },
        {
            "id": "66b85887f0fa2f4bc0c26667",
            "firstName": "viki",
            "lastName": "krish",
            "email": "viki@yopmail.com",
            "zipCode": "00601",
            "city": "Adjuntas",
            "state": "Puerto Rico",
            "countyName": "Adjuntas",
            "country": "US",
            "timezone": "America/Anchorage",
            "isEmailVerified": true,
            "isPhoneNoVerified": false,
            "isActive": true,
            "isPublic": true,
            "isOnboarded": true,
            "onboardedAt": "2024-08-11 06:43:49",
            "currentRole": "advisor",
            "roleLocked": false,
            "signedupVia": "default",
            "createdAt": "2024-08-11 06:21:59",
            "updatedAt": "2024-08-20 06:55:49",
            "advisorProfile": {
                "currentlyIn": "highschool",
                "highschoolName": "270 Hopkins Alternative",
                "highschoolStatus": "High School Junior",
                "universityName": null,
                "companyName": null,
                "companyWebsite": null,
                "operatingSince": null,
                "universityGraduationYear": null,
                "satScore": 1000,
                "actScore": 30,
                "accomplishments": null,
                "isServicesEnabled": true,
                "helpWithPlan": true,
                "helpWithSpecificCourseActivity": true,
                "noOfFreeConsultation": 1,
                "weighted": 3,
                "unWeighted": 4,
                "aspiringToBecome": "Able Seamen,Accountants and Auditor,Accountant",
                "targetMajor": "Major 1",
                "targetUniversities": "A Better U Beauty Barber Academy,A T Still University of Health Sciences,ABC Adult School,ABC Adult School - Cabrillo Lane,ABC Beauty Academy,ABCO Technology",
                "studentEmails": [],
                "advisorActivities": [
                    {
                        "id": "0b6cc1a6c5c90b4c2a23313eea88807e",
                        "activityName": "Aerobics",
                        "activityType": "Sports",
                        "gradeAchieved": "A+",
                        "teacherName": "",
                        "description": ""
                    },
                    {
                        "id": "3e0d7a8c01bd941a1afa141ef77c7bca",
                        "activityName": "Concert band",
                        "activityType": "Arts",
                        "gradeAchieved": "A",
                        "teacherName": "",
                        "description": ""
                    },
                    {
                        "id": "7fead4e51c97cda194a1484685bd613a",
                        "activityName": "Gymnastics",
                        "activityType": "Sports",
                        "gradeAchieved": "A",
                        "teacherName": "",
                        "description": ""
                    }
                ],
                "advisorCourses": [
                    {
                        "id": "d4298764a08d8cefb668a7d4b43cc6a8",
                        "courseName": "Business law",
                        "courseType": "Academic Accelerated",
                        "gradeAchieved": "A",
                        "teacherName": "",
                        "description": ""
                    },
                    {
                        "id": "470b8816074e4de8ee6207270cb98143",
                        "courseName": "Introduction to business",
                        "courseType": "All Types",
                        "gradeAchieved": "A",
                        "teacherName": "",
                        "description": ""
                    },
                    {
                        "id": "cda76992ee579cbf9b9f7f336c91dbd8",
                        "courseName": "Consumer education",
                        "courseType": "Academic Regular",
                        "gradeAchieved": "A",
                        "teacherName": "",
                        "description": ""
                    }
                ],
                "advisorWorkingHours": {
                    "mondayTimesheet": [
                        {
                            "startingTime": "08:00:00",
                            "closingTime": "19:00:00"
                        }
                    ],
                    "tuesdayTimesheet": [
                        {
                            "startingTime": "08:00:00",
                            "closingTime": "19:00:00"
                        }
                    ],
                    "wednesdayTimesheet": [
                        {
                            "startingTime": "08:00:00",
                            "closingTime": "19:00:00"
                        }
                    ],
                    "thursdayTimesheet": [
                        {
                            "startingTime": "08:00:00",
                            "closingTime": "19:00:00"
                        }
                    ],
                    "fridayTimesheet": [
                        {
                            "startingTime": "08:00:00",
                            "closingTime": "19:00:00"
                        }
                    ],
                    "saturdayTimesheet": [
                        {
                            "startingTime": "08:00:00",
                            "closingTime": "19:00:00"
                        }
                    ],
                    "sundayTimesheet": [
                        {
                            "startingTime": "08:00:00",
                            "closingTime": "19:00:00"
                        }
                    ]
                },
                "advisorReviewSummary": null,
                "defaultMeetingURL": "https://dev.leapbrains.com/advisor/myprofile",
                "defaultMeetingPassword": "aaaaaaaa"
            },
            "fullName": "viki krish"
        },
        {
            "id": "66b994c9f0fa2f4bc0c266bf",
            "firstName": "sreeram",
            "lastName": "ram",
            "email": "sreeram@yopmail.com",
            "zipCode": "00601",
            "city": "Adjuntas",
            "state": "Puerto Rico",
            "countyName": "Adjuntas",
            "country": "US",
            "timezone": "America/Chicago",
            "isEmailVerified": true,
            "isPhoneNoVerified": false,
            "isActive": true,
            "isPublic": true,
            "isOnboarded": true,
            "onboardedAt": "2024-08-12 04:57:49",
            "currentRole": "advisor",
            "roleLocked": false,
            "signedupVia": "default",
            "createdAt": "2024-08-12 04:51:21",
            "updatedAt": "2024-08-19 05:18:33",
            "advisorProfile": {
                "currentlyIn": "highschool",
                "highschoolName": "270 Hopkins West Jr High Alc",
                "highschoolStatus": "High School Junior",
                "universityName": null,
                "companyName": null,
                "companyWebsite": null,
                "operatingSince": null,
                "universityGraduationYear": null,
                "satScore": 1000,
                "actScore": 30,
                "accomplishments": null,
                "isServicesEnabled": true,
                "helpWithPlan": true,
                "helpWithSpecificCourseActivity": true,
                "noOfFreeConsultation": 1,
                "weighted": 3,
                "unWeighted": 4,
                "aspiringToBecome": "Able Seamen,Accountant,Accountants and Auditor,Actor,Actuarie,Acupuncturist,Acute Care Nurse,Adapted Physical Education Specialist,Adjustment Clerk,Administrative Law Judges- Adjudicators- and Hearing Officer,Administrative Services Manager,Adult Literacy- Remedial Education- and GED Teachers and Instructor",
                "targetMajor": "Major 1",
                "targetUniversities": "AMG School of Nursing,A Better U Beauty Barber Academy,A T Still University of Health Sciences,ABC Adult School,ABC Adult School - Cabrillo Lane,ABCO Technology,AI Miami International University of Art and Design,AOMA Graduate School of Integrative Medicine,ASA College,ASI Career Institute,ASPIRA City College",
                "studentEmails": [],
                "advisorActivities": [
                    {
                        "id": "67ed7a904369d95551b6b6ac7344cafa",
                        "activityName": "Concert band",
                        "activityType": "Arts",
                        "gradeAchieved": "A+",
                        "teacherName": "",
                        "description": ""
                    },
                    {
                        "id": "a8e4ae356de78d0c8f11945ed7575fab",
                        "activityName": "Choir",
                        "activityType": "Arts",
                        "gradeAchieved": "A+",
                        "teacherName": "",
                        "description": ""
                    },
                    {
                        "id": "9387de2dfdd4d7ba73f1113a9f75fca7",
                        "activityName": "Jazz band",
                        "activityType": "Arts",
                        "gradeAchieved": "B+",
                        "teacherName": "",
                        "description": ""
                    }
                ],
                "advisorCourses": [
                    {
                        "id": "82633e9cf191b83e22e26ab457ab288a",
                        "courseName": "Accounting",
                        "courseType": "Academic Regular",
                        "gradeAchieved": "A",
                        "teacherName": "",
                        "description": ""
                    },
                    {
                        "id": "d113f5e6361116913c07257ec95aef69",
                        "courseName": "Business law",
                        "courseType": "Academic Regular",
                        "gradeAchieved": "A+",
                        "teacherName": "",
                        "description": ""
                    },
                    {
                        "id": "71a5259f2b9c6d04e9c78a83a0dcc77b",
                        "courseName": "Business management",
                        "courseType": "Academic Accelerated",
                        "gradeAchieved": "A",
                        "teacherName": "",
                        "description": ""
                    }
                ],
                "advisorWorkingHours": {
                    "mondayTimesheet": [
                        {
                            "startingTime": "05:00:00",
                            "closingTime": "16:00:00"
                        }
                    ],
                    "tuesdayTimesheet": [
                        {
                            "startingTime": "05:00:00",
                            "closingTime": "16:00:00"
                        }
                    ],
                    "wednesdayTimesheet": [
                        {
                            "startingTime": "05:00:00",
                            "closingTime": "16:00:00"
                        }
                    ],
                    "thursdayTimesheet": [
                        {
                            "startingTime": "05:00:00",
                            "closingTime": "16:00:00"
                        }
                    ],
                    "fridayTimesheet": [
                        {
                            "startingTime": "05:00:00",
                            "closingTime": "16:00:00"
                        }
                    ],
                    "saturdayTimesheet": [
                        {
                            "startingTime": "05:00:00",
                            "closingTime": "16:00:00"
                        }
                    ],
                    "sundayTimesheet": [
                        {
                            "startingTime": "05:00:00",
                            "closingTime": "16:00:00"
                        }
                    ]
                },
                "advisorReviewSummary": null,
                "defaultMeetingURL": "https://dev.leapbrains.com/onboarding/profile",
                "defaultMeetingPassword": "123456"
            },
            "fullName": "sreeram ram"
        }
    ]

    useEffect(() => {
        let advisorCount = [
            ...(Array.isArray(props?.advisorUpdatesCount?.receivedRequests)
                ? props?.advisorUpdatesCount?.receivedRequests.map((request, index) => {
                    return {
                        type: request?.type,
                        count: request?.count,
                        id: `request-${index}`
                    };
                })
                : []),
            ...(Array.isArray(props?.advisorUpdatesCount?.milestoneUpdates)
                ? props?.advisorUpdatesCount?.milestoneUpdates.map(update => {
                    console.log(update, "Milestone Update");
                    return {
                        count: update?.totalUpdates,
                        type: "milestone updates",
                        id: update?.milestoneId,
                        milestone: update?.milestones
                    };
                })
                : []),
            ...(Array.isArray(props?.advisorUpdatesCount?.courseUpdates)
                ? props?.advisorUpdatesCount?.courseUpdates.map(update => {
                    console.log(update, "Milestone Update");
                    return {
                        count: update?.totalUpdates,
                        type: "course updates",
                        id: update?._id,
                        courses: update?.courses
                    };
                })
                : []),
            ...(Array.isArray(props?.advisorUpdatesCount?.activityUpdates)
                ? props?.advisorUpdatesCount?.activityUpdates.map(update => {
                    return {
                        count: update?.totalUpdates,
                        type: "activity updates",
                        id: update?._id,
                        activity: update?.activities
                    };
                })
                : []),
        ];
        setAdvisorCount(advisorCount)

    }, [props?.advisorUpdatesCount])

    console.log(advisorCounts, "props?.advisorUpdatesCount")
    return (
        <>
            <div className="parentDashboard">
                <div className="dashboard-top-text">
                    <h2 className="welcomeUser">Welcome {props.userProfile && props.userProfile.firstName}! </h2>

                    <p className="mb-4 dash-subText"><span>Your next steps👇  </span></p>
                    <h3 className="dashTitle">Build your network and follow updates  🕸️</h3>
                </div>
                <div> <CarouselComponent data={advisorCounts} /> </div>
                <div>
                    <h6 className="dashTitle mb-2">Set your goals ! We recommend the best advisors for you based on these goals 👇</h6>
                    <div>
                        <div class="row g-4" >
                            <div className="col-md-6">
                                <Card variant="outlined" sx={{ textAlign: 'center', borderRadius: "10px" }}>
                                    <div className="dashSubContainer" >
                                        <h6 className="dashTitle" style={{ textAlign: 'center' }}>
                                            Set your target universities 🎯
                                        </h6>
                                        <Button className="btn cta--rounded cta-primary mt-1" style={{ borderRadius: '10px', marginRight: '1rem' }}>
                                            <span style={{ paddingLeft: '20px', paddingRight: '20px' }}>Update</span>
                                        </Button>
                                    </div>
                                    <div className="p-3">
                                        <PillSection data={props?.parentGoal?.universities} maxWidth={500} maxHeight={75} />
                                    </div>
                                </Card>
                            </div>

                            <div class="col-md-6">
                                <Card variant="outlined" sx={{ textAlign: 'center', borderRadius: "10px" }}>
                                    <div className="dashSubContainer" >
                                        <h6 className="dashTitle" style={{ textAlign: 'center' }}>
                                            Set your career goals 🎯
                                        </h6>
                                        <Button className="btn cta--rounded cta-primary mt-1" style={{ borderRadius: '10px', marginRight: '1rem' }}>
                                            <span style={{ paddingLeft: '20px', paddingRight: '20px' }}>Update</span>
                                        </Button>
                                    </div>
                                    <div className="p-3">
                                        <PillSection data={props?.parentGoal?.professions} maxWidth={369} maxHeight={75} />
                                    </div>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-3">
                    <Card variant="outlined" sx={{ textAlign: 'center', borderRadius: "10px" }}>
                        <div className="dashSubContainer" >
                            <h6 className="dashTitle" style={{ textAlign: 'center' }}>
                                Set your courses and Activities 🎯
                            </h6>
                            <Button className="btn cta--rounded cta-primary mt-1" style={{ borderRadius: '10px', marginRight: '1rem' }}>
                                <span style={{ paddingLeft: '20px', paddingRight: '20px' }}>Update</span>
                            </Button>
                        </div>
                        <div className="p-3">
                            <PillSection data={mappedData} maxWidth={1000} maxHeight={75} />
                        </div>
                    </Card>
                </div>
                <div className="mt-3">
                    <div className="dashSubContainer" >
                        <h6 className="dashTitle" style={{ textAlign: 'left' }}>
                            Connect with advisors who have achieved your goals🚀
                        </h6>
                        <Button className="btn cta--rounded cta-primary mb-1 mt-1" style={{ borderRadius: '10px', marginRight: '1rem' }}>
                            <span >Invite an advisor</span>
                        </Button>
                        <Button className="btn cta--rounded cta-primary mb-1" style={{ borderRadius: '10px', marginRight: '1rem' }}>
                            <span >Your Advisors</span>
                        </Button>
                        <Button className="btn cta--rounded cta-primary mb-1" style={{ borderRadius: '10px', }}>
                            <span >Explore Advisors</span>
                        </Button>
                    </div>
                    <CarouselComponent data={arrayData} />
                </div>
                <div className="mt-3">
                    <div className="dashSubContainer" >
                        <h6 className="dashTitle" style={{ textAlign: 'left' }}>
                            Connect with students with similar goals 🎓
                        </h6>
                        <Button className="btn cta--rounded cta-primary mb-1 mt-1" style={{ borderRadius: '10px', marginRight: '1rem' }}>
                            <span >Invite a Friend</span>
                        </Button>
                        <Button className="btn cta--rounded cta-primary mb-1" style={{ borderRadius: '10px', marginRight: '1rem' }}>
                            <span >Your Friends</span>
                        </Button>
                        <Button className="btn cta--rounded cta-primary mb-1" style={{ borderRadius: '10px', }}>
                            <span >Explore Students</span>
                        </Button>
                    </div>
                    <CarouselComponent
                        data={advisorCounts}
                    />
                </div>
                <div>
                    <div class="row mt-3">
                        <div className="col-md-6">
                            <Card variant="outlined" sx={{ textAlign: 'center', borderRadius: "10px" }}>
                                <h6 className="dashTitle mt-3" style={{ textAlign: 'center' }}>
                                    Schedule available advisor sessions  🗓️
                                </h6>
                                <div className="p-3">
                                    {scheduleData.map((schedule) => (
                                        <ScheduleCard key={schedule.id} title={schedule.title} time={schedule.time} />
                                    ))}
                                </div>
                            </Card>
                        </div>

                        <div class="col-md-6">
                            <Card variant="outlined" sx={{ textAlign: 'center', borderRadius: "10px" }}>
                                <h6 className="dashTitle  mt-3" style={{ textAlign: 'center' }}>
                                    Scheduled sessions  🗓️
                                </h6>
                                <div className="p-3">
                                    {scheduleData.map((schedule) => (
                                        <ScheduleCard key={schedule.id} title={schedule.title} time={schedule.time} />
                                    ))}
                                </div>
                                <Button className="btn cta--rounded cta-primary mb-3" style={{ borderRadius: '10px', }}>
                                    <span >View schedule</span>
                                </Button>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = ({ commonData, userProfileData, studentData, parentData }) => {
    const { childprofile, userProfile } = userProfileData;
    const { isgetusergoals, } = studentData;
    const { parentGoal, advisorUpdatesCount } = parentData;
    const { message, errorList, connectedAdvisors, paidSessionBooked, bookPaidSessionError, sessionBooked, booksessionerror, totalUserCount } = commonData;
    return { message, errorList, connectedAdvisors, isgetusergoals, parentGoal, userProfile, paidSessionBooked, bookPaidSessionError, sessionBooked, booksessionerror, totalUserCount, childprofile, advisorUpdatesCount };
};
export default connect(mapStateToProps, { clearMessage, bookPaidSessionErrorStatus, getChildProfile, getUserCount, bookSession, bookPaidSession, bookSessionStatus, bookPaidSessionStatus })(Index);
