import axios from 'axios'

import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS, CUSTOM_ALERT_SHOW, CUSTOM_ALERT_CLOSE, SET_COPY_FLAG, HIDE_MESSAGE } from "../constants/CommonTypes";

import {
	USER_PROFILE_SUCCESS_STATUS, SENT_INVITE_STATUS, GET_INVITE_SPOUSE, VERIFY_INVITES, VERIFY_INVITES_STATUS, USER_PROFILE_SUCCESS, USER_ROLE_SAVED, SET_PROFILE_SAVED_STATUS, SET_CHILD_PROFILE_SAVED_STATUS, SET_CHILD_EDIT_PROFILE_SAVED_STATUS,
	USER_PASSWORD_UPDATE, USER_ONBOARDED, PROFILE_PICTURE_UPDATE_SUCCESS, COPY_INVITE_LINK, VERIFY_INVITES_STATUS_DATA, USER_CHILD_PROFILE_SUCCESS, UPDATE_GENERAL_INFO, UPDATE_CURRENT_STATUS,
	GET_INVITE_PARENT_STATUS, GET_INVITE_PARENT, USER_PARENT_PROFILE_SUCCESS, PROFILE_PICTURE_SWITCH_SUCCESS, SET_CHILD_EDIT_PROFILE_ERROR_STATUS, SET_CHILD_PROFILE_ERROR_STATUS, SENT_INVITE_ERROR_STATUS, USER_CHILD_PROFILE_STATUS, USER_PASSWORD_UPDATE_ERROR, UPDATE_CURRENT_STATUS_ERROR, UPDATE_GENERAL_INFO_ERROR, SET_PROFILE_SAVED_STATUS_ERROR
	, COPY_INVITE_LINK_STATUS, DISCONNECT_USER, COPY_INVITE_ERROR_STATUS, PROFILE_PICTURE_SWITCH_DELETE, DISCONNECTED_FRIEND_SWITCH, USER_CHILD_PROFILE_USER_ACTIVITY, STUDENT_COURSE_ACTIVITY_STATUS, STUDENT_PUBLIC_COURSE_ACTIVITY_DATA
} from "../constants/ProfileActionTypes"

import { getUserData } from '../../utils/AuthUtils'
import { getPricePackages } from './Advisor';
import { clearErrorList } from './index';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const getUserProfile = () => {
	return (dispatch) => {
		axios.get(BASE_URL + '/user-service/v1/profile').then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: USER_PROFILE_SUCCESS, payload: data.recordInfo });
				dispatch({ type: USER_PROFILE_SUCCESS_STATUS, payload: true });
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		}).catch(function (error) {
			if (error.response) {
				const { headers: { message } } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
			}
			console.log("Error****:", error.message);
		});
	}
};
export const getUserProfilestatus = status => {
	return (dispatch) => {
		dispatch({ type: USER_PROFILE_SUCCESS_STATUS, payload: status });
	}
};
export const setUserRole = requestParams => {
	return (dispatch) => {
		dispatch({ type: FETCH_START })
		axios.post(BASE_URL + '/user-service/v1/profile/roles/update', requestParams).then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: USER_ROLE_SAVED });
				dispatch(getUserProfile());
			} else {
				console.log("message", message)
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		}).catch(function (error) {
			console.log("error", error)
			if (error.response) {
				const { headers: { message, statusCode }, errorlist, } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
			}
			console.log("Error****:", error.message);
		});
	}
};

export const updateUserProfile = (requestParams, value) => {
	return (dispatch) => {
		dispatch({ type: FETCH_START })
		axios.post(BASE_URL + '/user-service/v1/student/profile/update', requestParams).then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: SET_PROFILE_SAVED_STATUS, payload: true });
				dispatch(getUserProfile());
				if (value = "profile") {
					dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } })
					setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 6000)
				}
			} else {
				console.log("message", message)
				dispatch({ type: SET_PROFILE_SAVED_STATUS_ERROR, payload: true });
				dispatch({ type: FETCH_ERROR, payload: message });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
			}
		}).catch(function (error) {
			console.log("error", error)
			dispatch({ type: SET_PROFILE_SAVED_STATUS_ERROR, payload: true });
			if (error.response) {
				const { headers: { message, statusCode }, errorlist, } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
			}
			console.log("Error****:", error.message);
		});
	}
};
export const setIsUserProfileSuccess = status => {
	return (dispatch) => {
		dispatch({ type: SET_PROFILE_SAVED_STATUS, payload: status });
	}
};
export const UserProfileErrorStatus = status => {
	return (dispatch) => {
		dispatch({ type: SET_PROFILE_SAVED_STATUS_ERROR, payload: status });
	}
}
export const setAdvisorProfile = requestParams => {
	return (dispatch) => {
		dispatch({ type: FETCH_START })
		axios.post(BASE_URL + '/user-service/v1/advisor/profile/update', requestParams).then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: SET_PROFILE_SAVED_STATUS, payload: true });
				dispatch(getUserProfile());
				dispatch(getPricePackages());
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
			}
		}).catch(function (error) {
			if (error.response) {
				const { headers: { message, statusCode }, errorlist, } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
				if(!errorlist) {
					dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } })
					setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
				}
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
			}
			console.log("Error****:", error.message);
		});
	}
};
export const setIsAdvisorProfileSuccess = status => {
	return (dispatch) => {
		dispatch({ type: SET_PROFILE_SAVED_STATUS, payload: status });
	}
};

export const updateGeneralInfo = requestParams => {
	return (dispatch) => {
		dispatch({ type: FETCH_START })
		axios.post(BASE_URL + '/user-service/v1/advisor/profile/generalinfo/update', requestParams).then(({ data }) => {
			console.log("data", data);
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: UPDATE_GENERAL_INFO, payload: true });
				dispatch(getUserProfile());
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
				dispatch({ type: UPDATE_GENERAL_INFO_ERROR, payload: true })
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
			}
		}).catch((error) => {
			dispatch({ type: UPDATE_GENERAL_INFO_ERROR, payload: true })
			if (error.response) {
				const { headers: { message, statusCode }, errorlist, } = error.response?.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
			}
		})
	}
}
export const setIsUpdateGeneralInfoSuccess = status => {
	return (dispatch) => {
		dispatch({ type: UPDATE_GENERAL_INFO, payload: status });
	}
};
export const setIsUpdateGeneralInfoError = status => {
	return (dispatch) => {
		dispatch({ type: UPDATE_GENERAL_INFO_ERROR, payload: status });
	}
};
export const updateCurrentStatus = requestParams => {
	return (dispatch) => {
		dispatch({ type: FETCH_START })
		axios.post(BASE_URL + '/user-service/v1/advisor/profile/currentstatus/update', requestParams).then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: UPDATE_CURRENT_STATUS, payload: true });
				dispatch(getUserProfile());
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000);
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
				dispatch({ type: UPDATE_CURRENT_STATUS_ERROR, payload: true })
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
			}
		}).catch(function (error) {
			dispatch({ type: UPDATE_CURRENT_STATUS_ERROR, payload: true })
			if (error.response) {
				const { headers: { message }, errorlist } = error?.response?.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error?.response?.data?.headers?.message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error?.response?.data?.headers?.message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
			}
			console.log("Error****:", error.message);
		});
	}
};

export const setIsUpdateCurrentStatusSuccess = status => {
	return (dispatch) => { dispatch({ type: UPDATE_CURRENT_STATUS, payload: status }) }
}

export const setIsUpdateCurrentStatusError = status => {
	return (dispatch) => { dispatch({ type: UPDATE_CURRENT_STATUS_ERROR, payload: status }) }
}

export const changePassword = requestParams => {
	axios.defaults.headers.common['Authorization'] = "Bearer " + getUserData('accessToken');
	return (dispatch) => {
		dispatch({ type: FETCH_START })
		axios.post(BASE_URL + '/user-service/v1/profile/changepassword', requestParams).then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: USER_PASSWORD_UPDATE, payload: true });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } })
				dispatch(clearErrorList())
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
			}
		}).catch(function (error) {
			if (error.response) {
				dispatch({ type: USER_PASSWORD_UPDATE_ERROR, payload: true });
				const { headers: { message, statusCode }, errorlist, } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
			}
			console.log("Error****:", error.message);
		});
	}
};

export const changePasswordStatus = status => {
	return (dispatch) => { dispatch({ type: USER_PASSWORD_UPDATE, payload: status }) }
}
export const changePasswordErrorStatus = status => {
	return (dispatch) => { dispatch({ type: USER_PASSWORD_UPDATE_ERROR, payload: status }) }
}
export const setUserOnboard = () => {
	axios.defaults.headers.common['Authorization'] = "Bearer " + getUserData('accessToken');
	return (dispatch) => {
		axios.post(BASE_URL + '/user-service/v1/profile/onboard').then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: USER_ONBOARDED });
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		}).catch(function (error) {
			if (error.response) {
				const { headers: { message } } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
			}
			console.log("Error****:", error.message);
		});
	}
};

export const uploadProfilePictureOnBoarding = requestParams => {
	return (dispatch) => {
		dispatch({ type: FETCH_START });
		axios.post(BASE_URL + '/user-service/v1/profile/profilepicture', requestParams)
			.then(({ data: response }) => {
				if (response.headers.statusCode === '200') {
					dispatch({ type: FETCH_SUCCESS });
					dispatch({ type: PROFILE_PICTURE_UPDATE_SUCCESS });
					dispatch({ type: HIDE_MESSAGE });
					// dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: response.headers.message, success: true } })
					// setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 5000)
					dispatch(getUserProfile());
				} else {
					dispatch({ type: FETCH_ERROR, payload: response.headers.message });
					dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: response.headers.message, success: false } })
					setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 5000)
				}
			}).catch(function (error) {
				if (error.response) {
					const { headers: { message }, errorlist } = error.response.data;
					dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist } });
					// dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
					setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 5000)
				} else {
					dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
					dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
					setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 5000)
				}
		});
	}
}
export const uploadProfilePicture = requestParams => {
	return (dispatch) => {
		dispatch({ type: FETCH_START });
		axios.post(BASE_URL + '/user-service/v1/profile/profilepicture', requestParams)
			.then(({ data: response }) => {
				if (response.headers.statusCode === '200') {
					dispatch({ type: FETCH_SUCCESS });
					dispatch({ type: PROFILE_PICTURE_UPDATE_SUCCESS });
					dispatch({ type: HIDE_MESSAGE });
					dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: response.headers.message, success: true } })
					setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 5000)
					dispatch(getUserProfile());
				} else {
					dispatch({ type: FETCH_ERROR, payload: response.headers.message });
					dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: response.headers.message, success: false } })
					setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 5000)
				}
			}).catch(function (error) {
				if (error.response) {
					const { headers: { message }, errorlist } = error.response.data;
					dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist } });
					// dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
					setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 5000)
				} else {
					dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
					dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
					setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 5000)
				}
		});
	}
}

export const deleteProfilePicture = () => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		await axios.post(BASE_URL + '/user-service/v1/profile/deleteprofilepicture')
			.then(({ data: response }) => {
				if (response.headers.statusCode === '200') {
					dispatch({ type: FETCH_SUCCESS });
					dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: response.headers.message, success: true } })
					setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
					dispatch(getUserProfile());
				}
			}).catch((error) => {
				if (error.response) {
					const { headers: { message } } = error.response.data;
					dispatch({ type: FETCH_ERROR, payload: message });
					dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
					setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
				} 
		});
	}
}
export const uploadSwitchChidPicture = requestParams => {
	return (dispatch) => {
		dispatch({ type: FETCH_START });
		axios.post(BASE_URL + '/user-service/v1/parent/profile/accounts/students/updateavatar', requestParams)
			.then(({ data: response }) => {
				if (response.headers.statusCode === '200') {
					dispatch({ type: FETCH_SUCCESS });
					dispatch({ type: PROFILE_PICTURE_SWITCH_SUCCESS, payload: true });
					dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: response.headers.message, success: true } })
					setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)

				} else {
					dispatch({ type: FETCH_ERROR, payload: response.headers.message });
					dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: response.headers.message, success: false } })
					setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
				}
			}).catch(function (error) {
				if (error.response) {
					const { headers: { message }, errorlist } = error.response.data;
					dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist } });
					dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
					setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
				} else {
					dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
					dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
					setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
				}
			});
	}
}
export const changePicSwitchStatus = status => {
	return (dispatch) => { dispatch({ type: PROFILE_PICTURE_SWITCH_SUCCESS, payload: status }) }
}
export const deleteSwitchChidPicture = requestParams => {
	return (dispatch) => {
		dispatch({ type: FETCH_START });
		axios.post(BASE_URL + '/user-service/v1/parent/profile/accounts/students/deleteavatar', requestParams)
			.then(({ data: response }) => {
				if (response.headers.statusCode === '200') {
					dispatch({ type: FETCH_SUCCESS });
					dispatch({ type: PROFILE_PICTURE_SWITCH_DELETE, payload: true });
					dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: response.headers.message, success: true } })
					setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)

				} else {
					dispatch({ type: FETCH_ERROR, payload: response.headers.message });
					dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: response.headers.message, success: false } })
					setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
				}
			}).catch(function (error) {
				if (error.response) {
					const { headers: { message }, errorlist } = error.response.data;
					dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist } });
					dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
					setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
				} else {
					dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
					dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
					setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
				}
			});
	}
}
export const setParentProfile = (requestParams, value) => {
	return (dispatch) => {
		dispatch({ type: FETCH_START });
		axios.post(BASE_URL + '/user-service/v1/parent/profile/update', requestParams).then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: SET_PROFILE_SAVED_STATUS, payload: true });
				dispatch(getUserProfile());
				dispatch(getChildProfile());
				if (value === "profile") {
					dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } });
					setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
				}
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
			}
		}).catch(function (error) {
			if (error.response) {
				const { headers: { message, statusCode }, errorlist, } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
			}
			console.log("Error****:", error.response.data);
		});
	}
}
export const setIsParentProfileSuccess = status => {
	return (dispatch) => {
		dispatch({ type: SET_PROFILE_SAVED_STATUS, payload: status });
	}
};
export const setChildProfile = (requestParams, value) => {
	return (dispatch) => {
		dispatch({ type: FETCH_START });
		axios.post(BASE_URL + '/user-service/v1/parent/profile/accounts/createchildprofile', requestParams).then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: SET_CHILD_PROFILE_SAVED_STATUS, payload: true });
				dispatch({ type: FETCH_SUCCESS });

				dispatch(getUserProfile());
				dispatch(getChildProfile());
				if (value === "profile") {
					dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } })
					setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
				}
				// dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
			} else {
				dispatch({ type: SET_CHILD_PROFILE_ERROR_STATUS, payload: true });
				dispatch({ type: FETCH_ERROR, payload: message });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
			}
		}).catch(function (error) {
			dispatch({ type: SET_CHILD_PROFILE_ERROR_STATUS, payload: true });
			if (error.response) {
				const { headers: { message, statusCode }, errorlist, } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
			}
			console.log("Error****:", error.response.data);
		});
	}
}
export const setIsChildProfileError = status => {
	return (dispatch) => {
		dispatch({ type: SET_CHILD_PROFILE_ERROR_STATUS, payload: status });
	}
};
export const setIsChildProfileSuccess = status => {
	return (dispatch) => {
		dispatch({ type: SET_CHILD_PROFILE_SAVED_STATUS, payload: status });
	}
};
export const getChildProfile = () => {
	return async (dispatch) => {
		await axios.get(BASE_URL + '/user-service/v1/parent/profile/accounts/viewallstudents').then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: USER_CHILD_PROFILE_STATUS, payload: true });
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: USER_CHILD_PROFILE_SUCCESS, payload: data.user });
				dispatch({ type: USER_CHILD_PROFILE_USER_ACTIVITY, payload: data.userLastActivities });

			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		}).catch(function (error) {
			if (error.response) {
				const { headers: { message } } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
			}
			console.log("Error****:", error.message);
		});
	}
};
export const getParentProfile = () => {
	return (dispatch) => {
		axios.get(BASE_URL + '/user-service/v1/parent/profile/accounts/viewparents').then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: USER_CHILD_PROFILE_STATUS, payload: true });
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: USER_PARENT_PROFILE_SUCCESS, payload: data.data });
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		}).catch(function (error) {
			if (error.response) {
				const { headers: { message } } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
			}
			console.log("Error****:", error.message);
		});
	}
};
export const getChildProfilestatus = status => {
	return (dispatch) => {
		dispatch({ type: USER_CHILD_PROFILE_STATUS, payload: status });
	}
};
export const editChildProfile = requestParams => {
	return (dispatch) => {
		dispatch({ type: FETCH_START });
		axios.post(BASE_URL + '/user-service/v1/parent/profile/accounts/editchildprofile', requestParams).then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: SET_CHILD_EDIT_PROFILE_SAVED_STATUS, payload: true });
				dispatch(getUserProfile());
				dispatch(getChildProfile());
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
			} else {
				dispatch({ type: SET_CHILD_EDIT_PROFILE_ERROR_STATUS, payload: true });
				dispatch({ type: FETCH_ERROR, payload: message });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
			}
		}).catch(function (error) {
			dispatch({ type: SET_CHILD_EDIT_PROFILE_ERROR_STATUS, payload: true });
			if (error.response) {
				const { headers: { message, statusCode }, errorlist, } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error?.response?.data?.headers?.message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
			}

		});
	}
}
export const editIsChildProfileError = status => {
	return (dispatch) => {
		dispatch({ type: SET_CHILD_EDIT_PROFILE_ERROR_STATUS, payload: status });
	}
};
export const editIsChildProfileSuccess = status => {
	return (dispatch) => {
		dispatch({ type: SET_CHILD_EDIT_PROFILE_SAVED_STATUS, payload: status });
	}
};
export const CopyInviteLink = (requestParams) => {
	return (dispatch) => {
		dispatch({ type: FETCH_START });
		axios.post(BASE_URL + '/user-service/v1/user-invite/generateurl', requestParams).then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: COPY_INVITE_LINK, payload: data.invitationLink })
				dispatch({ type: COPY_INVITE_LINK_STATUS, payload: true })
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 5000)
			}
		}).catch(function (error) {
			if (error.response) {
				const { headers: { message, statusCode }, errorlist, } = error.response.data;
				dispatch({ type: COPY_INVITE_ERROR_STATUS, payload: true })
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.errorlist.email.split(" ").slice(0, -4).join(" "), success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 5000)
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 5000)
			}
			console.log("Error****:", error.message);
		});
	}
}
export const CopyInviteLinkstatus = status => {
	return (dispatch) => {
		dispatch({ type: SET_COPY_FLAG, payload: status });
	}
};
export const CopyInviteErrorstatus = status => {
	return (dispatch) => {
		dispatch({ type: COPY_INVITE_ERROR_STATUS, payload: status });
	}
};
export const SentInviteDefault = requestParams => {
	return (dispatch) => {
		dispatch({ type: FETCH_START });
		axios.post(BASE_URL + '/user-service/v1/user-invite/default', requestParams).then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: SENT_INVITE_STATUS, payload: true })
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
			}
		}).catch(function (error) {
			if (error.response) {
				const { headers: { message, statusCode }, errorlist, } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.errorlist.email.split(" ").slice(0, -4).join(" "), success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
			}
			console.log("Error****:", error.message);
		});
	}
}
export const SentInviteSpouse = requestParams => {
	return (dispatch) => {
		dispatch({ type: FETCH_START });
		axios.post(BASE_URL + '/user-service/v1/user-invite/spouse', requestParams).then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: SENT_INVITE_STATUS, payload: true })
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
			}
		}).catch(function (error) {
			if (error.response.data.headers?.message !== " ") {
				console.log("else if")
				dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.headers?.message } });
				// dispatch(SentInviteParentErrorstatus(true));
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
			}
			if (error.response) {
				const { headers: { message, statusCode }, errorlist, } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.errorlist.email === "Invalid email provided" ? error.response.data.errorlist.email : error.response.data.errorlist.email.split(" ").slice(0, -4).join(" "), success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
			}
			console.log("Error****:", error.response.data.errorlist.email);
		});
	}
}
export const GetInviteSpouse = () => {
	return (dispatch) => {
		dispatch({ type: FETCH_START });
		axios.get(BASE_URL + '/user-service/v1/user-invite/spouse').then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: GET_INVITE_SPOUSE, payload: data?.data })

			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		}).catch(function (error) {
			if (error.response) {
				const { headers: { message, statusCode }, errorlist, } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });

			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });

			}
		});
	}
}
export const SentInviteParent = requestParams => {
	return (dispatch) => {
		dispatch({ type: FETCH_START });
		axios.post(BASE_URL + '/user-service/v1/user-invite/parent', requestParams).then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: SENT_INVITE_STATUS, payload: true })
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
			}
		}).catch(function (error) {
			if (error.response) {
				if (error.response.data.headers?.message !== " ") {
					console.log("else if")
					dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.headers?.message } });
					dispatch(SentInviteParentErrorstatus(true));
					dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } })
					setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
				}
				else if (error.response.data.errorlist?.email !== " ") {
					console.log("if")
					dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.errorlist?.email } });
					dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.errorlist?.email, success: false } })
					setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
				}
				else {
					console.log("else")
					const { headers: { message, statusCode }, errorlist, } = error.response.data;
					dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
					dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.errorlist.email.split(" ").slice(0, -4).join(" "), success: false } })
					setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
				}

			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
			}
			console.log("Error****:", error.message);
		});
	}
}
export const SentInviteParentstatus = status => {
	return (dispatch) => {
		dispatch({ type: SENT_INVITE_STATUS, payload: status });
	}
};
export const SentInviteParentErrorstatus = status => {
	return (dispatch) => {
		dispatch({ type: SENT_INVITE_ERROR_STATUS, payload: status });
	}
};
export const GetInviteParent = () => {
	return (dispatch) => {
		dispatch({ type: FETCH_START });
		axios.get(BASE_URL + '/user-service/v1/user-invite/parent').then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: GET_INVITE_PARENT, payload: data?.data })
				dispatch({ type: GET_INVITE_PARENT_STATUS, payload: true })
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		}).catch(function (error) {
			if (error.response) {
				const { headers: { message, statusCode }, errorlist, } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });

			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });

			}
		});
	}
}
export const GetInviteParentstatus = status => {
	return (dispatch) => {
		dispatch({ type: GET_INVITE_PARENT_STATUS, payload: status });
	}
};
export const verifyInvite = requestParams => {
	return (dispatch) => {
		axios.post(BASE_URL + '/user-service/v1/user-invite/process/verifyinvite', requestParams).then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: VERIFY_INVITES });
				dispatch({ type: VERIFY_INVITES_STATUS, payload: true })
				dispatch({ type: VERIFY_INVITES_STATUS_DATA, payload: data.recordInfo })
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
				dispatch({ type: VERIFY_INVITES_STATUS, payload: false })
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { message: message, statusCode: statusCode, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
			}
		}).catch(function (error) {
			if (error.response) {
				const { headers: { message, statusCode }, errorlist, } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
				dispatch({ type: VERIFY_INVITES_STATUS, payload: false })
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
				dispatch({ type: VERIFY_INVITES_STATUS, payload: false })
			}
			console.log("Error****:", error.message);
			dispatch({ type: VERIFY_INVITES_STATUS, payload: false })
			dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: "Invalid Request Initiated", success: false } })
			setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
		});
	}
}
export const verifyInvitestatus = status => {
	return (dispatch) => {
		dispatch({ type: VERIFY_INVITES_STATUS, payload: status });
	}
};
export const disconnectUser = requestParams => {
	return (dispatch) => {
		dispatch({ type: FETCH_START });
		axios.post(BASE_URL + '/user-service/v1/network/disconnect', requestParams).then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: DISCONNECT_USER, payload: true })
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
			}
		}).catch(function (error) {
			if (error.response) {
				const { headers: { message, statusCode }, errorlist, } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.errorlist.email.split(" ").slice(0, -4).join(" "), success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
			}
			console.log("Error****:", error.message);
		});
	}
}
export const disconnectUserStatus = status => {
	return (dispatch) => {
		dispatch({ type: DISCONNECT_USER, payload: status });
	}
};
export const disconnectSwitchFriend = (id, requestParams) => {
	return (dispatch) => {
		dispatch({ type: FETCH_START });
		axios.post(BASE_URL + '/user-service/v1/network/parents/disconnectuserofstudent/'+ id, requestParams).then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: DISCONNECTED_FRIEND_SWITCH, payload: true })
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
			}
		}).catch(function (error) {
			if (error.response) {
				const { headers: { message, statusCode }, errorlist, } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.errorlist.email.split(" ").slice(0, -4).join(" "), success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
			}
			console.log("Error****:", error.message);
		});
	}
}
export const disconnectUserSwitchStatus = status => {
	return (dispatch) => {
		dispatch({ type: DISCONNECTED_FRIEND_SWITCH, payload: status });
	}
};
export const getStudentCourseActivityDetails = id => {
	return async (dispatch) => {
	  dispatch({ type: FETCH_START });
	  await axios.get(BASE_URL + '/student-plans/v1/studentcourseactivities/public/' + id).then(({ data }) => {
		const { headers: { statusCode, message } } = data
		if (statusCode === "200") {
		  dispatch({ type: FETCH_SUCCESS });
		  dispatch({ type: STUDENT_COURSE_ACTIVITY_STATUS, payload: true })
		  dispatch({ type: STUDENT_PUBLIC_COURSE_ACTIVITY_DATA, payload: data })
		} else {
		  dispatch({ type: FETCH_ERROR, payload: message });
		}
	  }).catch(function (error) {
		if (error.response) {
		  const { headers: { message } } = error.response.data;
		  dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
		} else {
		  dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
		}
		console.log("Error****:", error.message);
	  });
	}
  }
  export const resetStudentCourseActivityDetails = () => {
	return (dispatch) => {
	  dispatch({ type: STUDENT_PUBLIC_COURSE_ACTIVITY_DATA, payload: [] });
	}
  };
  export const setStudentCourseActivityDetailStatus = status => {
	return (dispatch) => {
	  dispatch({ type: STUDENT_COURSE_ACTIVITY_STATUS, payload: status });
	}
  };