import React, { useEffect, useState } from "react";
import { Alert, Button, Modal } from "react-bootstrap";
import "./addEditResource.less"
import { Grid } from "@mui/material";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "../../../redux/store/navigate";
import { updateResource, updateResourceStatus, GetMilestoneByActivity, GetMilestoneByCourse, addNewResource, addNewResourceStatus } from '../../../redux/actions'
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import CustomAlert from "../../../components/CustomAlert";
import { ALERT_HIDE } from "../../../redux/constants/CommonTypes";


const validation = Yup.object({
    name: Yup.string().required("*Required").max(500, "Description only allow 750 Characters").nullable().matches(/^[a-zA-Z0-9\s]*$/, "Description can only allow alphabets and numbers"),
    url: Yup.string().nullable().url("Link should be valid url"),
})

const Index = (props) => {
    const { state } = useLocation();
    const dispatch = useDispatch()
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const { register, handleSubmit, reset, watch, formState: { errors }, setValue, clearErrors, } = useForm({
        mode: "onSubmit",
        resolver: yupResolver(
            validation
        ),
    });
    let resourceName = watch("name")

    useEffect(() => {
        setIsButtonDisabled(false)
    }, [])

    useEffect(() => {
        let { description, link } = props.editData
        setValue("name", description)
        setValue("url", link)
        clearErrors()
    }, [props.editData])

    console.log(props.editData, "props.editData")

    const onSubmit = (data) => {
        if (Object.keys(props.editData).includes('resourceId')) {
            let payload = {
                ...data,
                milestoneId: props?.mileStoneData?.id,
                resourceId: props.editData?.resourceId,
                planId: props?.mileStoneData?.planId
            }
            props.updateResource(payload)      // edit Api (Resouce)
            setIsButtonDisabled(true)
        } else if (!Object.keys(props.editData).includes('resourceId') && props.mileStoneData?.id) {
            let payload = {
                ...data,
                milestoneId: props?.mileStoneData?.id,
                planId: props?.mileStoneData?.planId
            }
            props.addNewResource(payload)
            setIsButtonDisabled(true)

        } else {
            console.log(data, "datasdsdsd")
            if (props.editData?.index >= 0) {
                data['index'] = props.editData?.index
            }
            props.setData(data)
            props.onHide()
            reset()

        }
    }

    useEffect(() => {
        if (props?.isUpdateResource) {
            if (state?.activityData?.id) {
                let payload = {
                    "planId": state?.activityData?.planId,
                    "activityId": state?.activityData?.id
                }
                props?.GetMilestoneByActivity(payload)
                props?.updateResourceStatus(false)
                props.onHide()
                setIsButtonDisabled(false)
            } else {
                let payload = {
                    "planId": state?.courseData?.planId,
                    "courseId": state?.courseData?.id
                }
                props?.GetMilestoneByCourse(payload)
                props?.updateResourceStatus(false)
                props.onHide()
                setIsButtonDisabled(false)

            }

        }
    }, [props.isUpdateResource])

    useEffect(() => {
        if (props?.isaddNewResource) {
            if (state?.activityData?.id) {
                let payload = {
                    "planId": state?.activityData?.planId,
                    "activityId": state?.activityData?.id
                }
                props?.GetMilestoneByActivity(payload)
                props?.addNewResourceStatus(false)
                reset()
                props.onHide()
                setIsButtonDisabled(false)
            } else {
                let payload = {
                    "planId": state?.courseData?.planId,
                    "courseId": state?.courseData?.id
                }
                props?.GetMilestoneByCourse(payload)
                props?.addNewResourceStatus(false)
                reset()
                props.onHide()
                setIsButtonDisabled(false)

            }
        }
    }, [props.isaddNewResource])

    return (
        <Modal style={{ "backgroundColor": "rgba(0,0,0,0.4)" }}
            size={"md"}
            {...props}
            centered={true}
            dialogclassName={"addResource-popup"}
            className="addResource-popup"
        >
            <Modal.Header closeButton className="addResource-popup__header">
                <h1 className="addResource__title">{Object.keys(props.editData).length > 0 ? `Edit` : `Add`} Resource</h1>
            </Modal.Header>
            <Modal.Body className="addResource-popup__body">
                <span style={{display: 'flex', justifyContent: 'center'}}>
                    {(props.alertShow && Object.keys(props?.errorList) == 0) && <Alert
                            {...props}
                            variant={props.customAlertSuccess ? 'success' : 'danger'}
                            onClose= {() => dispatch({ type: ALERT_HIDE })}
                            dismissible
                            >
                                {props.customAlertMsg}
                                
                        </Alert>}
                </span>
                <div className="addResource">
                    <form className="addResource__form" onSubmit={handleSubmit(onSubmit)}>
                        <Grid container mt={2}>
                            <Grid item lg={12} md={12} sm={12} xs={12} >
                                <div className="input-floating-label">
                                    <textarea {...register("name")} maxLength={500} className={"textarea--primary textbox--rounded input"} name="name"
                                        placeholder=" " style={{ "minHeight": "100px", "resize": "none" }} id="resourceName" onKeyPress={(event) => { if (! /^[A-Za-z0-9 ]+$/.test(event.key)) { event.preventDefault(); } }} />
                                    <label >Description</label>
                                    <div className="error-text" style={{ "position": "absolute" }} >{errors?.name?.message}</div>
                                    <div className="subtext" style={{ float: 'right' }}><span>{resourceName?.length || 0}</span>/500</div>
                                </div>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12} >
                                <div className="input-floating-label">
                                    <input refs="resourceLink" {...register("url")} type="text" className="textbox--primary textbox--rounded input"
                                        name="url" placeholder="Link" id="resourceUrl" />
                                    <label>Link</label>
                                    <div style={{ "display": "flex", "justifyContent": "space-between" }}>
                                        <div className="error-text" >{errors?.url?.message}</div>
                                        <p className="cta--text" style={{ "fontSize": "12px", "float": "right" }}>Optional</p>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                        <div style={{ display:"flex","justifyContent":"flex-end" }}>
                            <Button className="modalBtn" type="submit"  disabled={isButtonDisabled}>
                                Update
                            </Button>
                        </div>
                    </form>
                </div>
            </Modal.Body>
        </Modal>
    );
};

const mapStateToProps = ({ studentData, commonData, userProfileData }) => {
    const { isUpdateResource, isaddNewResource } = studentData;
    const { errorList, alertShow, customAlertSuccess, customAlertMsg } = commonData;
    const { userProfile } = userProfileData;
    return { errorList, isUpdateResource, isaddNewResource, userProfile, alertShow, customAlertSuccess, customAlertMsg };
};

export default connect(mapStateToProps, { updateResource, updateResourceStatus, addNewResource, addNewResourceStatus, GetMilestoneByActivity, GetMilestoneByCourse })(withRouter(Index));

