import React, { Component } from "react";
import Table from "react-bootstrap/Table";
import Avatar1 from "../../../../assets/images/icons/Avatar1.png";
import Avatar2 from "../../../../assets/images/icons/Avatar2.png";
import Buttons from "../../../../../src/Widget/Buttons";
import Select, { components } from "react-select";
import Title from "../Title";
import Transition from "../Transition"

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            view: false,
        };
    }
    render() {
        let { close } = this.props;
        const TransactionsListData = [
            {
                AvatarIcon: Avatar1,
                Name: "Cora Bailey",
                Rate: "55$",
                tags: ["Millestone"],
                FromDate: "03/01/2021",
                ToDate: "06/10/2022",
                button: "Accepted",
            },
            {
                AvatarIcon: Avatar2,
                Name: "Shaun White",
                Rate: "221$",
                tags: ["Course"],
                FromDate: "03/01/2021",
                ToDate: "06/10/2022",
                button: "Declined",
            },
            {
                AvatarIcon: Avatar1,
                Name: "John Seel",
                Rate: "120$",
                tags: ["Plan"],
                FromDate: "03/01/2021",
                ToDate: "06/10/2022",
                button: "Accepted",
            },
            {
                AvatarIcon: Avatar1,
                Name: "Cora Bailey",
                Rate: "55$",
                tags: ["Millestone"],
                FromDate: "03/01/2021",
                ToDate: "06/10/2022",
                button: "Accepted",
            },
            {
                AvatarIcon: Avatar2,
                Name: "Shaun White",
                Rate: "221$",
                tags: ["Course"],
                FromDate: "03/01/2021",
                ToDate: "06/10/2022",
                button: "Declined",
            },
            {
                AvatarIcon: Avatar1,
                Name: "John Seel",
                Rate: "120$",
                tags: ["Plan"],
                FromDate: "03/01/2021",
                ToDate: "06/10/2022",
                button: "Accepted",
            },
        ];

        let { selectoption } = this.state;
        const { ValueContainer, Placeholder } = components;
        const CustomValueContainer = ({ children, ...props }) => {
            return (
                <ValueContainer {...props}>
                    <Placeholder {...props} isFocused={props.isFocused}>
                        {props.selectProps.placeholder}
                    </Placeholder>
                    {React.Children.map(children, (child) =>
                        child && child.type !== Placeholder ? child : null
                    )}
                </ValueContainer>
            );
        };
        const styles = {
            placeholder: (base, state) => ({
                ...base,
                display:
                    state.isFocused ||
                    state.isSelected ||
                    state.selectProps.inputValue
                        ? "none"
                        : "block",
            }),
        };
        var TransactionsList =
            TransactionsListData &&
            TransactionsListData.map((element, key) => {
                return (
                    <>
                        <tr>
                            <td>
                                <div className="avisor-image ps-0">
                                    <img src={element.AvatarIcon} />
                                </div>
                            </td>

                            <td>
                                <div className="avisor-name">
                                    <p>{element.Name} </p>
                                </div>
                            </td>
                            <td>
                                <div className="avisor-rate">
                                    <p>{element.Rate}</p>
                                </div>
                            </td>
                            <td>
                                <div className="advisor-card tag">
                                <div className="card__item__lists__tags">
                                    {element.tags.map((tag, key) => (
                                        <span key={tag.toString() + key}>
                                            {tag}
                                        </span>
                                    ))}
                                  
                                </div>
                                </div>
                            </td>
                            <td>
                                <div className="avisor-from-date">
                                    <p>{element.FromDate}</p>
                                </div>
                            </td>
                            <td>
                                <div className="avisor-to-date">
                                    <p>{element.ToDate}</p>
                                </div>
                            </td>
                            <td>
                                <div className="avisor-button">
                                    <Buttons iconButton={element} />
                                </div>
                            </td>
                        </tr>
                    </>
                );
            });
        return (
            <>
                <Title handleClose={close} />
                <Transition />
                {TransactionsListData && TransactionsListData.length > 0 && (
                    <div className="advisor-wrap mt-5">
                    <div className="adv-trans-list">
                        <div className="row">
                            <div className="col-6 col-sm-8 col-md-8 col-lg-7 col-xl-7 col-xxl-9">
                                <h2 className="adv-trans-list__heading ">
                                    Transactions list
                                </h2>
                            </div>
                            <div className="col-6 col-sm-4 col-md-4 col-lg-5 col-xl-5 col-xxl-3">
                                <Select
                                    className="goals__form__select adv-trans-list__trans-select"
                                    classNamePrefix="mySelect"
                                    name="graduationYear"
                                    options={selectoption}
                                    closeMenuOnSelect={true}
                                    isClearable={false}
                                    components={{
                                        ValueContainer: CustomValueContainer,
                                        IndicatorSeparator: () => null,
                                    }}
                                    placeholder="Last Month"
                                    styles={{
                                        container: (provided, state) => ({
                                            ...provided,
                                            height: "38px",
                                            overflow: "visible",
                                        }),
                                        valueContainer: (provided, state) => ({
                                            ...provided,
                                            overflow: "visible",
                                            height: "100%",
                                            minHeight: "38px",
                                        }),
                                        placeholder: (provided, state) => ({
                                            ...provided,
                                            position: "absolute",
                                            top:
                                                state.hasValue ||
                                                state.selectProps.inputValue
                                                    ? -13
                                                    : "25%",
                                            fontSize:
                                                (state.hasValue ||
                                                    state.selectProps
                                                        .inputValue) &&
                                                12,
                                           background: "#fff",
                                            paddingLeft: 0,
                                            paddingRight: 0,
                                            marginLeft:0,
                                            marginRight:0,
                                        }),
                                    }}
                                />
                            </div>
                        </div>
                        
                        <div  style={{overflow:"auto"}}>
                            <Table
                           
                                responsive="sm md lg xl"
                                className=" table table-section"
                            >
                                <thead>
                                    <tr>
                                        {/* <th>{TableHeader.header1}</th> */}
                                    </tr>
                                </thead>
                                <tbody className=" ">{TransactionsList}</tbody>
                            </Table>
                        </div>
                    </div>
                    </div>
                )}
            </>
        );
    }
}
export default Index;
