import React, { useState, useEffect } from "react";
import "./profile.less"
import { Button, Modal, Typography, Box, Stack, Grid, TextField, Autocomplete, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { useForm, Controller } from "react-hook-form";
import Select, { components } from "react-select";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
// import { useComponentDidMount, useComponentDidUpdate } from "../../../../utils/useEffectHooks";
import { COUNTRY_NAME_LABEL, FIRST_NAME_LABEL, GENDER_LABEL, GPA_LABEL, GRADUATION_YEAR_LABEL, High_SCHOOL_LABEL, LAST_NAME_LABEL, MY_STATUS_LABEL, ZIP_CODE_LABEL, EMAIL_LABEL } from "../../../Constants/commonLabels";
import { schoolApi } from "../../../utils/AutoComplete";
import { connect } from "react-redux";
import { SentInviteSpouse} from "../../../redux/actions";




const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: "50%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,

};

const validation = Yup.object().shape({
  email: Yup.string().required("* Required")
    .matches(
      /^([\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,64})(,\s*[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,64})*$/,
      'Please enter valid email addresses'
    )
});

const Index = (props) => {
  const [highSchoolData, sethighSchoolData] = useState([]);
  const [selectSchoolOption, setselectSchoolOption] = useState([]);

  const { register, handleSubmit, watch, reset, setValue, control, formState: { errors } } = useForm({ resolver: yupResolver(validation), });



  const handleClose = () => {
    props?.handleClose();
    setselectSchoolOption([])
    reset()
  };


  const onSubmit = (data) => {
    console.log(data, "submyt")
      let emailLst = []
      let Emailfield
      if (data?.email) {
          Emailfield = data?.email?.split(',')
          Emailfield?.map(element => {
              if(element.trim() !== ""){
                  emailLst.push(element.trim())
              }
          });
      }
      let values = {
          emails: emailLst,
      }
    
     props.SentInviteSpouse(values);
      

    handleClose()
  };


  return (
    <Modal
      open={props?.show}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} className="disconnectUser">
        <form onSubmit={handleSubmit(onSubmit)}>
        <p className="profile__form__sub-title">Connect Another Spouse</p>
         
         
          <div className="row m-0">
            <div className="col-md-12 col-sm-12 col-12 p-0">
              <div className="input-floating-label">
                <input {...register("email")} type="text" className={"textbox--primary textbox--rounded input"} name="email" placeholder=" " />
                <label>{EMAIL_LABEL}</label>
                <div className="error-text">{errors.email?.message}</div>
              </div>
            </div>

          </div>
          
          <Grid container className="justifyContent popupConfirm">
            <Stack
              direction="row"
              className="flex-end btns--pay-stack"
              spacing={2}
            >
              
              <Button variant="outlined"  onClick={handleClose} className="outLineBtn" >Close</Button>
              <Button
                type="submit"
                variant="contained"
                className="blueBtn"
                style={{ textTransform: "none" }}
                size="small"
              >
                Send Invite
              </Button>
            </Stack>
          </Grid>

          
        </form>
      </Box>
    </Modal>
  );
}
const mapStateToProps = ({ userProfileData, commonData }) => {
  const { userProfile, isProfileSuccess, childprofile, parentProfile } = userProfileData;
  const { status, gender, country, graduationyear, schools } = commonData;
  return { userProfile, isProfileSuccess, status, gender, schools, country, childprofile, parentProfile, graduationyear };
};

export default connect(mapStateToProps, {SentInviteSpouse})(Index);
