import React, { Component } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "../../../assets/css/studentdashboard.less";
import Search from "../../../components/Search";
import OverViewCarousel from "../../../components/Carousel/OverViewCarousel";
import Milestones from "../../../components/Card/MilestoneCard";
import UpcommingSession from "../../../components/UpCommingSession";
import RecommendedFriends from "../../../components/RecommendedFriends";
import Avatar1 from "../../../assets/images/icons/Avatar1.png";
import Avatar2 from "../../../assets/images/icons/Avatar2.png";
import { userSignIn, getUserProfile } from "../../../redux/actions";
import { connect } from "react-redux";
import { withRouter } from "../../../redux/store/navigate";
import List from "./List";

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            show: false,
            setDate: "",
            date: "",
            isActive: false,
            showList: false,
        };
    }
    componentDidMount() {
        const message = this.props.message;
        const errorList = this.props.errorList;
        this.props.getUserProfile();
    }

    handleClickMobilesearch(e) {
        e.preventDefault();
        const currentState = this.state.isActive;
        this.setState({ isActive: !currentState });
    }
    handleShowList(data) {
        this.setState({ showList: data });
    }
    handleBack(closeData) {
        this.setState({ showList: closeData });
    }
    render() {
        let { isActive } = this.state;
        const OverviewData = [
            {
                id: 1,
                title: "Total Earned",
                image: (
                    <svg
                        width="48"
                        height="48"
                        viewBox="0 0 48 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle cx="24" cy="24" r="24" fill="#FA5F1C" />
                        <path
                            d="M24 34C29.5228 34 34 29.5228 34 24C34 18.4772 29.5228 14 24 14C18.4772 14 14 18.4772 14 24C14 29.5228 18.4772 34 24 34Z"
                            stroke="white"
                            strokeWidth="2.13004"
                            strokeLinecap="square"
                        />
                        <path
                            d="M24.0002 27.998C26.2094 27.998 28.0002 26.2072 28.0002 23.998C28.0002 21.7889 26.2094 19.998 24.0002 19.998C21.7911 19.998 20.0002 21.7889 20.0002 23.998C20.0002 26.2072 21.7911 27.998 24.0002 27.998Z"
                            stroke="white"
                            strokeWidth="2.13004"
                            strokeLinecap="square"
                        />
                        <path
                            d="M26.8298 26.8301L30.7326 30.6225"
                            stroke="white"
                            strokeWidth="2.13004"
                            strokeLinecap="square"
                        />
                        <path
                            d="M17.3633 30.8818L21.17 27.0527"
                            stroke="white"
                            strokeWidth="2.13004"
                            strokeLinecap="square"
                        />
                        <path
                            d="M26.8303 21.1706L30.3603 17.6406"
                            stroke="white"
                            strokeWidth="2.13004"
                            strokeLinecap="square"
                        />
                        <path
                            d="M17.3633 17.1367L21.1701 21.1706"
                            stroke="white"
                            strokeWidth="2.13004"
                            strokeLinecap="square"
                        />
                    </svg>
                ),
                counter: "$2.7k",
            },
            {
                id: 2,
                title: "Connected Students",
                image: (
                    <svg
                        width="48"
                        height="48"
                        viewBox="0 0 48 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle cx="24" cy="24" r="24" fill="#16DAF1" />
                        <path
                            d="M24 34C29.5228 34 34 29.5228 34 24C34 18.4772 29.5228 14 24 14C18.4772 14 14 18.4772 14 24C14 29.5228 18.4772 34 24 34Z"
                            stroke="white"
                            strokeWidth="2.13004"
                            strokeLinecap="square"
                        />
                        <path
                            d="M24.0002 27.998C26.2094 27.998 28.0002 26.2072 28.0002 23.998C28.0002 21.7889 26.2094 19.998 24.0002 19.998C21.7911 19.998 20.0002 21.7889 20.0002 23.998C20.0002 26.2072 21.7911 27.998 24.0002 27.998Z"
                            stroke="white"
                            strokeWidth="2.13004"
                            strokeLinecap="square"
                        />
                        <path
                            d="M26.8298 26.8301L30.7326 30.6225"
                            stroke="white"
                            strokeWidth="2.13004"
                            strokeLinecap="square"
                        />
                        <path
                            d="M17.3633 30.8818L21.17 27.0527"
                            stroke="white"
                            strokeWidth="2.13004"
                            strokeLinecap="square"
                        />
                        <path
                            d="M26.8303 21.1706L30.3603 17.6406"
                            stroke="white"
                            strokeWidth="2.13004"
                            strokeLinecap="square"
                        />
                        <path
                            d="M17.3633 17.1367L21.1701 21.1706"
                            stroke="white"
                            strokeWidth="2.13004"
                            strokeLinecap="square"
                        />
                    </svg>
                ),
                counter: "24",
            },
            {
                id: 3,
                title: "Pending connections",
                image: (
                    <svg
                        width="48"
                        height="48"
                        viewBox="0 0 48 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle cx="24" cy="24" r="24" fill="#34C38F" />
                        <path
                            d="M24 34C29.5228 34 34 29.5228 34 24C34 18.4772 29.5228 14 24 14C18.4772 14 14 18.4772 14 24C14 29.5228 18.4772 34 24 34Z"
                            stroke="white"
                            strokeWidth="2.13004"
                            strokeLinecap="square"
                        />
                        <path
                            d="M24.0002 27.998C26.2094 27.998 28.0002 26.2072 28.0002 23.998C28.0002 21.7889 26.2094 19.998 24.0002 19.998C21.7911 19.998 20.0002 21.7889 20.0002 23.998C20.0002 26.2072 21.7911 27.998 24.0002 27.998Z"
                            stroke="white"
                            strokeWidth="2.13004"
                            strokeLinecap="square"
                        />
                        <path
                            d="M26.8298 26.8301L30.7326 30.6225"
                            stroke="white"
                            strokeWidth="2.13004"
                            strokeLinecap="square"
                        />
                        <path
                            d="M17.3633 30.8818L21.17 27.0527"
                            stroke="white"
                            strokeWidth="2.13004"
                            strokeLinecap="square"
                        />
                        <path
                            d="M26.8303 21.1706L30.3603 17.6406"
                            stroke="white"
                            strokeWidth="2.13004"
                            strokeLinecap="square"
                        />
                        <path
                            d="M17.3633 17.1367L21.1701 21.1706"
                            stroke="white"
                            strokeWidth="2.13004"
                            strokeLinecap="square"
                        />
                    </svg>
                ),
                counter: "347",
            },
            {
                id: 4,
                title: "Pending connections",
                image: (
                    <svg
                        width="48"
                        height="48"
                        viewBox="0 0 48 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle cx="24" cy="24" r="24" fill="#FFBA21" />
                        <path
                            d="M24 34C29.5228 34 34 29.5228 34 24C34 18.4772 29.5228 14 24 14C18.4772 14 14 18.4772 14 24C14 29.5228 18.4772 34 24 34Z"
                            stroke="white"
                            strokeWidth="2.13004"
                            strokeLinecap="square"
                        />
                        <path
                            d="M24.0002 28C26.2094 28 28.0002 26.2091 28.0002 24C28.0002 21.7909 26.2094 20 24.0002 20C21.7911 20 20.0002 21.7909 20.0002 24C20.0002 26.2091 21.7911 28 24.0002 28Z"
                            stroke="white"
                            strokeWidth="2.13004"
                            strokeLinecap="square"
                        />
                        <path
                            d="M26.8298 26.8301L30.7326 30.6225"
                            stroke="white"
                            strokeWidth="2.13004"
                            strokeLinecap="square"
                        />
                        <path
                            d="M17.3633 30.8798L21.17 27.0508"
                            stroke="white"
                            strokeWidth="2.13004"
                            strokeLinecap="square"
                        />
                        <path
                            d="M26.8303 21.1696L30.3603 17.6396"
                            stroke="white"
                            strokeWidth="2.13004"
                            strokeLinecap="square"
                        />
                        <path
                            d="M17.3633 17.1367L21.1701 21.1706"
                            stroke="white"
                            strokeWidth="2.13004"
                            strokeLinecap="square"
                        />
                    </svg>
                ),
                counter: "347",
            },
            {
                id: 5,
                title: "Connected Students",
                image: (
                    <svg
                        width="48"
                        height="48"
                        viewBox="0 0 48 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle cx="24" cy="24" r="24" fill="#16DAF1" />
                        <path
                            d="M24 34C29.5228 34 34 29.5228 34 24C34 18.4772 29.5228 14 24 14C18.4772 14 14 18.4772 14 24C14 29.5228 18.4772 34 24 34Z"
                            stroke="white"
                            strokeWidth="2.13004"
                            strokeLinecap="square"
                        />
                        <path
                            d="M24.0002 27.998C26.2094 27.998 28.0002 26.2072 28.0002 23.998C28.0002 21.7889 26.2094 19.998 24.0002 19.998C21.7911 19.998 20.0002 21.7889 20.0002 23.998C20.0002 26.2072 21.7911 27.998 24.0002 27.998Z"
                            stroke="white"
                            strokeWidth="2.13004"
                            strokeLinecap="square"
                        />
                        <path
                            d="M26.8298 26.8301L30.7326 30.6225"
                            stroke="white"
                            strokeWidth="2.13004"
                            strokeLinecap="square"
                        />
                        <path
                            d="M17.3633 30.8818L21.17 27.0527"
                            stroke="white"
                            strokeWidth="2.13004"
                            strokeLinecap="square"
                        />
                        <path
                            d="M26.8303 21.1706L30.3603 17.6406"
                            stroke="white"
                            strokeWidth="2.13004"
                            strokeLinecap="square"
                        />
                        <path
                            d="M17.3633 17.1367L21.1701 21.1706"
                            stroke="white"
                            strokeWidth="2.13004"
                            strokeLinecap="square"
                        />
                    </svg>
                ),
                counter: "24",
            },
        ];
        const milesdropdown = [
            {
                data: "Set as completed",
            },
            {
                data: "Delete",
            },
        ];
        const workSpace = [
            {
                id: 1,
                image: Avatar1,
                status: "Active",
                goal: "Going to Cambridge",
                coursename: "Economy",
                planname: "Milestone 1",
                date: "2021, (2 - semestre)",
                percentage: 20,
            },
            {
                id: 2,
                image: Avatar2,
                status: "Ongoing",
                goal: "Become an Engineer",
                coursename: "Science",
                planname: "Milestone 5",
                date: "2021, (1- semestre)",
                percentage: 40,
            },
        ];
        const RecommendedFriendsData = [
            {
                AvatarIcon: Avatar1,
                AvatarIconAlt: "Avatar1-icon",
                RecommendedFriendsTitle: "Joshua Ashiru",
                RecommendedFriendsSubTitle: "Went to same school as you",
            },
            {
                AvatarIcon: Avatar2,
                AvatarIconAlt: "Avatar2-icon",
                RecommendedFriendsTitle: "Joshua Ashiru",
                RecommendedFriendsSubTitle: "Went to same school as you",
            },
            {
                AvatarIcon: Avatar1,
                AvatarIconAlt: "Avatar1-icon",
                RecommendedFriendsTitle: "Joshua Ashiru",
                RecommendedFriendsSubTitle: "Went to same school as you",
            },
            {
                AvatarIcon: Avatar1,
                AvatarIconAlt: "Avatar1-icon",
                RecommendedFriendsTitle: "Joshua Ashiru",
                RecommendedFriendsSubTitle: "Went to same school as you",
            },
        ];
        const optionsValue = {
            nav: false,
            navText: [
                "<svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'> <path fillRule='evenodd' clipRule='evenodd' d='M13.3333 8.00065C13.3333 7.63246 13.0348 7.33398 12.6666 7.33398H3.33325C2.96506 7.33398 2.66659 7.63246 2.66659 8.00065C2.66659 8.36884 2.96506 8.66732 3.33325 8.66732H12.6666C13.0348 8.66732 13.3333 8.36884 13.3333 8.00065Z' fill='#1B1C1E'/> <path fillRule='evenodd' clipRule='evenodd' d='M8.47149 2.86128C8.21114 2.60093 7.78903 2.60093 7.52868 2.86128L2.86201 7.52794C2.60166 7.78829 2.60166 8.2104 2.86201 8.47075L7.52868 13.1374C7.78903 13.3978 8.21114 13.3978 8.47149 13.1374C8.73184 12.8771 8.73184 12.455 8.47149 12.1946L4.27622 7.99935L8.47149 3.80409C8.73184 3.54374 8.73184 3.12163 8.47149 2.86128Z' fill='#1B1C1E'/> </svg>",
                "<svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'> <path fillRule='evenodd' clipRule='evenodd' d='M2.66675 8.00065C2.66675 7.63246 2.96522 7.33398 3.33341 7.33398H12.6667C13.0349 7.33398 13.3334 7.63246 13.3334 8.00065C13.3334 8.36884 13.0349 8.66732 12.6667 8.66732H3.33341C2.96522 8.66732 2.66675 8.36884 2.66675 8.00065Z' fill='#1B1C1E'/> <path fillRule='evenodd' clipRule='evenodd' d='M7.52851 2.86128C7.78886 2.60093 8.21097 2.60093 8.47132 2.86128L13.138 7.52794C13.3983 7.78829 13.3983 8.2104 13.138 8.47075L8.47132 13.1374C8.21097 13.3978 7.78886 13.3978 7.52851 13.1374C7.26816 12.8771 7.26816 12.455 7.52851 12.1946L11.7238 7.99935L7.52851 3.80409C7.26816 3.54374 7.26816 3.12163 7.52851 2.86128Z' fill='#1B1C1E'/> </svg> ",
            ],
            navClass: ["owl-prev", "owl-next"],
            autoplay: false,
            slideBy: 1,
            dots: false,
            dotsEach: false,
            dotData: true,
            margin: 35,
            loop:true,
            responsive: {
                0: { items: 2 },
                500: { items: 3 },
                // 6 : { items: 4,},
                769: { items: 3 },
                950: { items: 4 },
                1001: { items: 2 },
                1100: { items: 3 },
                1290: { items: 4 },
                1550: { items: 5 },
            },
        };

        return (
            <>
                {this.state.showList === false ?
                    <div className="dashboardtop pt-0">
                        <div className="row">
                            <div className="col-md-6 col-sm-11 col-10">
                                <div className="Advisor__dashboard-top-text">
                                    <h3>Hi, {this.props.userProfile?.firstName} </h3>
                                    <p className="Advisor__dashboard-top-text__sub-text p-0 m-0">
                                        <span> It’s good to see you again.</span>
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-1 col-1 Search-content">
                                <Search placeholder="Search the platform" />
                                <div className="mobile-search">
                                    <div class="mobile-search-content">
                                        <a href="" class="mobile-search-button" onClick={(e) => this.handleClickMobilesearch(e)} >
                                            <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path  d="M14.5046 3.22982C11.3749 0.183809 6.28112 0.183809 3.15144 3.22982C0.0224364 6.2765 0.0224364 11.2335 3.15144 14.2801C5.93851 16.992 10.2792 17.2826 13.4069 15.1649C13.4727 15.468 13.6233 15.7572 13.8655 15.993L18.4233 20.4289C19.0875 21.0741 20.1608 21.0741 20.8216 20.4289C21.4852 19.7832 21.4852 18.7385 20.8216 18.0947L16.2638 13.6575C16.023 13.4237 15.7251 13.2765 15.4137 13.2124C17.5909 10.1677 17.2923 5.9437 14.5046 3.22982ZM13.0656 12.8796C10.729 15.1537 6.92633 15.1537 4.59043 12.8796C2.25521 10.6055 2.25521 6.90511 4.59043 4.631C6.92633 2.35755 10.729 2.35755 13.0656 4.631C15.4022 6.90511 15.4022 10.6055 13.0656 12.8796Z"fill="#1C84EE"/>
                                            </svg>
                                        </a>
                                        {isActive && (
                                            <input type="text" class="mobile-search-input" placeholder="Search the platform" />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : ""}
                {this.state.showList === false ?
                    <div className="adv-dash-line ">
                        <div className="vertic-line"></div>
                    </div>
                    : ""}
                {this.state.showList === false ?
                    <div className="main-wrap">
                        <div className="Advisor__sub-sub-text"> Overview </div>
                        <div className="row m-0 mt-2">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12 ps-1 pe-1">
                                <div className="row m-0 mb-4">
                                    <div className="col-sm-12 p-0">
                                        <div className="dashboard__overview-data cursorshow">
                                            <OverViewCarousel  CarouselData={OverviewData} CarouselOption={optionsValue}
                                            onClick={(data) => this.handleShowList(data) }
                                              />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row m-0 mb-4">
                            <div className="col-lg-7 col-12 col-md-8  col-xl-7 col-xxl-7 col-sm-6 ps-1 pe-1 width-100 p-right-0 mb-4">
                                <div className="GetAdvisors  advisor-dash-cards">
                                    <h2 className="GetAdvisors__title">Workspace{" "}
                                    <a  className="main-content__cta cta--underline cursorshow">See all </a>
                                    </h2>
                                    <p className="GetAdvisors__sub-title mb-0 mt-3">  {" "} You have 24 Students.{" "} </p>
                                    <Milestones milestoneData={workSpace} DropDownData={milesdropdown} dashboard={true} />
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-4 col-xl-5 col-xxl-5 col-sm-6 pe-1 width-100 p-left-0 ps-1">
                                <div className="RecommendedFriends  advisor-dash-cards mb-4">
                                    <RecommendedFriends RecommendedFriendsTitle={ "Recommended Students" }
                                        RecommendedFriendsSubTitle={""}
                                        RecommendedFriendsData={RecommendedFriendsData } />
                                </div>
                            </div>
                        </div>
                    </div>
                    : ""}
                <div className="Trans-list">
                    {this.state.showList === true ? <List close={(closedata) => this.handleBack(closedata)} /> : ""}
                </div>
                <div className="right-wrap">
                    <div className="minicnt">
                        <Calendar onChange={this.setDate} value={this.date}  prev2Label="Schedule" />
                    </div>
                    <div className="upcomecnt">
                        <h5>Upcoming Sessions</h5>
                        <UpcommingSession />
                    </div>
                </div>
            </>
        );
    }
}
const mapStateToProps = ({ userProfileData, commonData }) => {
    const { message, errorList } = commonData
    const { userProfile } = userProfileData;
    return { message, errorList, userProfile }
};

export default connect(mapStateToProps, { userSignIn, getUserProfile })(withRouter(Index));
