import React, { useEffect, useState } from 'react'
import "./courseMilestone.less";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { Box, Grid, Tab, Tabs, } from "@mui/material";
import { useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { DeleteCourse, GetMilestoneByCourse, createMilestonforCourseStatus, CreateNotesStatus, 
    DeleteMileStoneResourceStatus, DeleteMileStoneNotesStatus, ReplyNotesStatus, updateReadUpdateStatus, GetMileStoneByCourseStatus } from "../../../../../redux/actions";
import CustomAlert from '../../../../../components/CustomAlert';
import MileStoneTab from "./MilestoneTab";
import AddMilestone from '../../../../../componentsNew/Common/addMilestone';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            className="Advisor-MyNetwork__tabs-sec"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box className="card-sec">
                    {children}
                </Box>
            )}
        </div>
    );
}

const Index = (props) => {
    const [milestoneShow, setMilestoneShow] = useState(false)
    const [tab, setTab] = useState(0);

    const navigate = useNavigate();
    const {state} = useLocation();

    useEffect(() => {
        let payload = {
            "planId": state.courseData.planId,
            "courseId": state.courseData.id
        }
        props?.GetMilestoneByCourse(payload)
    }, [])

    useEffect(() => {
        let payload = {
            "planId": state.courseData.planId,
            "courseId": state.courseData.id
        }
        if(props?.isUpdateNotes){
            props?.CreateNotesStatus(false)
            props?.GetMilestoneByCourse(payload)
        }
        if(props?.isDeleteResource){
            props?.DeleteMileStoneResourceStatus(false);
            props?.GetMilestoneByCourse(payload)
        }
        if(props?.createCourseMilestoneStatus){
			props?.GetMilestoneByCourse(payload)
            closemilestone()
		}
        if (props?.deleteNotes) {
            props?.DeleteMileStoneNotesStatus(false)
            props?.GetMilestoneByCourse(payload)
        }
        if (props?.isReplyNotes) {
            props?.GetMilestoneByCourse(payload)
        }
        if (props.viewedUpdate) {
            props.updateReadUpdateStatus(false);
            props?.GetMilestoneByCourse(payload)
        }
        if (props?.isMileStoneCourseStatus) {
            props?.GetMileStoneByCourseStatus(false);
        }
    }, [props?.isUpdateNotes, props?.isDeleteResource, props?.createCourseMilestoneStatus, 
        props?.deleteNotes, props?.isReplyNotes, props.viewedUpdate])
    
    
    const changeTab = (event, newValue) => {
        setTab(newValue);
    };
    const Openmilestone = () => setMilestoneShow(true);
    const closemilestone = () => setMilestoneShow(false);
 
    return (
        <>
            <div style={{ "width": "100%", "display": "flex", "justifyContent": "center", }}> {<CustomAlert />}</div>
            {milestoneShow &&
                <AddMilestone show={milestoneShow} onHide={() => closemilestone()} handleClose={closemilestone} />
            }
            <div className="milestone_Page">
                <div className="milestone_Page__topSec ">
                    {/* Plan mobile view */}
                    <div className="milestone_Page__topSec__mobileTitle" >
                        <p>Your Students</p>
                    </div>
                    <div className="milestone_Page__topSec__mobileSubTitle">
						<ArrowBackOutlinedIcon className='milestone_Page__topSec__mobileSubTitle__goBackIcon' onClick={() => navigate(-1)}/>
                        <p>Course:{" "}{state.courseData?.courseName}</p>
                    </div>
                    <div className="milestone_Page__topSec__desktop">
                            <div className="milestone_Page__topSec__desktop__desktopTitle">
                                <p>
                                    <span className="milestone_Page__topSec__desktopTitle--backBtn">
                                        <svg width="25" height="25" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={(e) => navigate(-1)}>
                                            <path fillRule="evenodd" clipRule="evenodd" d="M13.3333 8.00016C13.3333 7.63197 13.0349 7.3335 12.6667 7.3335H3.33334C2.96515 7.3335 2.66668 7.63197 2.66668 8.00016C2.66668 8.36835 2.96515 8.66683 3.33334 8.66683H12.6667C13.0349 8.66683 13.3333 8.36835 13.3333 8.00016Z" fill="#1B1C1E" />
                                            <path fillRule="evenodd" clipRule="evenodd" d="M8.47139 2.86177C8.21104 2.60142 7.78893 2.60142 7.52859 2.86177L2.86192 7.52843C2.60157 7.78878 2.60157 8.21089 2.86192 8.47124L7.52859 13.1379C7.78893 13.3983 8.21104 13.3983 8.47139 13.1379C8.73174 12.8776 8.73174 12.4554 8.47139 12.1951L4.27613 7.99984L8.47139 3.80458C8.73174 3.54423 8.73174 3.12212 8.47139 2.86177Z" fill="#1B1C1E" />
                                        </svg>
                                    </span> {" "}
                                    Course:{" "}{state.courseData?.courseName}
                                </p>
                        </div>
                    </div>
                </div>
                <div className="milestone_Page__midSec">
                    {props?.isCourseMileStone?.length > 0 ? (
                        <>
                            <Grid item xs={12} sm={12} md={12} lg={11} xl={10}>
                                <Box className="tab_card"
                                    sx={{
                                        bgcolor: "#fff",
                                        borderRadius: "10px",
                                        border: "1px solid #CDCDCD",
                                        marginInline: "auto",
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <Tabs
                                        value={tab}
                                        onChange={changeTab}
                                        variant="scrollable"
                                        scrollButtons
                                        allowScrollButtonsMobile
                                        aria-label="scrollable force tabs example"
                                    >
                                        { props?.isCourseMileStone?.map((milestone, index) => (
                                            <Tab className='milesTab'
                                                key={milestone?.id}
                                                label={milestone?.milestoneTitle}
                                                disableRipple id={`mileTab-${index}`}
                                            />
                                        ))}
                                    </Tabs>
                                    <span onClick={Openmilestone} style={{ cursor: "pointer" }} id='addMilestone'>
                                        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect width="25" height="25" rx="5" fill="#1C84EE" />
                                            <path d="M12.5 6.25C12.7072 6.25 12.9059 6.33231 13.0524 6.47882C13.1989 6.62534 13.2812 6.82405 13.2812 7.03125V11.7188H17.9688C18.176 11.7188 18.3747 11.8011 18.5212 11.9476C18.6677 12.0941 18.75 12.2928 18.75 12.5C18.75 12.7072 18.6677 12.9059 18.5212 13.0524C18.3747 13.1989 18.176 13.2812 17.9688 13.2812H13.2812V17.9688C13.2812 18.176 13.1989 18.3747 13.0524 18.5212C12.9059 18.6677 12.7072 18.75 12.5 18.75C12.2928 18.75 12.0941 18.6677 11.9476 18.5212C11.8011 18.3747 11.7188 18.176 11.7188 17.9688V13.2812H7.03125C6.82405 13.2812 6.62534 13.1989 6.47882 13.0524C6.33231 12.9059 6.25 12.7072 6.25 12.5C6.25 12.2928 6.33231 12.0941 6.47882 11.9476C6.62534 11.8011 6.82405 11.7188 7.03125 11.7188H11.7188V7.03125C11.7188 6.82405 11.8011 6.62534 11.9476 6.47882C12.0941 6.33231 12.2928 6.25 12.5 6.25Z" fill="white" />
                                        </svg>
                                    </span>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={11} xl={10}>
                                { props?.isCourseMileStone?.map((milestoneData, index) => {
                                    return (
                                        <TabPanel value={tab} index={index}>
                                            <MileStoneTab data={milestoneData} />
                                        </TabPanel>
                                    )
                                })}
                            </Grid>
                        </>
                    ) : (
                        <Grid item xs={12} sm={12} md={12} lg={11} xl={10}>
                            <div className="planviewcnttopsec__emptyState">
                                <div className="planviewcnttopsec__emptyState__content">
                                    <div>
                                        <p className="planviewcnttopsec__emptyState__content__empty-text">
                                            This student does not have any Milestones for this course
                                        </p>
                                        <p className="planviewcnttopsec__emptyState__content__empty-cta" id='createMilestone' onClick={Openmilestone} >
                                            Create Milestone
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    )}
                </div>
            </div>
        </>
    )
}

const mapStateToProps = ({ studentData }) => {
    const { isCourseMileStone, isDeleteResource, createCourseMilestoneStatus, isMileStoneCourseStatus, isUpdateNotes, deleteNotes, isReplyNotes, viewedUpdate } = studentData;
    return { isCourseMileStone, isDeleteResource, createCourseMilestoneStatus, isMileStoneCourseStatus, isUpdateNotes, deleteNotes, isReplyNotes, viewedUpdate };
};

export default connect(mapStateToProps, { DeleteCourse, GetMilestoneByCourse, createMilestonforCourseStatus, CreateNotesStatus, 
    DeleteMileStoneResourceStatus, DeleteMileStoneNotesStatus, ReplyNotesStatus, updateReadUpdateStatus, GetMileStoneByCourseStatus })(Index);                                                                                                                                                                                                                                                                                                                                                                                               
