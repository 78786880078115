import React, { useEffect, useState } from "react";
import { Button, Grid, TextField, Typography } from '@mui/material';
import { connect } from "react-redux";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import CustomAlert from '../../../../components/CustomAlert';
import { yupResolver } from "@hookform/resolvers/yup";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Controller, useForm } from "react-hook-form";
import Select, { components } from "react-select";
import * as Yup from "yup";
import Loader from "../../../../components/Loader";
import Tags from '../../../../componentsNew/Common/SelectableTags';
import { advisorAvailablity } from "../../../../redux/actions"
import './booksession.less'
import dayjs from "dayjs";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '34%',
    maxWidth: '34%',
    height: '90%',
    overflowY: 'scroll',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const { ValueContainer, Placeholder } = components;
const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>{props.selectProps.placeholder}</Placeholder>
            {React.Children.map(children, child => child && child.type !== Placeholder ? child : null)}
        </ValueContainer>
    );
};
const validation = Yup.object({
    Selectdate: Yup.date().required('*Required').nullable(),
    Location: Yup.string().required("*Required").nullable(),
    password: Yup.string().max(20, "Password only allows 20 characters").nullable(),
    Description: Yup.string().required('*Required').max(250, 'Description only allows 250 characters').nullable(),
})
const Index = (props) => {
    const [loader, setloader] = useState(false)
    const [selectedTimesheet, setSelectedTimesheet] = useState([]);
    const [selectedTag, setSelectedTag] = useState(null);
    const [isTagError, setIsTagError] = useState(false);
    const [dateSelected, setSelectedDate] = useState(null);
    const [packageInfo, setPackageInfo] = useState(null);
    const [selectedPackage, setSelectedPackage] = useState(null);
    const [advisorData, setAdvisorData] = useState([]);
    const [splitedTime, setSplitedTime] = useState([]);
    const [minDate, setMinDate] = useState(dayjs());

    const handleSelectedChange = (date) => {
        setSelectedDate(date);
    };

    const handleTagClick = (tag) => {
        if (selectedTimesheet && selectedTimesheet?.length > 0) {
            setSelectedTag(tag);
            setIsTagError(false);
        }
    };

    const { register, handleSubmit, reset, watch, control, formState: { errors }, setValue, clearErrors, } = useForm({
        mode: "onSubmit",
        resolver: yupResolver(
            validation
        ),
    });
    const Description = watch("Description")

    useEffect(() => {
        setSelectedTimesheet([])
        setloader(false)
        reset()
    }, [])

    useEffect(() => {
        if (props?.Time) {
            setValue("Location", props?.Time?.defaultMeetingURL)
            setValue("password", props?.Time?.defaultMeetingPassword)
            setValue("advisor", props?.selectedAdvisor)
        }
        if (props?.advisorsData?.advisorWorkingHoursWithDay || props?.Time?.advisorWorkingHoursWithDay) {
            TimeSplitter();
        }
        if (props?.userProfile?.timezone) {
            updateMinDate();
        }
    }, [props?.Time, props?.advisorsData, props?.userProfile])

    const updateMinDate = () => {
        const now = dayjs();
        const tzDate = now.tz(props?.userProfile?.timezone).startOf('day');
        setMinDate(tzDate);
    };
    const filterObject = (title, options, value) => {
        if (value === "" || value === undefined) return;
        let filtered =
            options &&
            options?.filter(
                (options) => options?.label?.toString() === value?.label?.toString()
            );
        if (filtered && filtered[0]) {
            setValue(title, filtered[0]);
        } else {
            setValue(title, { label: value?.label, value: value?.value })
        }
    };
    console.log("advisorList",props?.advisorList);
    
    useEffect(() => {
        if (props?.selectedAdvisor) {
            filterObject("advisor", props?.advisorList, props?.selectedAdvisor);
            if (props?.advisorList && props?.selectedAdvisor) {
            }
        }
        if (props?.selectedAdvisor) {
            const selectedAdvisorId = props?.selectedAdvisor?.value;

            if (selectedAdvisorId) {
                const selectedAdvisor = props?.advisorList.find(
                    (advisor) => advisor.userInfo.id === selectedAdvisorId
                );
                if (selectedAdvisor) {
                    const AdvisorProfile = selectedAdvisor?.userInfo?.advisorProfile
                    setValue("Location", AdvisorProfile?.defaultMeetingURL)
                    setValue("password", AdvisorProfile?.defaultMeetingPassword)
                }
            }
        }
    }, [props?.selectedAdvisor])

    const handleClose = () => {
        props?.onHide()
        reset("student")
        setSelectedTimesheet([])
    };
    const onSubmit = (data) => {
        if (!selectedTag) {
            setIsTagError(true);
            return;
        }
        setloader(true)
        props?.HandleSumit(data, selectedTag)
        setSelectedTimesheet([])
        // reset()
        // setloader(false)
    }

    const selectedDate = watch("Selectdate");
    const PackageSelect = watch("package")

    useEffect(() => {
        if (selectedDate) {
            handleDateChange(selectedDate);
            const timestamp = new Date(selectedDate);
            const SelectDate = timestamp.toISOString().split('T')[0];
            let AdvisorId = advisorData?.userInfo?.id
            props?.advisorAvailablity(AdvisorId, SelectDate)
        }
        // if (PackageSelect) {
        //     const selectedPackageInfo = packageInfo?.find(item => item?.packageInfo?.id === PackageSelect?.value);
        //     handleDateChange(selectedDate)
        //     if (selectedPackageInfo) {
        //         setSelectedPackage(selectedPackageInfo);
        //     }
        // }
    }, [selectedDate]);

    const handlePackageChange = () => {
        const selectedPackageInfo = packageInfo?.find(item => item?.packageInfo?.id === PackageSelect?.value);
        setValue("Selectdate", undefined)
        setSelectedTimesheet([])
        setSelectedPackage(selectedPackageInfo);
        setSelectedTag('');
    }

    useEffect(() => {
        if (watch("advisor")) {
            const subscriptionDetails = watch("advisor") && props?.advisorList?.find((item) => item?.userInfo?.id === watch("advisor")?.value);
            setAdvisorData(subscriptionDetails)
            const matchedAdvisorFeePackages = subscriptionDetails?.advisorFeePackages?.filter(advisorPackage => {
                return subscriptionDetails?.subscriptiondetails?.some(subscription => advisorPackage?.id === subscription?.packageId);
            });
            const subscriptionDetailsData = subscriptionDetails?.subscriptiondetails
            setPackageInfo(subscriptionDetailsData)
    console.log(subscriptionDetails,"subscriptionDetails")

        }
    }, [watch("advisor")]);

console.log(packageInfo, "packageIndo")
    const TimeSplitter = () => {
        if (props?.advisorsData?.advisorWorkingHoursWithDay || props?.Time?.advisorWorkingHoursWithDay ) {
            const advisorWorkingHours = props?.advisorsData?.advisorWorkingHoursWithDay ?? props?.Time?.advisorWorkingHoursWithDay;

            const splitTimesheets = {
                "mondayTimesheet": [],
                "tuesdayTimesheet": [],
                "wednesdayTimesheet": [],
                "thursdayTimesheet": [],
                "fridayTimesheet": [],
                "saturdayTimesheet": [],
                "sundayTimesheet": []
            };

            const dayMapping = {
                "Monday": "mondayTimesheet",
                "Tuesday": "tuesdayTimesheet",
                "Wednesday": "wednesdayTimesheet",
                "Thursday": "thursdayTimesheet",
                "Friday": "fridayTimesheet",
                "Saturday": "saturdayTimesheet",
                "Sunday": "sundayTimesheet",
                "Mon": "mondayTimesheet",
                "Tue": "tuesdayTimesheet",
                "Wed": "wednesdayTimesheet",
                "Thu": "thursdayTimesheet",
                "Fri": "fridayTimesheet",
                "Sat": "saturdayTimesheet",
                "Sun": "sundayTimesheet"
            };

            function parseTimeAndDay(timeAndDay) {
                const lastSpaceIndex = timeAndDay?.lastIndexOf(' ');
                const time = timeAndDay?.substring(0, lastSpaceIndex).trim();
                const day = timeAndDay?.substring(lastSpaceIndex + 1).trim();
                return [time, day];
            }

            const convertTimeFormat = (convertedTime) => {
                const [time, day] = convertedTime?.split(' ');
                const converted12HourTime = dayjs(time, 'HH:mm')?.format('hh:mm A');
                return `${converted12HourTime} ${day}`;
            };

            Object.keys(advisorWorkingHours)?.forEach(day => {
                const dayTimeSheet = advisorWorkingHours[day];

                if (Array.isArray(dayTimeSheet) && dayTimeSheet?.length > 0) {
                    dayTimeSheet.forEach(entry => {

                        const convertedStartTime = convertTimeFormat(entry?.startingTimeday);
                        const convertedCloseTime = convertTimeFormat(entry?.closingTimeday);

                        if (convertedStartTime && convertedCloseTime) {
                            const [startTime, startDay] = parseTimeAndDay(convertedStartTime);
                            const [endTime, endDay] = parseTimeAndDay(convertedCloseTime);

                            const startDayKey = dayMapping[startDay];
                            const endDayKey = dayMapping[endDay];

                            if (!startDayKey || !endDayKey) {
                                console.error(`Invalid day key mapping: startDay=${startDay}, endDay=${endDay}`);
                                return;
                            }

                            if (startDayKey === endDayKey) {
                                // Case 1: Same day
                                splitTimesheets[startDayKey].push({
                                    startingTime: `${startTime}, ${startDay}`,
                                    closingTime: `${endTime}, ${endDay}`
                                });
                            } else {
                                // Case 2: Different days
                                console.log("Case 2: Different days");
                                splitTimesheets[startDayKey].push({
                                    startingTime: `${startTime}, ${startDay}`,
                                    closingTime: `12:00 AM, ${startDay}`
                                });

                                splitTimesheets[endDayKey].push({
                                    startingTime: `12:00 AM, ${endDay}`,
                                    closingTime: `${endTime}, ${endDay}`
                                });
                            }
                        }

                    });
                }
            });
            if (splitTimesheets) {
                const updatedTimesheets = { ...splitTimesheets };

                Object?.keys(updatedTimesheets)?.forEach(day => {
                    if (updatedTimesheets[day][0]?.startingTime?.includes('PM')) {
                        const pmEntry = updatedTimesheets[day]?.shift();
                        updatedTimesheets[day]?.push(pmEntry);
                    }
                });
                setSplitedTime(updatedTimesheets);
            }
        }
    }

    const handleDateChange = (date) => {
        let TimeZone = props?.userProfile?.timezone;
        console.log(watch("package")?.eventDuration, "sessionLengthssssss")
        let sessionLength = watch("package")?.eventDuration ?? 30;

        console.log(sessionLength, "sessionLength")

        if (date && TimeZone && splitedTime) {
            const selectedDay = dayjs(date)?.format("dddd").toLowerCase();
            const timesheetArray = splitedTime[`${selectedDay}Timesheet`];
            const timeIntervals = new Set();

            timesheetArray?.forEach(entry => {
                let startTime = dayjs(entry?.startingTime, 'hh:mm A, dddd');
                let endTime = dayjs(entry?.closingTime, 'hh:mm A, dddd');

                if (endTime?.isBefore(startTime)) {
                    endTime = endTime?.add(1, 'day');
                }

                while (startTime?.isBefore(endTime)) {
                    const intervalEnd = dayjs(Math?.min(startTime.clone()?.add(sessionLength, 'minutes')?.valueOf(), endTime?.valueOf()));
                    const intervalText = startTime?.format('h:mm A') + ' - ' + intervalEnd?.format('h:mm A');
                    timeIntervals?.add(intervalText);

                    startTime = startTime?.add(sessionLength, 'minutes');
                }
            });
            const uniqueTimeIntervals = Array?.from(timeIntervals)?.map(interval => ({
                text: interval,
                isClickable: true
            }));
            console.log(uniqueTimeIntervals, "uniqueTimeIntervals")

            setSelectedTimesheet(uniqueTimeIntervals);
        }
    };
    const formatDate = (dateString) => {
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    return (
        <>
            <Modal open={props?.show} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" className="BooksessionModal">
                <Box sx={style} className="Modal-container book-view-modal">
                    {loader && <Loader />}
                    <div className="Modal-header">
                        <div className="">
                            <p className="Modal-header-title">
                                Book Session
                            </p>
                        </div>
                        <div className="">
                            <CloseIcon onClick={(e) => handleClose(e)} sx={{ cursor: "pointer" }} />
                        </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        {<CustomAlert />}
                    </div>
                    <form id="" onSubmit={handleSubmit(onSubmit)} >
                        <div className="col-md-12 col-sm-12 col-12 p-0">
                            <Controller
                                control={control}
                                name="advisor"
                                render={({ field }) => (
                                    <div className="input-floating-label">
                                        <Select placeholder={"Select Advisor"} {...field} closeMenuOnSelect={true} isClearable={false}
                                            isSearchable={false} classNamePrefix="mySelect"
                                            isDisabled={props?.selectedAdvisor ? true : false}
                                            options={props?.advisorList?.map(item => ({
                                                value: item.userInfo?.id,
                                                label: item.userInfo?.fullName,
                                            }))}
                                            components={{ ValueContainer: CustomValueContainer, IndicatorSeparator: () => null }}
                                            styles={{ option: (provided, state) => ({ ...provided, cursor: 'pointer', }), container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible" }), valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px' }), placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10, display: state.isFocused || state.isSelected || state.selectProps.inputValue ? 'none' : 'block', }) }} />
                                        <div className="error-text" style={{ "position": "absolute" }} >{errors.year?.message}</div>
                                    </div>
                                )} />
                        </div>
                        {console.log("packageInfo",packageInfo)}
                        
                        {packageInfo?.length > 0 && (
                            <>
                                <div className="col-md-12 col-sm-12 col-12 p-0">
                                    <Controller
                                        control={control}
                                        name="package"
                                        render={({ field }) => (
                                            <div className="input-floating-label">
                                                <Select
                                                    placeholder={"Select Package"}
                                                    {...field}
                                                    closeMenuOnSelect={true}
                                                    isClearable={false}
                                                    isSearchable={false}
                                                    classNamePrefix="mySelect"
                                                    options={(() => {
                                                        const options = packageInfo
                                                          ?.filter(item => !item?.packageExpired && item?.noOfPaidSessions > 0)
                                                          ?.map(item => ({
                                                            value: item?.packageInfo?.id,
                                                            label: item?.packageInfo?.packageName,
                                                            eventDuration: item?.packageInfo?.sessionLength,
                                                          }));
                                                    
                                                        // Log the options to the console
                                                        console.log("Select options:", options);
                                                    
                                                        return options;
                                                      })()}
                                                    components={{
                                                        ValueContainer: CustomValueContainer,
                                                        IndicatorSeparator: () => null
                                                    }}
                                                    styles={{
                                                        option: (provided, state) => ({ ...provided, cursor: 'pointer' }),
                                                        container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible", zIndex: "9" }),
                                                        valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px' }),
                                                        placeholder: (provided, state) => ({
                                                            ...provided,
                                                            position: "absolute",
                                                            top: state.hasValue || state.selectProps.inputValue ? -13 : "30%",
                                                            fontSize: (state.hasValue || state.selectProps.inputValue) && 13,
                                                            background: '#fff',
                                                            paddingLeft: 10,
                                                            paddingRight: 10,
                                                            display: state.isFocused || state.isSelected || state.selectProps.inputValue ? 'none' : 'block',
                                                        })
                                                    }}
                                                    onChange={(selectedOption) => {
                                                        field.onChange(selectedOption); // Update the form state
                                                        // Add your custom logic here
                                                        handlePackageChange(selectedOption); // Example function
                                                    }}
                                                />
                                                <div className="error-text" style={{ position: "absolute" }}>
                                                    {errors.package?.message}
                                                </div>
                                                {selectedPackage && (
                                                    <>
                                                        {(selectedPackage?.paymentType === "monthly" || selectedPackage?.packageInfo?.paymentType === "monthly") && (
                                                            <p>{selectedPackage?.noOfPaidSessions ?? selectedPackage?.packageInfo?.noOfPaidSessions} sessions remaining - expiring in {formatDate(selectedPackage?.subscriptionEndsAt)}</p>
                                                        )}
                                                        {(selectedPackage?.paymentType === "onetime" || selectedPackage?.packageInfo?.paymentType === "onetime") && (
                                                            <p>{selectedPackage?.noOfPaidSessions} sessions remaining - expiring in {formatDate(selectedPackage?.subscriptionEndsAt)}</p>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        )}
                                    />

                                </div>
                            </>
                        )}
                        <Grid container>
                            <Grid item sm={12} md={12} lg={12} sx={{ width: '100%' }}>
                                <div className="input-floating-label">
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <Controller name="Selectdate" control={control} defaultValue={null}
                                            render={({ field }) => (
                                                <DateTimePicker
                                                    label="Select Date"
                                                    value={field.value}
                                                    views={['year', 'month', 'day']}
                                                    inputFormat="dd/MM/yyyy"
                                                    onChange={(date) => {
                                                        if (date instanceof Date && !isNaN(date.getTime())) { field.onChange(date); }
                                                    }}
                                                    minDate={minDate}
                                                    maxDate={selectedPackage?.subscriptionEndsAt}
                                                    renderInput={(params) => <TextField {...params}
                                                        sx={{ width: '100%', '& .MuiOutlinedInput-root': { border: 'none', '&:hover fieldset': { border: 'none' }, }, }} />} />
                                            )} />
                                    </LocalizationProvider>
                                    <label>Selectdate</label>
                                    <div className="error-text" style={{ "position": "absolute" }} >{errors?.Selectdate?.message}</div>
                                </div>
                            </Grid>
                        </Grid>
                        {selectedTimesheet && selectedTimesheet.length > 0 && (
                            <>
                                <p>Available Time</p>
                                <div style={{ "display": "flex", "flexWrap": "wrap", "marginBottom": "20px", "height": "150px", "overflow": "scroll" }}>
                                    {selectedTimesheet.map(time => (
                                        <Tags
                                            key={time.text}
                                            bold
                                            text={time.text}
                                            onTagClick={time.isClickable ? handleTagClick : null} // Only add onClick handler if clickable
                                            isSelected={selectedTag === time.text}
                                            isClickable={time.isClickable} // Pass the clickable state to the tag component
                                        />
                                    ))}
                                </div>
                            </>
                        )}
                        {isTagError && <p style={{ color: '#E64A19', fontSize: '12px' }}>Please select a Time</p>}
                        {selectedDate && (!selectedTimesheet || selectedTimesheet.length === 0) && (
                            <>
                                <div className="emptyState, zeroStateReceived mb-5" style={{ "display": "flex", "justifyContent": "center", }} >
                                    <div className="" style={{ "border": "1px solid #cdcdcd", padding: "20px", "borderRadius": "10px" }}>
                                        <div className="Empty_Card emptyShadow" style={{ "boxShadow": 'none' }} >
                                            <p className='mb-0' style={{ "color": "#E64A19" }}>Advisor not available for this date</p>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                        <Grid container>
                            <Grid item sm={12} md={12} lg={12} sx={{ width: '100%' }}>
                                <div className="input-floating-label">
                                    <input refs="Location" type="text" className="textbox--primary textbox--rounded input"
                                        placeholder="Location" {...register('Location')}
                                    />
                                    <label>Meeting Location</label>
                                    <div className="error-text" style={{ "position": "absolute" }} >{errors?.Location?.message}</div>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item sm={12} md={12} lg={12} sx={{ width: '100%' }}>
                                <div className="input-floating-label">
                                    <input refs="password" type="text" className="textbox--primary textbox--rounded input"
                                        placeholder="Password" {...register('password')}
                                    />
                                    <label>Meeting Password</label>
                                    <div className="error-text" style={{ "position": "absolute" }} >{errors?.password?.message}</div>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} sx={{ width: '100%' }}>
                                <div className="input-floating-label">
                                    <textarea {...register("Description")} refs="Description" maxLength={250} className={"textarea--primary textbox--rounded input"} name="Description"
                                        placeholder=" " style={{ "minHeight": "100px", "resize": "none" }} id="resourceName" />
                                    <label >Description</label>
                                    <div className="error-text" style={{ "position": "absolute" }} >{errors?.Description?.message}</div>
                                    <div style={{ "color": "#595959", fontSize: "12px", float: 'right' }} >{Description?.length ? Description?.length : 0}/250 </div>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} sx={{ width: '100%' }}>
                                <div className="BookSessionModal_footer">
                                    <Button variant="contained" className="BookBtn" type="submit">Book Session</Button>
                                </div>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Modal>
        </>
    )
}

const mapStateToProps = ({ commonData, userProfileData }) => {
    const { advisorTime } = commonData;
    const { userProfile } = userProfileData;
    return { advisorTime, userProfile };
};

export default connect(mapStateToProps, { advisorAvailablity })(Index);
