import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS, CUSTOM_ALERT_SHOW, CUSTOM_ALERT_CLOSE
} from "../constants/CommonTypes";
import { SET_ISPARENTGOAL_SUCCESS_STATUS, GET_PARENT_GOALS_STATUS, SET_PARENT_GOAL_SUCCESS, GET_STUDENT_BY_ID_PROFILE, GET_PARENT_GOALS, GET_PARENT_GOALS_STUDEN,GET_PARENTS_GOALS_STATUS, GET_PARENT_RECOMMENDED, GET_PARENT_RECOMMENDED_STATUS, GET_ADVISOR_COUNT_UPDATES } from "../constants/ParentActionTypes";
import axios from 'axios'
import { getUserProfile } from "../actions/Profile";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const setParentUserGoals = requestParams => {
  return (dispatch) => {
    dispatch({ type: FETCH_START })
    axios.post(BASE_URL + '/user-service/v1/parent/goals/update', requestParams).then(({ data }) => {
      const { headers: { statusCode, message } } = data
      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: SET_ISPARENTGOAL_SUCCESS_STATUS, payload: true });
        dispatch({ type: SET_PARENT_GOAL_SUCCESS, payload: true });
        dispatch(getUserProfile());
      } else {
        dispatch({ type: FETCH_ERROR, payload: message });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      }
    }).catch(function (error) {
      if (error.response) {
        const { headers: { message, statusCode }, errorlist, } = error.response.data;
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      } else {
        dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      }
    });
  }
};

export const setParentUserGoalsProfile = requestParams => {
  return (dispatch) => {
    dispatch({ type: FETCH_START })
    axios.post(BASE_URL + '/user-service/v1/parent/goals/update', requestParams).then(({ data }) => {
      const { headers: { statusCode, message } } = data
      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: SET_PARENT_GOAL_SUCCESS, payload: true });
        dispatch(getUserProfile());
      } else {
        dispatch({ type: FETCH_ERROR, payload: message });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      }
    }).catch(function (error) {
      if (error.response) {
        const { headers: { message, statusCode }, errorlist, } = error.response.data;
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      } else {
        dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      }
      console.log("Error****:", error.message);
    });
  }
};


export const setIsParentReset = status => {
  return (dispatch) => {
    dispatch({ type: SET_PARENT_GOAL_SUCCESS, payload: status });
  }
};
export const getParentGoals = () => {
  console.log("working");
  return (dispatch) => {
    dispatch({ type: FETCH_START })
    axios.get(BASE_URL + '/user-service/v1/parent/goals/load').then(({ data }) => {
      const { headers: { statusCode, message } } = data
      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: GET_PARENT_GOALS, payload: data.recordInfo });
        dispatch({ type: GET_PARENTS_GOALS_STATUS, payload: true });
        // dispatch(getUserProfile());
      } else {
        dispatch({ type: FETCH_ERROR, payload: message });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      }
    }).catch(function (error) {
      if (error.response) {
        const { headers: { message, statusCode }, errorlist, } = error.response.data;
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      } else {
        dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      }
      console.log("Error****:", error.message);
    });
  }
};

export const setIsParentGoalSuccess = status => {
  return (dispatch) => {
    dispatch({ type: SET_ISPARENTGOAL_SUCCESS_STATUS, payload: status });
  }
};
export const setIsParentGoalStatus = status => {
  return (dispatch) => {
    dispatch({ type: GET_PARENTS_GOALS_STATUS, payload: status });
  }
};


export const getGoalsFromStudent = requestParams => {
  return (dispatch) => {
    dispatch({ type: FETCH_START })
    axios.post(BASE_URL + '/user-service/v1/parent/goals/load/studentgoals', requestParams).then(({ data }) => {
      const { headers: { statusCode, message } } = data
      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: GET_PARENT_GOALS, payload: data.recordInfo });
        dispatch({ type: GET_PARENT_GOALS_STATUS, payload: true });
        dispatch(getUserProfile());
      } else {
        dispatch({ type: FETCH_ERROR, payload: message });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      }
    }).catch(function (error) {
      if (error.response) {
        const { headers: { message, statusCode }, errorlist, } = error.response.data;
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      } else {
        dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      }
      console.log("Error****:", error.message);
    });
  }
};
export const getGoalsFromStudentStatus = status => {
  return (dispatch) => {
    dispatch({ type: GET_PARENT_GOALS_STATUS, payload: status });
  }
};
export const setParentUserGoalsStudent = (requestParams, value) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START })
    axios.post(BASE_URL + '/user-service/v1/parent/goals/update/studentgoals', requestParams).then(({ data }) => {
      const { headers: { statusCode, message } } = data
      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: SET_ISPARENTGOAL_SUCCESS_STATUS, payload: true });
        dispatch(getUserProfile());
        if (value === "profile") {
          dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } })
          setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 6000)
        }
      } else {
        dispatch({ type: FETCH_ERROR, payload: message });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      }
    }).catch(function (error) {
      if (error.response) {
        const { headers: { message, statusCode }, errorlist, } = error.response.data;
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      } else {
        dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      }
      console.log("Error****:", error.message);
    });
  }
};


export const getStudentById = (id) => {
  console.log("working");
  return (dispatch) => {
    dispatch({ type: FETCH_START })
    axios.get(BASE_URL + '/user-service/v1/parent/profile/viewstudent/'+ id).then(({ data }) => {
      const { headers: { statusCode, message } } = data
      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: GET_STUDENT_BY_ID_PROFILE, payload: data });
        // dispatch({ type: GET_PARENTS_GOALS_STATUS, payload: true });
        // dispatch(getUserProfile());
      } else {
        dispatch({ type: FETCH_ERROR, payload: message });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      }
    }).catch(function (error) {
      if (error.response) {
        const { headers: { message, statusCode }, errorlist, } = error.response.data;
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      } else {
        dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      }
      console.log("Error****:", error.message);
    });
  }
};

//advisor recommendation

export const getParentRecommended = requestParams => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		try {
			const { data } = await axios.post(BASE_URL + '/user-service/v1/search/suggestedparents', requestParams);
			const { headers: { statusCode, message } } = data;
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: GET_PARENT_RECOMMENDED, payload: data.data });
				dispatch({ type: GET_PARENT_RECOMMENDED_STATUS, payload: true });
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		} catch (error) {
			if (error.response) {
				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
			}

		}
	}
};

export const getParentRecommendedstatus = status => {
	return (dispatch) => {
		dispatch({ type: GET_PARENT_RECOMMENDED_STATUS, payload: status });
	}
}


export const getAdvisorCountUpdates = (id) => {
  console.log("working");
  return (dispatch) => {
    dispatch({ type: FETCH_START })
    axios.get(BASE_URL + '/user-service/v1/dashboard/parent/counts/'+ id).then(({ data }) => {
      const { headers: { statusCode, message } } = data
      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: GET_ADVISOR_COUNT_UPDATES, payload: data });
      } else {
        dispatch({ type: FETCH_ERROR, payload: message });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      }
    }).catch(function (error) {
      if (error.response) {
        const { headers: { message, statusCode }, errorlist, } = error.response.data;
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      } else {
        dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      }
      console.log("Error****:", error.message);
    });
  }
};

