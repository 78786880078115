import React, { useEffect, useState } from 'react'
import { DeleteCourse, DeleteActivity, undoCourseDropSuggestion, undoActivityDropSuggestion, 
	undoActivitySuggestion, undoCourseSuggestion, setSuggestToDropCourseStatus, setUndoCourseSuggestionStatus,
	GetStudentPlanPhaseStatus, setPhaseTimeStatus, setUndoActivitySuggestionStatus, setUndoActivitySuggestionErrorStatus } from "../../../redux/actions";
import { connect } from 'react-redux';
import UndoRoundedIcon from '@mui/icons-material/UndoRounded';
import { Tooltip } from '@mui/material';
import Loader from '../../../components/Loader';
import './SemesterPills.less'

const Index = (props) => {
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if(props?.isCourseDeleted || props.isActivityDeleted){
			setLoading(false);
		}
		if (props?.isgetplanphase) {
			setLoading(false);
			props?.GetStudentPlanPhaseStatus(false);
		}
		if (props?.undoCourseSuggestionStatus) {
            props?.setUndoCourseSuggestionStatus(false);
		}
		if (props?.undoCourseSuggestionErrorStatus) {
			setLoading(false);
            props?.setUndoCourseSuggestionStatus(false);
		}
		if (props?.undoActivitySuggestionStatus) {
            props?.setUndoActivitySuggestionStatus(false);
		}
		if (props?.undoActivityuggestionErrorStatus) {
			setLoading(false);
            props?.setUndoActivitySuggestionErrorStatus(false);
		}

		// if (props?.undoCourseDropSuggestionStatus) {
		// 	setLoading(false)
		// }
		// if (props?.courseDropSuggestionStatus) {
        //     props?.setSuggestToDropCourseStatus(false)
		// 	setLoading(false)
		// }
		// if (props?.activityDropSuggestionStatus) {
		// 	setLoading(false)
		// }
		// if (props?.undoActivityDropSuggestionStatus) {
		// 	setLoading(false)
		// }
		// if (props?.undoActivitySuggestionStatus) {
		// 	setLoading(false)
		// }
	}, [props?.isCourseDeleted, props.isActivityDeleted, props?.undoCourseDropSuggestionStatus,
		props?.courseDropSuggestionStatus, props?.activityDropSuggestionStatus, props?.undoCourseSuggestionStatus,
		props?.undoCourseSuggestionErrorStatus, props?.undoActivityDropSuggestionStatus, 
		props?.undoActivitySuggestionStatus, props?.undoActivityuggestionErrorStatus
	])
  

	const deleteCourse = (event, deleteId) => {
		event.stopPropagation();
		if (props?.activityDelete) {
			let payload = {
				planId: props?.totalData?.planId,
				phaseId: props?.totalData?.id
			}
			props.DeleteActivity(payload, deleteId, "dashboard")
			setLoading(true)
		} else {
			let payload = {
				planId: props?.totalData?.planId,
				phaseId: props?.totalData?.id
			}
			props.DeleteCourse(payload, deleteId, "dashboard")
			setLoading(true)
		}
	}

	const handleUndoSuggestion = (event, deleteId) => {
		event.stopPropagation();
		if (props?.activityDelete) {
			let payload = {
				activityId: deleteId,
				planId: props?.totalData?.planId,
				phaseId: props?.totalData?.id
			}
			props.undoActivitySuggestion(payload)
			setLoading(true)
		} else {
			let payload = {
				planId: props?.totalData?.planId,
				phaseId: props?.totalData?.id,
				courseId: deleteId,
			}
			props.undoCourseSuggestion(payload)
			setLoading(true)
		}
	}
	const handleUndoDropSuggestion = (event, deleteId) => {
		event.stopPropagation();
		if (props?.activityDelete) {
			let payload = {
				planId: props?.totalData?.planId,
				phaseId: props?.totalData?.id,
				activityId: deleteId
			}
			props.undoActivityDropSuggestion(payload)
			setLoading(true)
		} else {
			let payload = {
				planId: props?.totalData?.planId,
				phaseId: props?.totalData?.id,
				courseId: deleteId,
			}
			props.undoCourseDropSuggestion(payload)
			setLoading(true)
		}
	}

	const getSuggestionDetail = (isApproved, isDropSuggested) => {
		if (!isApproved) {
			return (
				<Tooltip title="Undo Suggestion"  placement="top">
                	<UndoRoundedIcon style={{fontSize: 18, marginLeft: "5px", cursor: 'pointer'}} onClick={(e) => handleUndoSuggestion(e, props?.deleteId)}/>
				</Tooltip>
			);	
		}
        if (isDropSuggested) {
            return (
				<Tooltip title="Undo Suggestion"  placement="top">
	                <UndoRoundedIcon style={{fontSize: 18, marginLeft: "5px", cursor: 'pointer'}} onClick={(e) => handleUndoDropSuggestion(e, props?.deleteId)}/>
				</Tooltip>

            );
        } else {
            return (
				<svg onClick={(e) => deleteCourse(e, props.deleteId)} style={{ cursor: 'pointer' }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path fillRule="evenodd" clipRule="evenodd" d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM12 13.4142L8.70711 16.7071L7.29289 15.2929L10.5858 12L7.29289 8.70711L8.70711 7.29289L12 10.5858L15.2929 7.29289L16.7071 8.70711L13.4142 12L16.7071 15.2929L15.2929 16.7071L12 13.4142Z" fill="#4a4848" />
				</svg>
            );
        }
    }

	return (
		<>
			{loading && <Loader />}
			<div className='tag__tags-chip' onClick={(e) => props?.coursepopupshow(props?.totalData, props?.courseData)}>
				<p title={props.text} className={`SemPills Chips tag__tags-chip__tag-text${props.bold ? "-bold" : "-light"}`}>
					{props.text}
					&nbsp;
				</p>
				{ props?.close && 
					getSuggestionDetail(props?.courseData?.isApproved, props?.courseData?.isDropsuggested)
				}
			</div>
		</>

	)
}

const mapStateToProps = ({ studentData, advisorData }) => {
  const { isCourseMileStone, createCourseMilestoneStatus, mileCourseUpdata, isMilestoneDeleted, 
    isUpdateResource, isaddNewResource, isDeleteResource, courseDataById, courseDataByIdStatus, 
    isMileStatus, isMileStoneCourseStatus, isCourseDeleted, isActivityDeleted, isgetplanphase } = studentData;
  const { undoCourseDropSuggestionStatus, courseDropSuggestionStatus, undoActivityDropSuggestionStatus, 
	activityDropSuggestionStatus, undoActivitySuggestionStatus, undoCourseSuggestionStatus, undoCourseSuggestionErrorStatus,
	undoActivityuggestionErrorStatus
 } = advisorData;
  return { isCourseMileStone, createCourseMilestoneStatus, mileCourseUpdata, isMilestoneDeleted, isUpdateResource, 
    isaddNewResource, isDeleteResource, courseDataById, courseDataByIdStatus, isMileStatus, undoCourseSuggestionStatus,
    isMileStoneCourseStatus, isCourseDeleted, isActivityDeleted, undoCourseDropSuggestionStatus, activityDropSuggestionStatus,
	courseDropSuggestionStatus, undoActivityDropSuggestionStatus, undoActivitySuggestionStatus, isgetplanphase, 
	undoCourseSuggestionErrorStatus, undoActivityuggestionErrorStatus };
};
export default connect(mapStateToProps, { DeleteCourse, DeleteActivity, undoCourseDropSuggestion, undoActivityDropSuggestion, 
	undoActivitySuggestion, undoCourseSuggestion, setSuggestToDropCourseStatus, setUndoCourseSuggestionStatus, GetStudentPlanPhaseStatus,
	setPhaseTimeStatus, setUndoActivitySuggestionStatus, setUndoActivitySuggestionErrorStatus })(Index);                                                                                                                                                                                                                                                                                                                                                                                               
