import React, { useState, useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "../../assets/css/owlCarousel.less";
import { Grid } from "@mui/material";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import Tags from "../../componentsNew/Common/Tags";
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import PersonIcon from '@mui/icons-material/Person';
import {
    getRecommendedStudents, getAdvisorRecommendedstatus, getAdvisorRecommended,
    getParentRecommended, userConnect, userConnectStatus, userConnectErrorStatus, resetDetailedUserProfileStatus
} from "../../redux/actions";
import { connect } from "react-redux";
import ConnectCard from "../connectPopup";
import PillSection from "../../componentsNew/Common/PillSection"
import ExploreStudentStudent from "../../componentsNew/Common/Cards/Student/ExploreStudent-Student";
import SuggestedStudent from "../../componentsNew/Common/Cards/Student/SuggestedStudent";
import ViewStudentProfile from "../../componentsNew/Common/ViewStudentProfile";

const Index = (props) => {
    const [connect, setConnectPopup] = useState(false);
    const [showProfile, setShowProfile] = useState(false);
    const [profileData, setProfileData] = useState({});
    const [lastViewedIndex, setLastViewedIndex] = useState(0);

    useEffect(() => {
        if (props.userConnectError) {
            props?.userConnectErrorStatus(false)
        }
    }, [props.userConnectError])



    const handleConnectClose = () => {
        setProfileData({})
        setConnectPopup(false);
    }

    //parent Popup Open Close
    const connectDataShow = (data) => {
        setProfileData(data)
        setConnectPopup(true);
    }

    const onSubmit = (data) => {            //Connect Popup Onsubmit
        let payload = {
            userId: profileData?.userInfo?.id,
            message: data?.description,
            accessiblePlans: []
        }
        props?.userConnect(payload)
    };
    const showProfileModal = (status) => {
        if (!status) {
            setProfileData({})
            props?.resetDetailedUserProfileStatus();
        }
        setShowProfile(status)
    }
    const handleViewProfile = (userInfo) => {
        setProfileData(userInfo);
        showProfileModal(true);
    }

    const options = {
        nav: true,
        navElement: ["ChevronLeftOutlinedIcon ", "ChevronRightIcon "],
        autoplay: false,
        slideBy: 1,
        dots: false,
        dotsEach: false,
        dotData: true,
        loop: false,
        startPosition: lastViewedIndex,
        onChanged: (event) => {
            if (event.item.index) {
                setLastViewedIndex(event.item.index);
            }
        },
        responsive: {
            0: {
                items: 1,
            },
            450: {
                items: 1,
            },
            575: {
                items: 1,
            },
            768: {
                items: 2,
            },
            1000: {
                items: props.OwlCarouselData?.length > 2 ? 4 : 3,
            },
            1024: {
                items:
                    props.OwlCarouselData?.length <= 2 && 2 ||
                    props.OwlCarouselData?.length === 3 && 3 ||
                    (props.OwlCarouselData?.length > 3 && 3),
            },
            1140: {
                items:
                    props.OwlCarouselData?.length <= 2 && 2 ||
                    props.OwlCarouselData?.length === 3 && 3 ||
                    (props.OwlCarouselData?.length > 3 && 3),
            },
            1366: {
                items:
                    props.OwlCarouselData?.length <= 2 && 2 ||
                    props.OwlCarouselData?.length === 3 && 3 ||
                    (props.OwlCarouselData?.length > 3 && 3),
                // autoWidth: true,
                // margin: 10,
                rewind: false
            },
            2000: {
                items: props.OwlCarouselData?.length > 2 ? 5 : 2,
                autoWidth: true,
                margin: -10,
                rewind: false
            },
        },
    };

    let owlCarouselData = props.OwlCarouselData.map((element, key) => {
        const { userInfo } = element;
        return (
            <div className="owl__item" key={userInfo?.id}>
                <a>
                    <SuggestedStudent
                        name={userInfo?.fullName}
                        country={userInfo?.country}
                        avatarUrl={userInfo?.avatarPath}
                        school={userInfo?.studentProfile?.currrentSchoolName}
                        gpa={userInfo?.studentProfile?.desiredGpa}
                        universities={userInfo?.studentProfile?.universities?.toSorted((a, b) => { return a.length - b.length })}
                        professions={userInfo?.studentProfile?.professions?.toSorted((a, b) => { return a.length - b.length })}
                        matchParam={element?.matchingParams?.[0]}
                        courseAndActivities={userInfo?.studentProfile?.courses?.concat(userInfo?.studentProfile?.activities)?.toSorted((a, b) => {return a?.length - b?.length})}
                        targetWeighted={userInfo?.studentProfile?.targetWeighted}
                        targetUnweighted={userInfo?.studentProfile?.targetUnweighted}
                        userProfile={props?.userProfile?.currentRole ?? null}
                    >
                        <button className="suggestedStudentCard__buttonSec__primary-btn" onClick={() => handleViewProfile({ id: userInfo?.id })}>
                            View Profile
                        </button>
                    </SuggestedStudent>
                </a>
            </div>
        )
    })
    return (
        <>
            <ConnectCard show={connect} handleConnectValues={(data) => onSubmit(data)} data={profileData} handleClose={handleConnectClose} />
            {showProfile &&
                <ViewStudentProfile show={showProfile} profileData={profileData} handleClose={() => showProfileModal(false)} />
            }
            {props?.OwlCarouselData?.length > 0 && (
                <OwlCarousel
                    className="owl owl-OwlCarousel owl-theme"
                    {...options} >
                    {owlCarouselData}
                </OwlCarousel>
            )}
        </>
    );
}
const mapStateToProps = ({ userProfileData, studentData, commonData, parentData }) => {
    const { detailedAdvisorData, userConnectError, userConnectSuccess, getRecommendedStatus, getRecommendedData } = commonData;
    const { userProfile } = userProfileData;
    const { getstudentplan, } = studentData;
    const { getRecommendParent, isParentRecommended } = parentData

    return { userProfile, detailedAdvisorData, getstudentplan, getRecommendedData, userConnectError, userConnectSuccess, getRecommendParent, isParentRecommended, getRecommendedStatus };
};
export default connect(mapStateToProps, { userConnectStatus, getRecommendedStudents, getAdvisorRecommendedstatus, getParentRecommended, userConnectStatus, userConnectErrorStatus, getAdvisorRecommended, userConnect, resetDetailedUserProfileStatus })(Index);
