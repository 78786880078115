import React, { useEffect, useState } from 'react'
import ConnectedAdvisorCard from "../../../../componentsNew/Common/Cards/Advisor/ConnectedAdvisor"
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import { connect } from "react-redux";
import { clearMessage, getConnectedAdvisorsParents, setConnectedAdvisorStatus, bookSession, bookPaidSession, disconnectUserSwitchStatus, getFreeSessionStatus, bookSessionStatus, getConnectedSessionDetails, bookPaidSessionErrorStatus, bookPaidSessionStatus, disconnectSwitchFriend } from '../../../../redux/actions'
import Loader from '../../../../components/Loader';
import AdvisorProfile from "../../../../componentsNew/Common/advisorProfile";
import BookSessionPopup from "../../../../componentsNew/Common/BookSession/BookSessionModal";
import { useNavigate } from 'react-router-dom';
import ConfirmationPopup from "../../../../componentsNew/Common/ConfirmationPopup";
import { convertUTCTodateTimeZone, convertUtcToEventTime } from '../../../../utils/CommonUtils';


const Index = (props) => {
    let navigate = useNavigate();
    const [loading, setloading] = useState(false)
    const [bookshow, setbookshow] = useState(false);
    const [TimeSession, setTimeSession] = useState([]);
    const [advisorid, setAdvisorid] = useState("");
    const [profilePopup, setProfiePopup] = useState(false);
    const [profileData, setProfileData] = useState({});
    const [showDisconnectModal, setShowDisconnectModal] = useState(false);
    const [disconnectUserData, setDisconnectUserData] = useState({});
    const [advisorName, setAdvisorName] = useState("");

    const profileShow = (data) => {
        setProfileData(data)
        setProfiePopup(true);
    }
    useEffect(() => {
        setloading(true)
        props?.clearMessage()
    }, [])


    useEffect(() => {
        let childId = localStorage.getItem("parentViaChild")
        if (props?.childprofile?.length > 0 && childId) {
            props?.getConnectedAdvisorsParents(childId)
            props?.getConnectedSessionDetails(childId);
        }
    }, [props?.childprofile])

    useEffect(() => {
        if (props?.connectedAdvisorsStatus && props?.connectedAdvisors) {
            const advisorId = props?.connectedAdvisors.map(item => item.userInfo?.id);
            let payload =
            {
                "advisorIds": advisorId,
                studentId: localStorage.getItem("parentViaChild")
            }

            if (props?.connectedAdvisors?.length > 0) {
                props?.getFreeSessionStatus(payload)
            }

            props?.setConnectedAdvisorStatus(false)
            setloading(false)
        }
        if (props?.unsubscribeStatus) {
            let childId = localStorage.getItem("parentViaChild")
            props?.getConnectedAdvisorsParents(childId)
        }
    }, [props?.connectedAdvisors, props?.unsubscribeStatus])

    const profileHide = () => setProfiePopup(false);

    const OpenBookSession = (e, data) => {
        props?.clearMessage()
        e.preventDefault()
        setTimeSession(data)
        setAdvisorid(data?.userInfo?.id)
        setProfileData(data)
        setAdvisorName({ label: data?.userInfo?.fullName, value: data?.userInfo?.id })
        setbookshow(true)
    }

    const CloseBookSession = () => {
        // props?.clearMessage()
        setbookshow(false)
        // setLoading(false)
    }
    function convertTo24HourFormat(time12h) {
        const [time, modifier] = time12h.split(' ');
        let [hours, minutes] = time.split(':');
        hours = parseInt(hours, 10);
        if (modifier === 'PM' && hours !== 12) {
            hours += 12;
        } else if (modifier === 'AM' && hours === 12) {
            hours = 0;
        }
        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.padStart(2, '0');
        return `${formattedHours}:${formattedMinutes}:00`;
    }

 

    const SessionBook = (data, timeString) => {
        const startTime = timeString.split(' - ')[0];
        const endTime = timeString.split(' - ')[1];
        const moment = require('moment');
        const selectedDate = moment(data.Selectdate, "YYYY-MM-DD").format("YYYY-MM-DD");
        let currentTime = selectedDate + ' ' + convertTo24HourFormat(startTime)
        let payload = {
            "advisorId": advisorid,
            "eventDuration": '30',
            "eventTime": currentTime,
            "meetingLocation": data?.Location,
            "meetingDescription": data?.Description,
            "password": data?.password,
            "studentId": localStorage.getItem("parentViaChild"),
        }
        if (data?.package) {
            payload["packageId"] = data?.package?.value;
            props?.bookPaidSession(payload)
        } else {
            props?.bookSession(payload)
        }
    }

    useEffect(() => {
        let childId = localStorage.getItem("parentViaChild")
        if (props?.sessionBooked) {
            // setLoading(false)
            CloseBookSession()
            props?.getConnectedAdvisorsParents(childId)
            props?.getConnectedSessionDetails(childId);
            props?.bookSessionStatus(false)
        }
        if (props?.booksessionerror) {
            // setLoading(false)
            CloseBookSession()
            props?.bookSessionStatus(false)
        }
    }, [props?.sessionBooked, props?.booksessionerror])

    useEffect(() => {
        let childId = localStorage.getItem("parentViaChild")

        if (props?.paidSessionBooked) {
            props?.getConnectedAdvisorsParents(childId)
            props?.getConnectedSessionDetails(childId);
            CloseBookSession()
            props?.bookPaidSessionStatus(false)
        }
        if (props?.bookPaidSessionError) {
            // setLoading(false)
            CloseBookSession()
            props?.bookPaidSessionErrorStatus(false)
        }
    }, [props?.paidSessionBooked, props?.bookPaidSessionError])

    const openDisconnectModal = (status, data) => {
        setDisconnectUserData(data)
        setShowDisconnectModal(status);
    }
    const HandleNavigate = (e) => {
        e.preventDefault();
        navigate('/parent/mynetwork')
    }
    const handleDelete = () => {
        let childId = localStorage.getItem("parentViaChild")
        let payload = {
            userId: disconnectUserData?.userInfo?.id,

        }
        props?.disconnectSwitchFriend(childId, payload)
    }
    useEffect(() => {
        if (props?.isDisconnectFriend) {
            let childId = localStorage.getItem("parentViaChild")
            props?.getConnectedAdvisorsParents(childId)
            props?.getConnectedSessionDetails(childId);
            setShowDisconnectModal(false);
            props?.disconnectUserSwitchStatus(false);
        }

    }, [props?.isDisconnectFriend,])


    const getScheduledTime = (userId, packageId) => {
        const childId = props?.childprofile?.filter(profile => {
            return profile?.id === localStorage?.getItem("parentViaChild");
        });

        let eventDetails = props?.scheduledSessionDetails?.find(data => data?.userId === userId && data?.packageId === packageId);
        if (eventDetails) {
            let date = convertUTCTodateTimeZone(eventDetails?.eventTime, childId && childId[0]?.timezone);
            let formattedDate = date.format('DD/MM/YYYY')
            return `${formattedDate} at ${convertUtcToEventTime(eventDetails?.eventTime, childId && childId[0]?.timezone)?.format('hh:mm A')}`
        } else {
            return null;
        }
    }


    return (
        <>
            {loading && <Loader />}
            {bookshow &&
                <BookSessionPopup show={bookshow} UserData={props?.userProfile} advisorData={profileData} selectedAdvisor={advisorName} advisorList={props?.connectedAdvisors} onHide={CloseBookSession} Time={TimeSession} HandleSumit={SessionBook} />}
            {profilePopup &&
                <AdvisorProfile show={profilePopup} detailedInfo={props?.detailedAdvisorData} data={profileData} handleClose={profileHide} />
            }
            {showDisconnectModal &&
                <ConfirmationPopup show={showDisconnectModal} handleSubmit={() => handleDelete()} message={`You and ${disconnectUserData?.userInfo?.fullName} will no longer be connected to each other`} btnText="confirm" onHide={() => openDisconnectModal(false)} />
            }
            {props?.connectedAdvisors?.length > 0 ? (
                <div className='Network__Content' style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {props?.connectedAdvisors && props?.connectedAdvisors
                        .sort((a, b) => b?.subscriptiondetails?.length - a?.subscriptiondetails?.length)
                        .map((data) => {
                            let filtered = props?.freeSessionStatus?.length > 0 && props?.freeSessionStatus?.filter(
                                (freeSessionData) => freeSessionData.advisorId === data?.userInfo?.id
                            );
                            return (
                                <ConnectedAdvisorCard data={data} key="">
                                    {
                                        data?.subscriptiondetails?.length === 0 && (
                                            <>
                                                {
                                                    filtered && filtered?.length > 0 && filtered[0]?.freeSessionCompleted === false && (
                                                        <div className="netWork-card__button-sec">
                                                            {getScheduledTime(data?.userInfo?.id) ? (
                                                                <span className="meetTimeZone">
                                                                    <CalendarTodayOutlinedIcon sx={{ fontSize: '0.7rem' }} /> You are scheduled to meet on {getScheduledTime(data?.userInfo?.id)}
                                                                </span>
                                                            ) : ''}
                                                            {!getScheduledTime(data?.userInfo?.id) &&
                                                                <button className="netWork-card__button-sec__primes-btn" onClick={(e) => OpenBookSession(e, data)} style={{ "width": "90%" }}>
                                                                    Book Free Session
                                                                </button>
                                                            }
                                                        </div>
                                                    )
                                                }
                                                {
                                                    filtered && filtered?.length > 0 && (filtered[0]?.freeSessionCompleted === true && filtered[0]?.acceptedFreeSession === 1) && (
                                                        <div className="netWork-card__button-sec">
                                                            <button className="netWork-card__button-sec__primes-btn" onClick={(e) => profileShow(data)} style={{ "width": "90%", "height": "42px" }}>
                                                                Purchase Subscription
                                                            </button>
                                                        </div>
                                                    )
                                                }

                                                <div className="netWork-card__button-sec">
                                                    <button className="netWork-card__button-sec__secondary-btn" onClick={() => openDisconnectModal(true, data)} style={{ "width": "90%", "height": "42px" }}>
                                                        Disconnect
                                                    </button>
                                                </div>
                                            </>
                                        )
                                    }

                                    <div className="netWork-card__button-sec">
                                        <button className="netWork-card__button-sec__primary-btn" onClick={(e) => profileShow(data)} style={{ "width": "90%", "height": "42px" }}>
                                            View Profile
                                        </button>
                                    </div>
                                </ConnectedAdvisorCard>
                            );
                        })}

                </div>
            ) :
                <div className='Empty__Container'>
                    <div className='Backdrop__container__card' style={{ marginTop: '6rem' }}>
                        <div className='title_cnt'>
                            <p>Let’s get you connected with some advisors who can help you achieve your goals !</p>
                        </div>
                        <div className='btn_cnt'>
                            <p onClick={(e) => HandleNavigate(e)}>
                                <span>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="11" cy="11" r="6" stroke="#222222" />
                                        <path d="M11 8C10.606 8 10.2159 8.0776 9.85195 8.22836C9.48797 8.37913 9.15726 8.6001 8.87868 8.87868C8.6001 9.15726 8.37913 9.48797 8.22836 9.85195C8.0776 10.2159 8 10.606 8 11" stroke="#222222" stroke-linecap="round" />
                                        <path d="M20 20L17 17" stroke="#222222" stroke-linecap="round" />
                                    </svg>
                                </span>
                                Explore Advisors
                            </p>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

const mapStateToProps = ({ commonData, userProfileData }) => {
    const { isDisconnectFriend, childprofile, userProfile } = userProfileData;
    const { message, errorList, connectedAdvisors, connectedAdvisorsStatus, paidSessionBooked, scheduledSessionDetails, bookPaidSessionError, sessionBooked, booksessionerror, freeSessionStatus, unsubscribeStatus } = commonData;
    return { message, errorList, connectedAdvisors, isDisconnectFriend, childprofile, userProfile, connectedAdvisorsStatus, paidSessionBooked, scheduledSessionDetails, bookPaidSessionError, sessionBooked, booksessionerror, freeSessionStatus, unsubscribeStatus };
};
export default connect(mapStateToProps, { clearMessage, getConnectedAdvisorsParents, disconnectUserSwitchStatus, getFreeSessionStatus, getConnectedSessionDetails, setConnectedAdvisorStatus, bookSession, bookPaidSession, bookSessionStatus, bookPaidSessionErrorStatus, bookPaidSessionStatus, disconnectSwitchFriend })(Index);
