import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../../../assets/css/sendInvite.less";
import { connect } from "react-redux";
import { setUserOnboard, setIsUserProfileSuccess } from "../../../../redux/actions";
import { withRouter } from "../../../../redux/store/navigate";
import { EMAIL_LABEL } from "../../../../Constants/commonLabels";
import { SentInviteSpouse, CopyInviteLink, clearMessage, GetInviteSpouse, CopyInviteLinkstatus, SentInviteParentstatus, getUserProfile, setCopyInviteFlag, CopyInviteErrorstatus } from "../../../../../src/redux/actions";
import ReactTooltip from 'react-tooltip';
import CustomAlert from "../../../../components/Alert";
import Loader from "../../../../components/Loader"
import copy from 'copy-to-clipboard';
import AlertBox from '@mui/material/Alert';
import Alert from 'react-bootstrap/Alert';
import Footer from "../../../../componentsNew/Common/Footer";

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataLoaded: false,
            loading: false,
            fields: { email: '' },
            errors: {},
            alertVisible: false,
            selectedEmail: [],
            clicked: false,
            displayError: false,
            emailMatchs: [],
            isClickedNext: false,
            link: '',
            copiedlink: false,
        };
    }


    componentDidMount() {
        this.props.setCopyInviteFlag(true)
        this.props.clearMessage()
        this.props.GetInviteSpouse()
        this.handleCopySendInvite("componentDidMount")
        this.setInitialValues();
        // if (this.props.isUserOnboarded) {
        //     this.props.navigate('/student/dashboard');
        // }
    }

    componentDidUpdate = async () => {
        if (this.props.isUserOnboarded) {
            this.props.navigate('/parent/dashboard');
        }

        if (this.props.isInviteSentSucess) {

            this.props.getUserProfile()
            this.props.SentInviteParentstatus(false)
        }

        if (this.props.copyInviteFlag && this.state.clicked === true && this.state.link === '') {
            this.handleCopySendInvite("componentDidUpdate")
            this.props.CopyInviteLinkstatus(false)
        }
        console.log("this.props.copyInvitelinkSucess", this.props.copyInvitelinkSucess)
        if (this.props.copyInvitelinkSucess) {
            await this.setState({ link: this.props.copyInvitelink, clicked: false })
            this.props.setCopyInviteFlag(false)
        }
        if (this.props.copylinkerror && this.state.clicked === true) {
            this.setState({ alertVisible: true, copiedlink: false })
            CopyInviteErrorstatus(false)
        }
    }

    CopyClipboards = async () => {
        this.props.clearMessage()
        let { link } = this.state
        this.setState({ copiedlink: false, alertVisible: false })
        var textfield = document.createElement("input");
        textfield.setAttribute('id', 'myInput')
        textfield.setAttribute('value', link)
        document.body.appendChild(textfield)
        var copyText = document.getElementById("myInput");
        copyText.focus();
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand('copy');
        textfield.remove();
        this.props.CopyInviteLinkstatus(false)
        this.setState({ link: '', copiedlink: true, alertVisible: true, clicked: true, showalert: true })
        setTimeout(() => {
            this.setState({ showalert: false, alertVisible: false, copiedlink: false })
        }, 3000);
        this.props.setCopyInviteFlag(true)
    }

    componentWillReceiveProps = async (newprops) => {
        if (newprops?.userProfile?.parentProfile?.parentEmails) {
            let { fields, selectedEmail } = this.state;
            fields["email"] = newprops?.userProfile?.parentProfile?.parentEmails.join(", ");
            if (newprops?.userProfile?.parentProfile?.parentEmails != undefined) {
                newprops?.userProfile?.parentProfile?.parentEmails?.map((email) => {
                    selectedEmail.push(email)
                })
            }
            this.setState({ selectedEmail });
        }
    }

    handleChange = (field, e) => {
        let { errors } = this.state;
        let fields = this.state.fields;
        fields[field] = e.target.value;
        if (e.target.value.length >= 0) {
            errors[field] = "";
        }
        this.setState({ fields, errors: errors, displayError: true });
    }

    handleSendInvite = (e) => {
        e?.preventDefault();
        this.setState({ isClickedNext: false })
        if (this.handleValidation()) {
            let fields = this.state.fields;
            let emailLst = []
            let Emailfield
            if (fields["email"]) {
                Emailfield = fields["email"]?.split(',')
                Emailfield?.map(element => {
                    if (element.trim() !== "") {
                        emailLst.push(element.trim())
                    }
                });
            }
            let values = {
                emails: emailLst,
            }
            this.setState({ alertVisible: true, clicked: true, displayError: false, isClickedNext: false })
            this.props.SentInviteSpouse(values);
        }
    }

    handleCopySendInvite = (vals) => {
        console.log("vals", vals);
        console.log("handleCopySendInvite", this.props.copyInviteFlag)
        let values = {
            "role": "parent"
        }
        if (this.props.copyInviteFlag) {
            this.props.CopyInviteLink(values);
        }
    }


    validateEmails = (emails) => {
        const re = /^[\w-\.+]+@([\w-]+\.)+[\w-]{2,4}$/;
        const emailArray = emails.split(',');
        for (let i = 0; i < emailArray.length; i++) {
            if (!re.test(emailArray[i].trim())) {
                return false;
            }
        }
        return true;
    }


    handleValidation = () => {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        console.log(this.state.displayError, "displayError")
        if (this.state.displayError === false) {
            console.log(!fields["email"], "sfsffsfsf")
            console.log(fields["email"], "sfsffsfsfemail")

            if (!fields["email"]) {
                formIsValid = false;
                errors["email"] = "*Required";
                this.setState({ errors: errors });
            }
        }
        if (fields["email"] != "") {
            let lastAtPos = fields["email"].lastIndexOf("@");
            let lastDotPos = fields["email"].lastIndexOf(".");
            if (
                !(
                    lastAtPos < lastDotPos &&
                    lastAtPos > 0 &&
                    fields["email"].indexOf("@@") == -1 &&
                    lastDotPos > 2 &&
                    fields["email"].length - lastDotPos > 2
                )
            ) {

                formIsValid = false;
                errors["email"] = "Not a valid email";
            }

            if (this?.validateEmails(fields["email"])) {
                console.log('Emails are valid');
            } else {
                formIsValid = false;
                errors["email"] = "Not a valid email";
            }

        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    setInitialValues = () => {
        let { fields, selectedEmail } = this.state;
        fields["email"] = this.props?.userProfile?.parentEmails?.join(", ");
        if (this.props?.userProfile?.parentEmails != undefined) {
            this.props?.userProfile?.parentEmails?.map((email) => {
                selectedEmail.push(email)
            })
        }
        this.setState({ selectedEmail });

    }
    handleClickSkip = () => {
        this.props.setUserOnboard();
    }
    handleNext = (e) => {
        console.log(this.props?.userProfile?.parentProfile?.parentEmails, "this.props?.userProfile")
        e.preventDefault()
        if (this?.state?.displayError === true) {
            let { fields, emailMatchs } = this.state;

            if (this.props?.userProfile?.parentProfile?.parentEmails) {
                if (this.props?.userProfile?.parentProfile?.parentEmails != undefined) {
                    this.props?.userProfile?.parentProfile?.parentEmails?.map((email) => {
                        emailMatchs.push({
                            emails: email === fields["email"] ? true : false,
                        })
                    })
                    if (emailMatchs && emailMatchs[0]?.emails === true) {
                        // this.props.navigate('/onboarding/profile/child');
                        this?.handleClickSkip()

                    } else {
                        this.setState({ isClickedNext: true });
                    }
                }

            }
            if (this.props?.userProfile?.parentProfile?.parentEmails?.length === 0) {
                if (fields["email"] === "") {
                    // this.props.navigate('/onboarding/profile/child');
                    this?.handleClickSkip()
                }
            } else if (this.props?.userProfile?.parentProfile?.parentEmails?.length !== 0) {
                if (fields["email"] === "") {
                    this.handleSendInvite()
                    // this.props.navigate('/onboarding/profile/child');
                    this?.handleClickSkip()
                } else {
                    this.setState({ isClickedNext: true });
                }
            }
        } else {
            // this.props.navigate('/onboarding/profile/child');
            this?.handleClickSkip()
        }
    }
    render() {
        return (
            <>
                {this.state.loading && <Loader />}
                {this.state.loading || this.props.loading && <Loader />}

                <div className="sendInvite sendInviteParent">
                    <div className="sendInvite__form">
                        <h1 className="sendInvite__form__title text-center">Would you like to invite your spouse ?</h1>
                        <div className="sendInvite__form_inner">
                            <div className="row m-0">
                                <div className="col-md-12 col-sm-12 col-12 ps-0 p-mob-0">
                                    <div className="input-floating-label">
                                        <input
                                            refs="Email"
                                            type="text"
                                            className={"textbox--primary textbox--rounded input"}
                                            name="Email"
                                            placeholder="Email"
                                            value={this.state.fields["email"]}
                                            onChange={(e) => this.handleChange("email", e)}
                                        />
                                        <label>{EMAIL_LABEL}</label>
                                        {this.state.errors["email"] && <span className="error-text ps-0">{this.state.errors["email"]}</span>}

                                    </div>
                                </div>
                            </div>
                            <div className="row m-0">
                                <div className="col-md-12 col-sm-12 col-12 ps-0 p-mob-0">
                                    <div className="text-center" >
                                        <Link to="#">
                                            <Button className="btn cta--rounded cta-primary mb-4 w-100" disabled={this.state.loading} onClick={(e) => this.handleSendInvite(e)} >
                                                {this.state.loading && (
                                                    <span className="spinner-border spinner-border-sm"></span>
                                                )}
                                                <span>Send Invite</span>
                                            </Button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="row m-0">
                                {this.props.copyInvitelinkSucess === false ? (
                                    <ReactTooltip id="copy" place="bottom" effect="solid" className={"tooltip"}>Click to copy link</ReactTooltip>
                                ) : (
                                    <ReactTooltip id="copy" place="bottom" effect="solid" className={"tooltip"}>Link copied success</ReactTooltip>
                                )}
                                <div className="col-md-12 col-sm-12 col-12 ps-0 p-mob-0">
                                    <p data-tip className="text-center" style={{ "cursor": "pointer" }} onClick={(e) => this.CopyClipboards(this.state?.link)}>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.5441 10.456C12.7249 9.6372 11.6142 9.17725 10.4561 9.17725C9.2979 9.17725 8.18716 9.6372 7.36805 10.456L4.27905 13.544C3.45993 14.3631 2.99976 15.474 2.99976 16.6325C2.99976 17.7909 3.45993 18.9018 4.27905 19.721C5.09818 20.5401 6.20914 21.0003 7.36755 21.0003C8.52597 21.0003 9.63693 20.5401 10.4561 19.721L12.0001 18.177" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M10.4561 13.5438C11.2752 14.3626 12.3859 14.8225 13.5441 14.8225C14.7022 14.8225 15.8129 14.3626 16.6321 13.5438L19.7211 10.4558C20.5402 9.63669 21.0004 8.52572 21.0004 7.36731C21.0004 6.2089 20.5402 5.09793 19.7211 4.27881C18.9019 3.45969 17.791 2.99951 16.6326 2.99951C15.4741 2.99951 14.3632 3.45969 13.5441 4.27881L12.0001 5.82281" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        Copy invite link
                                    </p>
                                </div>
                                {this.state.isClickedNext && <AlertBox icon={false} severity="error" onClose={() => { this.setState({ isClickedNext: false }) }}>Please click the Send invite button</AlertBox>}
                                {this.state.alertVisible &&
                                    <CustomAlert />
                                }
                                {this.state.copiedlink && (
                                    <div className="copyInviteAlert">
                                        <Alert variant="success"
                                            onClose={() => { this.setState({ showalert: false, copiedlink: false }) }} dismissible>
                                            Copied to clipboard
                                        </Alert>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <div className="row m-0">
                        <div className="footer__left col-md-4 col-sm-4 col-4 text-center p-0">
                            <p>
                                <Link to="/onboarding/selectparent" onClick={() => this.props.setIsUserProfileSuccess(false)} className="footer__left__cta">
                                    <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M5.80474 0.195262C5.54439 -0.0650874 5.12228 -0.0650874 4.86193 0.195262L0.195262 4.86193C-0.0650873 5.12228 -0.0650873 5.54439 0.195262 5.80474L4.86193 10.4714C5.12228 10.7318 5.54439 10.7318 5.80474 10.4714C6.06509 10.2111 6.06509 9.78894 5.80474 9.52859L1.60948 5.33333L5.80474 1.13807C6.06509 0.877722 6.06509 0.455612 5.80474 0.195262Z" fill="#1B1C1E" />
                                    </svg>
                                    <span>Go back</span>
                                </Link>
                            </p>
                        </div>
                        <div className="footer__center col-md-4 col-sm-4 col-4 text-center">
                            <p>8 out of 8 steps</p>
                        </div>
                        <div className="footer__right col-md-4 col-sm-4 col-4 text-center">


                            <Link className="footer__cta" to="#" onClick={(e) => this.handleNext(e)}  >Next step</Link>


                        </div>
                    </div>
                </div>


                <Footer reverseLink={"/onboarding/selectadvisor/child"}>
                    <Link className="footer__cta" to="#" onClick={(e) => this.handleNext(e)}>Go to dashboard
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.92893 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.0222 21.4135 8.08879 20.3147 6.4443C19.2159 4.79981 17.6541 3.51808 15.8268 2.76121C13.9996 2.00433 11.9889 1.8063 10.0491 2.19215C8.10929 2.578 6.32746 3.53041 4.92893 4.92893" strokeWidth="2" className="rolestrokes" />
                            <path d="M15 12L15.7809 11.3753L16.2806 12L15.7809 12.6247L15 12ZM3 13C2.44771 13 2 12.5523 2 12C2 11.4477 2.44771 11 3 11V13ZM11.7809 6.3753L15.7809 11.3753L14.2191 12.6247L10.2191 7.6247L11.7809 6.3753ZM15.7809 12.6247L11.7809 17.6247L10.2191 16.3753L14.2191 11.3753L15.7809 12.6247ZM15 13H3V11H15V13Z" className="rolefills" />
                        </svg>
                    </Link>
                </Footer>

            </>
        );
    }
}

const mapStateToProps = ({ userProfileData, studentData, commonData }) => {
    const { loading, copyInviteFlag } = commonData
    const { isUserOnboarded, userProfile } = userProfileData;
    const { copyInvitelinkSucess, copyInvitelink, getSpouseList, isInviteSentSucess, copylinkerror } = userProfileData;
    return { isUserOnboarded, copyInvitelinkSucess, userProfile, copyInvitelink, copyInviteFlag, loading, getSpouseList, isInviteSentSucess, copylinkerror }
};

export default connect(mapStateToProps, { setUserOnboard, setIsUserProfileSuccess, CopyInviteLinkstatus, SentInviteParentstatus, getUserProfile, SentInviteSpouse, setCopyInviteFlag, GetInviteSpouse, CopyInviteLink, clearMessage, CopyInviteErrorstatus })(withRouter(Index));