import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Typography, CardContent, Card } from '@mui/material';
import "./count.less";

const Index = (props) => {
    const navigate = useNavigate();
    let buttonState;
    let navigatePath;

    if (props?.data?.type === "advisor") {
        buttonState = "new advisor connection requests"
        navigatePath = "/parent/mynetwork"
    }
    if (props?.data?.type === "student") {
        buttonState = "new student connection requests"
        navigatePath = "/parent/mynetwork"

    }
    else if (props?.data?.type === "milestone updates") {
        console.log(props?.data?.milestone[0]?.planId, props?.data?.milestone[0]?.courseId, "idsection")
        buttonState = "new updates from Ryan"
        navigatePath = `/parent/mywork/plan/${props?.data?.milestone[0]?.planId}/milestones/${props?.data?.milestone[0]?.courseId}`;
    }
    else if (props?.data?.type === "course updates") {
        buttonState = "new course updates from Ryan"
        // navigatePath = `/parent/mywork/plan/${props?.data?.milestone[0]?.planId}/milestones/${props?.data?.milestone[0]?.courseId}`;
    }
    else if (props?.data?.type === "activity updates") {
        buttonState = "new activity updates from Ryan"
        // navigatePath = `/parent/mywork/plan/${props?.data?.milestone[0]?.planId}/milestones/${props?.data?.milestone[0]?.courseId}`;
    }

    const handleCardClick = () => {
        if (navigatePath) {
            navigate(navigatePath);
        }
    };
    console.log(props?.data, "sdgsbhcgv")
    return (
        <>
            <div className="dashboard-top-parent" onClick={handleCardClick} >
                <Card className="dashCountCard" variant="outlined" >
                    <CardContent>
                        <Typography variant="h6" component="div" className="totalcount"> {props?.data?.count}</Typography>
                        <Typography className="countFrom">{buttonState}</Typography>
                    </CardContent>
                </Card>
            </div>
        </>
    );
};

export default Index