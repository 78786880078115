export const SET_ISPARENTGOAL_SUCCESS_STATUS = "SET_ISPARENTGOAL_SUCCESS_STATUS"
export const GET_PARENT_GOALS_STATUS = "GET_PARENT_GOALS_STATUS"
export const GET_PARENT_GOALS = "GET_PARENT_GOALS"
export const GET_PARENT_GOALS_STUDENT = "GET_PARENT_GOALS_STUDENT"
export const GET_PARENTS_GOALS_STATUS ="GET_PARENTS_GOALS_STATUS"
export const GET_STUDENT_BY_ID_PROFILE ="GET_STUDENT_BY_ID_PROFILE"
export const SET_PARENT_GOAL_SUCCESS = "SET_PARENT_GOAL_SUCCESS"
export const GET_PARENT_RECOMMENDED = "GET_PARENT_RECOMMENDED"
export const GET_PARENT_RECOMMENDED_STATUS = "GET_PARENT_RECOMMENDED_STATUS"
export const GET_ADVISOR_COUNT_UPDATES = "GET_ADVISOR_COUNT_UPDATES"



