import React from "react";
import {Outlet} from "react-router-dom";
import "../assets/css/dashboard.less";
// import Sidebar from "../layouts/Block/Sidebar";
import Sidebar from "../componentsNew/Advisor/Sidebar";

import ChatBox from "../components/ChatBox";
import Calendar from "../components/Calendar";
import { connect } from "react-redux";
import axios from 'axios';
import {  getUserData } from "../utils/AuthUtils";
import MuiAlert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import { getUserProfile, getUserRoles,  getUniversities, getProfessions, getGender, getCountry, getStatus, 
    getActivity, getCourse, getStudentGrades, clearMessage,getChildProfile,getPhasetime, getnotification,
    manageProfileServices, setManageProfileServicesStatus} from "../redux/actions";
import { withRouter } from "../redux/store/navigate";

let style = {
    zIndex: 99, 
    position: "-webkit-sticky",
    position: "sticky", 
    top: 10,
    backgroundColor: '#EF6C00E5',
}
class DashboardLayout extends React.Component {
    componentDidMount(){
        this.props.clearMessage()
        if(getUserData('accessToken')){
            axios.defaults.headers.common['Authorization'] = "Bearer " + getUserData('accessToken');
            this.props.getUserRoles();
            this.props.getUserProfile();
            this.props.getChildProfile();
            // this.props.getUniversities();
            this.props.getProfessions();
            this.props.getGender();
            this.props.getCountry();
            this.props.getStatus();
            this.props.getActivity();
            this.props.getCourse();
            this.props.getStudentGrades();
            let payload = {
                "pageNo":1,
                "limit":50
            }
            this.props.getnotification(payload)
        }
    }
    componentDidUpdate(){
        if (this.props.userProfile?.isOnboarded !== undefined && !this.props.userProfile.isOnboarded) {
            this.props.navigate('/onboarding/roles');
        }
        if(this.props?.manageServiceStatus){
            this.props.getUserProfile();
            this.props.setManageProfileServicesStatus(false);
        }
    }
    convertTimeFormat(timeStr) {
		let parts = timeStr.split(':');
		let newTime = parts.slice(0, 2).join(':');
		return newTime;
	}
    turnServicesOn(){
		let {mondayTimesheet, tuesdayTimesheet, wednesdayTimesheet, thursdayTimesheet, fridayTimesheet, saturdayTimesheet, sundayTimesheet} = this?.props?.userProfile?.advisorProfile?.advisorWorkingHours;

        let payLoad = {
            isServicesEnabled: !this?.props?.userProfile?.advisorProfile?.isServicesEnabled
        }
        payLoad.mondayAvailableTimes = mondayTimesheet?.map(date => {return {startTime: this.convertTimeFormat(date?.startingTime),endTime:this.convertTimeFormat(date?.closingTime)}})
		payLoad.tuesdayAvailableTimes = tuesdayTimesheet?.map(date => {return {startTime: this.convertTimeFormat(date?.startingTime),endTime:this.convertTimeFormat(date?.closingTime)}})
		payLoad.wednesdayAvailableTimes = wednesdayTimesheet?.map(date => {return {startTime: this.convertTimeFormat(date?.startingTime),endTime:this.convertTimeFormat(date?.closingTime)}})
		payLoad.thursdayAvailableTimes = thursdayTimesheet?.map(date => {return {startTime: this.convertTimeFormat(date?.startingTime),endTime:this.convertTimeFormat(date?.closingTime)}})
		payLoad.fridayAvailableTimes = fridayTimesheet?.map(date => {return {startTime: this.convertTimeFormat(date?.startingTime),endTime:this.convertTimeFormat(date?.closingTime)}})
		payLoad.saturdayAvailableTimes = saturdayTimesheet?.map(date => {return {startTime: this.convertTimeFormat(date?.startingTime),endTime:this.convertTimeFormat(date?.closingTime)}})
		payLoad.sundayAvailableTimes = sundayTimesheet?.map(date => {return {startTime: this.convertTimeFormat(date?.startingTime),endTime:this.convertTimeFormat(date?.closingTime)}})
		
        console.log(payLoad);
        this.props?.manageProfileServices(payLoad);
    }
    render(){
        const Alert = React.forwardRef(function Alert(props, ref) {
            return <MuiAlert style={style} elevation={8} ref={ref} variant="filled" {...props} />;
        });
        
        const action = (
            <Button style={{color: "#fff", border: '1px solid #fff', textTransform: "capitalize"}} variant="outlined" size="small" onClick={() => this.turnServicesOn()}>
                Turn On
            </Button>
        );
        return (
            <div>
                <div id="dashboard-layout">
                    <Sidebar notification= {this.props.notificationdata}/>
                    <div className="center-wrap">
                        {this?.props?.userProfile && this?.props?.userProfile?.advisorProfile?.isServicesEnabled === false &&
                            <Alert className="serviceInfoNotification" severity="warning" action={action}>
                                You will not be available for other users unless you turn on your services.
                            </Alert>
                        }
                        {this.props.userProfile && <Outlet/>}
                    </div>
                    <Calendar />
                    <ChatBox />
                </div>
            </div>
        )
    }
}
//export default DashboardLayout;
const mapStateToProps = ({userProfileData, commonData, advisorData}) => {
    const {isUserOnboarded, userProfile} = userProfileData
    const {notificationdata} = commonData;
    const {manageServiceStatus} = advisorData;
    return {isUserOnboarded, userProfile, notificationdata, manageServiceStatus}
};
  
export default connect(mapStateToProps, { getUserProfile, getUserRoles,  getUniversities, getProfessions, getGender, getCountry,
    getActivity, getCourse, getStudentGrades, getStatus, clearMessage,getChildProfile,getPhasetime, getnotification, 
    manageProfileServices, setManageProfileServicesStatus })(withRouter(DashboardLayout));