
import {USER_PROFILE_SUCCESS, USER_PROFILE_DATA, USER_ROLE_SAVED, SET_PROFILE_SAVED_STATUS, USER_CHILD_PROFILE_SUCCESS,
  VERIFY_INVITES_STATUS_DATA, USER_PASSWORD_UPDATE, USER_ONBOARDED, SET_CHILD_PROFILE_SAVED_STATUS, 
  SET_CHILD_EDIT_PROFILE_SAVED_STATUS, SENT_INVITE_STATUS, VERIFY_INVITES, VERIFY_INVITES_STATUS, COPY_INVITE_LINK, UPDATE_GENERAL_INFO,
   UPDATE_CURRENT_STATUS, SET_PROFILE_SAVED_STATUS_ERROR,
  GET_INVITE_SPOUSE, GET_INVITE_PARENT_STATUS, USER_PASSWORD_UPDATE_ERROR, PROFILE_PICTURE_UPDATE_SUCCESS,
   UPDATE_CURRENT_STATUS_ERROR, UPDATE_GENERAL_INFO_ERROR, USER_PROFILE_SUCCESS_STATUS,GET_INVITE_PARENT,COPY_INVITE_ERROR_STATUS,
  SET_CHILD_PROFILE_ERROR_STATUS, USER_PARENT_PROFILE_SUCCESS, PROFILE_PICTURE_SWITCH_SUCCESS,COPY_INVITE_LINK_STATUS,  SENT_INVITE_ERROR_STATUS, SET_CHILD_EDIT_PROFILE_ERROR_STATUS, USER_CHILD_PROFILE_STATUS, DISCONNECT_USER, GET_ADVISOR_RECOMMENDED, PROFILE_PICTURE_SWITCH_DELETE
  ,DISCONNECTED_FRIEND_SWITCH, USER_CHILD_PROFILE_USER_ACTIVITY, STUDENT_COURSE_ACTIVITY_STATUS, STUDENT_PUBLIC_COURSE_ACTIVITY_DATA,} from "../constants/ProfileActionTypes";

const INIT_STATE = {
  isRoleSuccess: false,
  isProfileSuccess: false,
  isChildProfileSucess: false,
  userProfile: {},
  isPasswordUpdate: false,
  isUserOnboarded: false,
  isInviteSentSucess: false,
  getSpouseList: [],
  isInvitelink: '',
  copyInvitelinkSucess: false,
  copyInvitelink: '',
  inviteProfile: {},
  childprofile: [],
  sentinviteerror: false,
  getchildstatus: false,
  parentProfile: [],
  isSetGeneralInfoSuccess: false,
  isSetCurrentStatusError: false,
  isSetGeneralInfoError: false,
  editChildProfileSucess: false,
  isCreateChildError: false,
  isEditChildError: false,
  isPicUpload: false,
  copyinvitestatus: false,
  isDisconnectUser : false,
  copylinkerror :false,
  recommendedAdvisor : [],
  isDisconnectFriend : false,
  childDetails : [],
  isPublicCourseActivityLoaded: false,
  publicStudentCoursesActivities: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case USER_PROFILE_SUCCESS: {
      return {
        ...state,
        userProfile: action.payload,
      };
    }
    case USER_ROLE_SAVED: {
      return {
        ...state,
        isRoleSuccess: true,
      };
    }
    case PROFILE_PICTURE_UPDATE_SUCCESS: {
      return {
        ...state,
        isProfilePicUploaded: action.payload,
      };
    }
    case SET_PROFILE_SAVED_STATUS: {
      return {
        ...state,
        isProfileSuccess: action.payload,
      };
    }
    case UPDATE_GENERAL_INFO: {
      return {
        ...state,
        isSetGeneralInfoSuccess: action.payload,
      };
    }
    case UPDATE_GENERAL_INFO_ERROR: {
      return {
        ...state,
        isSetGeneralInfoError: action.payload,
      };
    }
    case UPDATE_CURRENT_STATUS: {
      return {
        ...state,
        isSetCurrentStatusSuccess: action.payload,
      };
    }
    case UPDATE_CURRENT_STATUS_ERROR: {
      return {
        ...state,
        isSetCurrentStatusError: action.payload,
      };
    }
    case USER_PASSWORD_UPDATE: {
      return {
        ...state,
        isPasswordUpdate: action.payload,
      };
    }
    case USER_PASSWORD_UPDATE_ERROR: {
      return {
        ...state,
        isPasswordUpdateerror: action.payload,
      };
    }
    case USER_ONBOARDED: {
      return { ...state, isUserOnboarded: true };
    }
    case SET_CHILD_PROFILE_SAVED_STATUS: {
      return {
        ...state,
        isChildProfileSucess: action.payload,
      };
    }
    case SET_CHILD_EDIT_PROFILE_SAVED_STATUS: {
      return {
        ...state,
        editChildProfileSucess: action.payload,
      }
    }
    case SENT_INVITE_STATUS: {
      return {
        ...state,
        isInviteSentSucess: action.payload,
      };
    }
    case GET_INVITE_SPOUSE: {
      return {
        ...state,
        getSpouseList: action.payload,
      };
    }

    case VERIFY_INVITES: {
      return {
        ...state,
        isInvitelink: true,
      };
    }
    case VERIFY_INVITES_STATUS: {
      return {
        ...state,
        isInvitelink: action.payload,
      };
    }
    case VERIFY_INVITES_STATUS_DATA: {
      return {
        ...state,
        inviteProfile: action.payload,
      };
    }
    case COPY_INVITE_LINK: {
      return {
        ...state,
        copyInvitelink: action.payload,
        copyInvitelinkSucess: true,
      };
    }
    case USER_CHILD_PROFILE_SUCCESS: {
      return {
        ...state,
        childprofile: action.payload,
      }
    }
    case GET_INVITE_PARENT: {
      return {
        ...state,
        getparentlist: action.payload,
      }
    }
    case GET_INVITE_PARENT_STATUS: {
      return {
        ...state,
        getparentliststatus: action.payload,
      }
    }
    case SENT_INVITE_ERROR_STATUS: {
      return {
        ...state,
        sentinviteerror: action.payload,
      }
    }
    case USER_CHILD_PROFILE_STATUS: {
      return {
        ...state,
        getchildstatus: action.payload,
      }
    }
    case USER_PARENT_PROFILE_SUCCESS: {
      return {
        ...state,
        parentProfile: action.payload,
      }
    }
    case SET_PROFILE_SAVED_STATUS_ERROR: {
      return {
        ...state,
        isProfileSuccessError: action.payload,
      }
    }
    case USER_PROFILE_SUCCESS_STATUS: {
      return {
        ...state,
        getprofilestatus: action.payload,
      }
    }
    case SET_CHILD_PROFILE_ERROR_STATUS: {
      return {
        ...state,
        isCreateChildError: action.payload,
      }
    }

    case  SET_CHILD_EDIT_PROFILE_ERROR_STATUS: {
      return {
        ...state,
        isEditChildError: action.payload,
      }
    }

    case PROFILE_PICTURE_SWITCH_SUCCESS: {
      return {
        ...state,
        isPicUpload: action.payload,
      }
    }
    case COPY_INVITE_LINK_STATUS: {
      return{
        ...state,
        copyinvitestatus: action.payload,
      }
    }
    case DISCONNECT_USER: {
      return{
        ...state,
        isDisconnectUser: action.payload,
      }
    }
    case DISCONNECTED_FRIEND_SWITCH: {
      return{
        ...state,
        isDisconnectFriend: action.payload,
      }
    }
    case COPY_INVITE_ERROR_STATUS:{
      return{
        ...state,
        copylinkerror: action.payload
      }
    }
    case USER_CHILD_PROFILE_USER_ACTIVITY: {
      return{
        ...state,
        childDetails: action.payload
      }
    }
    case STUDENT_COURSE_ACTIVITY_STATUS: {
      return{
        ...state,
        isPublicCourseActivityLoaded: action.payload
      }
    }
    case STUDENT_PUBLIC_COURSE_ACTIVITY_DATA: {
      return{
        ...state,
        publicStudentCoursesActivities: action.payload
      }
    }
    default:
      return state;
  }
}
