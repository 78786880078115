import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { Sidebar } from "../../componentsNew/Student/Sidebar";
import ChatBox from "../../components/ChatBox";
import { connect } from "react-redux";
import {
    getUserProfile, getUserRoles, getUniversities, getProfessions, getGender, getCountry,
    getStatus, clearMessage, getCourse, getCourses, getActivities, getActivity, getStudentGrades,
    getPhasetime, getInviteAdvisor, getGraduationyear,ConnectParent,getUserGoals,getPlanStatus,getnotification,
    listScheduledEvents,
} from "../../redux/actions";
import { withRouter } from "../../redux/store/navigate";
const DefaultLayout = (props) => {
    useEffect(() => {
        props.getUserRoles();
        props.getProfessions();
        props.getActivities();
        props.getGender();
        props.getStatus();
        props.getUserProfile();
        props.getCountry();
        props.getActivity();
        props.getCourse();
        props.getActivities();
        props.getCourses();
        props.getStudentGrades();
        props.getGraduationyear();
        props.ConnectParent();
        props.getUserGoals();
        props.getPlanStatus();
        let payload = {
            "pageNo":1,
            "limit":50
        }
        props.getnotification(payload)
    }, [])
    const userProfile = useSelector((state) => state.userProfileData.userProfile);
    const additionalStyle = {};
    if (props.compact) {
        additionalStyle.padding = "0px";
    }
    return (
        <div id="layoutSidenav">
            <Sidebar userProfile={props?.userProfile} notification={props?.notificationdata} />
            <div className="center-wrap-default" style={additionalStyle}>
                {userProfile && <Outlet />}
            </div>
            {!props.compact && <ChatBox />}
        </div>
    );
};
const mapStateToProps = ({ userProfileData,commonData }) => {
    const { isUserOnboarded, userProfile } = userProfileData;
    const { notificationdata } = commonData;
    return { isUserOnboarded, userProfile,notificationdata }
};

export default connect(mapStateToProps, {
    getUserProfile, getUserRoles, getUniversities, getProfessions, getGender, getCountry,
    getStatus, clearMessage, getCourse, getCourses, getActivities, getActivity, getStudentGrades,
    getPhasetime, getInviteAdvisor, getGraduationyear,ConnectParent,getUserGoals,getPlanStatus,getnotification,
    listScheduledEvents,
})(withRouter(DefaultLayout));


