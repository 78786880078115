import React, { useEffect, useState } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from "@fullcalendar/interaction"
import timeGridPlugin, { TimeColsView } from "@fullcalendar/timegrid";
import '../../../assets/css/schedule.less';
import 'bootstrap/dist/css/bootstrap.min.css';
import momentPlugin from '@fullcalendar/moment';
import moment from "moment";
import Select, { components } from "react-select";
import { getplanAdvisor, clearMessage, listScheduledEvents, setListScheduledEventStatus, confirmScheduledEventStatus, ConnectParent, getConnectedAdvisors, bookSession, bookPaidSession, bookSessionStatus, bookPaidSessionErrorStatus, bookPaidSessionStatus, setConnectedAdvisorStatus, rescheduleEventStatus } from "../../../redux/actions"
import { connect } from "react-redux";
import { withRouter } from "../../../redux/store/navigate";
import DetailPopup from "./Popup/SessionDetailPopu"
import CustomAlert from '../../../components/CustomAlert';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { Button } from '@mui/material';
import BookSessionPopup from "../../../componentsNew/Common/BookSession/BookSessionPopup";
import Loader from '../../../components/Loader';
import Backdrop from '@mui/material/Backdrop';
import { useNavigate } from 'react-router-dom';
import { convertToUTCString, convertToUTCTimeformat, convertUTCTodateTimeZone, convertUtcToEventTime, GetCurrentTimezone } from '../../../utils/CommonUtils';
import dayjs from 'dayjs';

const selectStyle = {
    option: (styles, { data, isDisabled, isFocused, isSelected, }) => {
        return {
            ...styles,
            fontWeight: isFocused ? '600' : '400',
            color: isFocused ? 'rgba(0, 0, 0, 1)' : 'rgba(92, 92, 92, 1)',
            backgroundColor: "white",
            backgroundColor: isFocused ? "white" : "white",
            cursor: isDisabled ? 'not-allowed' : 'default',
        };
    },
}
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
        href="#"
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
        <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.8751 0.999531L5.9951 4.87953L2.1151 0.999531C1.7251 0.609531 1.0951 0.609531 0.705098 0.999531C0.315098 1.38953 0.315098 2.01953 0.705098 2.40953L5.2951 6.99953C5.6851 7.38953 6.3151 7.38953 6.7051 6.99953L11.2951 2.40953C11.6851 2.01953 11.6851 1.38953 11.2951 0.999531C10.9051 0.619531 10.2651 0.609531 9.8751 0.999531Z" fill="white" />
        </svg>
    </a>
));
const CalendarViews = [
    { value: 'dayGridMonth', label: 'Month' },
    { value: 'timeGridWeek', label: 'Week' },
    { value: 'timeGridDay', label: 'Day' }
];

function renderDayCell(dayCellContent) {
    return (
        <>
            <div className="daycell-head">
                <div>  {dayCellContent.dayNumberText}</div>
            </div>
        </>
    );
}


const Index = (props) => {
    let navigate = useNavigate();
    const [loading, setloading] = useState(false)
    const [bookshow, setbookshow] = useState(false);
    const [showdetails, setshowdetails] = useState(false)
    const [eventData, setEventData] = useState({})
    const [differentUserIds, setDifferentUserIds] = useState([]);
    const [AdvisorProfile, setAdvisorProfile] = useState([])
    const [TimeSession, setTimeSession] = useState([]);
    const [advisorid, setAdvisorid] = useState("");
    const [advisorName, setAdvisorName] = useState("");
    const [paidSession, setPaidSession] = useState(null);
    
    let { CarouselData, CarouselOption } = props;

    useEffect(() => {
        props.listScheduledEvents();
        props.ConnectParent();
        props?.getConnectedAdvisors()
        setloading(true)
    }, [])
    useEffect(() => {
        if (props?.connectedAdvisorsStatus && props?.listScheduledEventsStatus) {
            props?.setConnectedAdvisorStatus(false)
            setListScheduledEventStatus(false)
            setloading(false)
        }
    }, [props?.connectedAdvisors, props?.scheduledEvents, props?.listScheduledEventsStatus])

    useEffect(() => {
        const getDifferentUserIds = () => {
            const userProfileIds = props.userProfile.id;

            const differentIds = [];

            props.scheduledEvents.forEach((event) => {
                event.userInvitationStatuses.forEach((invitationStatus) => {
                    if (!userProfileIds.includes(invitationStatus.userId)) {
                        differentIds.push(invitationStatus.userId);
                    }
                });
            });

            return differentIds;
        };

        if (Array.isArray(props.scheduledEvents) && props.userProfile && props.userProfile.id) {
            const differentIds = getDifferentUserIds();
            setDifferentUserIds(differentIds);
        }
        if (props?.reScheduledEvents) {
            props?.rescheduleEventStatus(false);
            props.listScheduledEvents();
        }
    }, [props.scheduledEvents, props.userProfile, props?.reScheduledEvents]);

    useEffect(() => {
        if (differentUserIds && differentUserIds?.length > 0) {
            const removeDuplicates = (arr) => {
                return arr.filter((item, index) => arr.indexOf(item) === index);
            };

            const uniqueArray = removeDuplicates(differentUserIds);
            let payload = {
                userIds: uniqueArray
            }
            props?.getplanAdvisor(payload)
        }

    }, [differentUserIds])

    const handleDetailShow = (arg) => {
        const { title, start, end, extendedProps } = arg.event;
        //  dayjs(start)?.format('hh:mm A')
        const Starttime = start;
        const Endtime = end;
        setEventData({
            ['title']: title,
            ['start']: Starttime,
            ['end']: Endtime,
            ...extendedProps.details,
            ...extendedProps,
            ['AdvisorData']: extendedProps?.details?.eventParticipents
        })
        setshowdetails(true)
    }
    const handleDetailHide = () => {
        setshowdetails(false)
    }

    let events = props.scheduledEvents?.map(event => {
        let eventParticipents = [];
        let advisorNames = event?.userIds?.map(userId => {
            let advisor = props?.advisorlistdata?.length > 0 && props?.advisorlistdata?.find(advisor => advisor?.id === userId);
            if (advisor && advisor?.currentRole === "advisor") {
                eventParticipents.push(advisor);
            }
            return advisor ? advisor?.fullName : "";
        });
        return ({
            title: advisorNames ? advisorNames?.filter(name => name !== "").join(', ') : "Advisor Name",
            eventParticipents: eventParticipents?.filter(data => data) || [],
            start: convertUTCTodateTimeZone(event.eventTime, props?.userProfile?.timezone)?.format('YYYY-MM-DD HH:mm:ss'),
            end: convertUTCTodateTimeZone(event.eventClosingTime, props?.userProfile?.timezone)?.format('YYYY-MM-DD HH:mm:ss'),
            details: {
                id: event?.id,
                createdBy: event?.createdBy,
                eventType: event?.eventType,
                eventDuration: event?.eventDuration,
                eventStatus: event?.eventStatus,
                meetingDescription: event?.meetingDescription,
                meetingLocation: event?.meetingLocation,
                meetingPassword: event?.password
            },
            invitationStatus: event?.userInvitationStatuses
        })
    })

    var calendar = new FullCalendar();

    const selectStyle = {
        menu: (provided) => ({
            ...provided,
            zIndex: 99,
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected, }) => {
            return {
                ...styles,
                fontWeight: isFocused ? '600' : '400',
                color: isFocused ? 'rgba(2, 2, 2, 0.8)' : 'rgba(39, 39, 39, 0.8)',
                backgroundColor: "rgba(255, 255, 255, 1)",
                backgroundColor: isFocused ? "rgba(255, 255, 255, 1)" : "rgba(255, 255, 255, 1)",
                cursor: isDisabled ? 'not-allowed' : 'default',
            };
        },
    }

    const handleSelect = (valObj, action) => {
        if (action === "clear") {
            return;
        } else {
            calendar.current.getApi().changeView(valObj.value);
        }
    }

    const renderEventContent = (arg) => {
        const { start } = arg.event;
        const currentDate = GetCurrentTimezone(props?.userProfile?.timezone)
        const isPastEvent = (currentDate > dayjs(start).format('YYYY-MM-DDTHH:mm:ss'));
        let eventStatus = arg.event?.extendedProps?.invitationStatus?.find((status) => status?.userId === props.userProfile?.id)
        const backgroundColor = eventStatus?.status === "complete" ? '#cdcdcd' : eventStatus?.status === "pending" ? '#00AE31' : eventStatus?.status === "rejected" ? '#FF6565' : eventStatus?.status === 'reScheduled' ? "#FDB560" : '#3297FF';

        let eventStyle = {};
        if (isPastEvent) {
            eventStyle['border'] = `.3px solid ${backgroundColor}`;
            eventStyle['backgroundColor'] = '#fff';
            eventStyle['color'] = backgroundColor;
        } else {
            eventStyle['backgroundColor'] = backgroundColor;
        }
        const eventTitle = arg.event?.extendedProps?.details?.eventType === "freeSession" ? `${arg.event.title} - Consultation session ` : `${arg.event.title} - Paid session `
        let TimeZone = props?.userProfile?.timezone
        return (
            <div className="event" style={eventStyle}>
                <div className="eventMonthview">
                    <div className="fc-event-time" style={isPastEvent ? { color: backgroundColor } : {}} title={moment(arg.event.start)?.format("h:mm A")}>
                        {moment(arg.event.start)?.format("h:mm A")}
                    </div>
                    <div className="fc-event-title" style={isPastEvent ? { color: backgroundColor } : {}} title={eventTitle}>{eventTitle}</div>
                </div>
                <div className="eventTimeGridview">
                    <div className="fc-event-time" style={isPastEvent ? { color: backgroundColor } : {}} title={moment(arg.event.start)?.format("h:mm A")}>
                        {moment(arg.event.start)?.format("h:mm A")}
                    </div>
                    <div className="fc-event-title" style={isPastEvent ? { color: backgroundColor } : {}} title={eventTitle}>{eventTitle}</div>
                </div>
            </div>
        );
    };
    const OpenBookSession = (e, data) => {
        props?.clearMessage()
        e.preventDefault()
        setPaidSession(getNoOfPaidSessions(data) ? true : null)
        setTimeSession(data)
        setAdvisorid(data?.userInfo?.id)
        setAdvisorName({ label: data?.userInfo?.fullName, value: data?.userInfo?.id })
        setbookshow(true)
    }

    const CloseBookSession = () => {
        props?.clearMessage()
        setbookshow(false)
        // setLoading(false)
    }
    function convertTo24HourFormat(time12h) {
        const [time, modifier] = time12h.split(' ');
        let [hours, minutes] = time.split(':');
        hours = parseInt(hours, 10);
        if (modifier === 'PM' && hours !== 12) {
            hours += 12;
        } else if (modifier === 'AM' && hours === 12) {
            hours = 0;
        }
        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.padStart(2, '0');
        return `${formattedHours}:${formattedMinutes}:00`;
    }
    const SessionBook = (data, timeString) => {
        const startTime = timeString.split(' - ')[0];
        const endTime = timeString.split(' - ')[1];
        const moment = require('moment');
        const selectedDate = moment(data.Selectdate, "YYYY-MM-DD").format("YYYY-MM-DD");
        let currentTime = selectedDate + ' ' + convertTo24HourFormat(startTime)

        let payload = {
            "advisorId": advisorid,
            "eventTime": currentTime,
            "meetingLocation": data?.Location,
            "meetingDescription": data?.Description,
            "studentId": localStorage.getItem("parentViaChild"),
            "password": data?.password
        }
        if (data?.package) {
            payload["eventDuration"] = data?.package?.eventDuration;
            payload["packageId"] = data?.package?.value;
            props?.bookPaidSession(payload)
        } else {
            payload["eventDuration"] = 30;
            props?.bookSession(payload)
        }
    }

    useEffect(() => {
        if (props?.sessionBooked) {
            // setLoading(false)
            CloseBookSession()
            props?.bookSessionStatus(false)
            props.listScheduledEvents()
        }
        if (props?.booksessionerror) {
            // setLoading(false)
            CloseBookSession()
            props?.bookSessionStatus(false)
            props.listScheduledEvents()
        }
    }, [props?.sessionBooked, props?.booksessionerror])

    useEffect(() => {
        if (props?.paidSessionBooked) {
            // setLoading(false)
            CloseBookSession()
            props?.bookPaidSessionStatus(false)
            props.listScheduledEvents()
        }
        if (props?.bookPaidSessionError) {
            // setLoading(false)
            CloseBookSession()
            props?.bookPaidSessionErrorStatus(false)
            props.listScheduledEvents()
        }
    }, [props?.paidSessionBooked, props?.bookPaidSessionError])

    useEffect(() => {
        if (props?.sessionBooked) {
            // setLoading(false)
            CloseBookSession()
            props?.bookSessionStatus(false)
            props.listScheduledEvents()
        }
        if (props?.booksessionerror) {
            // setLoading(false)
            CloseBookSession()
            props?.bookSessionStatus(false)
            props.listScheduledEvents()
        }
    }, [props?.sessionBooked, props?.booksessionerror])

    const HandleNavigate = (e) => {
        e.preventDefault();
        navigate('/student/mynetwork')
    }
    const getNoOfPaidSessions = (advisorData) => {
        const subscriptionDetails = advisorData?.subscriptiondetails?.[0];
        return subscriptionDetails ? `(${subscriptionDetails.noOfPaidSessions} remaining)` : '';
    };
    const getNoOffreeSessions = (advisorData) => {
        const subscriptionDetails = advisorData?.userInfo?.advisorProfile?.noOfFreeConsultation
        return subscriptionDetails ? `(${subscriptionDetails} free remaining)` : '';
    }
    return (
        <>
            {loading && <Loader />}
            {<div style={{ "width": "100%", "display": "flex", "justifyContent": "center", }}> {<CustomAlert />}</div>}
            {bookshow &&
                <BookSessionPopup show={bookshow} selectedAdvisor={advisorName} advisorList={props?.connectedAdvisors} paidSession={paidSession} onHide={CloseBookSession} Time={TimeSession} HandleSumit={SessionBook} />
            }
            {showdetails &&
                <DetailPopup show={showdetails} onHide={handleDetailHide} data={eventData} />
            }

            {props?.connectedAdvisors && props?.connectedAdvisorsStatus &&
                <Backdrop className='Backdropsschedule' sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={props?.connectedAdvisors?.length === 0} >
                    <div className='Backdrop__container'>
                        <div className='Backdrop__container__card'>
                            <div className='title_cnt'>
                                <p>You currently have no connected advisors Explore and connect with advisors to book your <b>free</b> session</p>
                                {/* <p>You currently have used all your available sessions Explore and connect with more advisors to book your <b>free</b> session or purchase additional session from your existing advisors</p> */}
                            </div>
                            <div className='btn_cnt'>
                                <p onClick={(e) => HandleNavigate(e)}>
                                    <span>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="11" cy="11" r="6" stroke="#222222" />
                                            <path d="M11 8C10.606 8 10.2159 8.0776 9.85195 8.22836C9.48797 8.37913 9.15726 8.6001 8.87868 8.87868C8.6001 9.15726 8.37913 9.48797 8.22836 9.85195C8.0776 10.2159 8 10.606 8 11" stroke="#222222" stroke-linecap="round" />
                                            <path d="M20 20L17 17" stroke="#222222" stroke-linecap="round" />
                                        </svg>
                                    </span>
                                    Explore Advisors
                                </p>
                            </div>
                        </div>
                    </div>
                </Backdrop>
            }
            <div className="schedule-mobile-top">
                <div className="dashboardtop">
                    <div className="row">
                        <div className="col-md-8 col-sm-11 col-10">
                            <div className="dashboard-top-text">
                                <h2>Schedule</h2>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-1 col-1 Search-content">
                            <div className="mobile-search">
                                <div class="mobile-search-content">
                                    <a href="javascript:void(0)" class="mobile-search-button">
                                        <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14.5046 3.22982C11.3749 0.183809 6.28112 0.183809 3.15144 3.22982C0.0224364 6.2765 0.0224364 11.2335 3.15144 14.2801C5.93851 16.992 10.2792 17.2826 13.4069 15.1649C13.4727 15.468 13.6233 15.7572 13.8655 15.993L18.4233 20.4289C19.0875 21.0741 20.1608 21.0741 20.8216 20.4289C21.4852 19.7832 21.4852 18.7385 20.8216 18.0947L16.2638 13.6575C16.023 13.4237 15.7251 13.2765 15.4137 13.2124C17.5909 10.1677 17.2923 5.9437 14.5046 3.22982ZM13.0656 12.8796C10.729 15.1537 6.92633 15.1537 4.59043 12.8796C2.25521 10.6055 2.25521 6.90511 4.59043 4.631C6.92633 2.35755 10.729 2.35755 13.0656 4.631C15.4022 6.90511 15.4022 10.6055 13.0656 12.8796Z" fill="#1C84EE" />
                                        </svg>
                                    </a>
                                    <input type="text" class="mobile-search-input" placeholder="Search here..." />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="schedule">
                <div className="calendar-outer">
                    <div className="calendar-box">
                        <div className="schedule-head-box">
                            <div className="right">
                                <div className="cal-select-month">
                                    <Select
                                        closeMenuOnSelect={true}
                                        options={CalendarViews}
                                        placeholder={"Month"}
                                        styles={selectStyle}
                                        onChange={(value, { action }, name) => handleSelect(value, action)}
                                    />
                                </div>
                            </div>
                            <div className="left">
                                <div className="top-cal">
                                    {(props?.connectedAdvisors?.length === 1 && events?.length <= 0) &&
                                        <p>You have {props?.connectedAdvisors[0]?.userInfo?.advisorProfile?.noOfFreeConsultation}{" "}free session remaining with {props?.connectedAdvisors && props?.connectedAdvisors[0]?.userInfo?.fullName}</p>
                                    }
                                    <div className="cal-add-btn">
                                        {props?.connectedAdvisors?.length > 1 ? (
                                            <DropdownButton className='Dropdown' id="dropdown-basic-button" title="Book Session" >
                                                {props?.connectedAdvisors?.length > 0 && props?.connectedAdvisors?.map((data, index) => (
                                                    <>
                                                        <Dropdown.Item onClick={(e) => OpenBookSession(e, data)} >{data?.userInfo?.fullName}{" "}{getNoOfPaidSessions(data) ? getNoOfPaidSessions(data) : getNoOffreeSessions(data)}</Dropdown.Item>
                                                    </>
                                                ))}
                                            </DropdownButton>
                                        ) :
                                            <>
                                                {props?.connectedAdvisors?.length > 0 && props?.connectedAdvisors?.map((data,) => (
                                                    <Button className='Booksession-btn' onClick={(e) => OpenBookSession(e, data)}>Book Session</Button>
                                                ))}
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="small-cal">
                            <div className="left">
                                <div className="cal-select-month mt-2">
                                    <Select
                                        closeMenuOnSelect={true}
                                        options={CalendarViews}
                                        placeholder={"Month"}
                                        styles={selectStyle}
                                        onChange={(value, { action }, name) => handleSelect(value, action)}
                                    />
                                </div>
                            </div>
                            <div className="col-md-12 col-sm-12 col-12">
                                {(props?.connectedAdvisors?.length === 1 && events?.length <= 0) &&
                                    <p>You have {props?.connectedAdvisors[0]?.userInfo?.advisorProfile?.noOfFreeConsultation}{" "}free session remaining with {props?.connectedAdvisors && props?.connectedAdvisors[0]?.userInfo?.fullName}</p>
                                }
                                <div className="cal-add-btn">
                                    {props?.connectedAdvisors?.length > 1 ? (
                                        <DropdownButton className='Dropdown' id="dropdown-basic-button" title="Book Session" >
                                            {props?.connectedAdvisors?.length > 0 && props?.connectedAdvisors?.map((data, index) => (
                                                <>
                                                    <Dropdown.Item onClick={(e) => OpenBookSession(e, data)} >{data?.userInfo?.fullName}{" "}{getNoOfPaidSessions(data) ? getNoOfPaidSessions(data) : getNoOffreeSessions(data)}</Dropdown.Item>
                                                </>
                                            ))}
                                        </DropdownButton>
                                    ) :
                                        <>
                                            {props?.connectedAdvisors?.length > 0 && props?.connectedAdvisors?.map((data,) => (
                                                <Button className='Booksession-btn' onClick={(e) => OpenBookSession(e, data)}>Book Session</Button>
                                            ))}
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                        <FullCalendar
                            ref={calendar}
                            customButtons={{
                                myCustomButton: { icon: 'calendar', }
                            }}
                            plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin, momentPlugin]}
                            eventContent={renderEventContent}
                            dayCellContent={renderDayCell}
                            views={{
                                month: {
                                    titleFormat: "MMM['] YYYY",
                                    eventTimeFormat: "h:mm",
                                    eventClassNames: "monthview-event-class",
                                    dayMaxEventRows: 2
                                },
                                week: {
                                    type: "weekgrid",
                                    dayHeaderFormat: "ddd  D",
                                    dayHeaderContent: ({ date }) => {
                                        const fd = date.getDay();
                                        const fdate = date.getDate();
                                        const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
                                        return (
                                            <div>
                                                <div className="week-event-Day">
                                                    {weekdays[fd]}
                                                </div>
                                                <div className="week-event-Date">
                                                    {fdate}
                                                </div>
                                            </div>
                                        );
                                    },
                                    titleFormat: "MMM['] YYYY",
                                    slotDuration: "01:00:00",
                                    slotLabelClassNames: "time-hours",
                                    timeFormat: "H(:mm)",
                                    scrollTime: "09:00:00",
                                    slotLabelFormat: "h  A",
                                    allDaySlot: false,
                                    eventClassNames: "week-view-event-css",
                                    dayHeaderClassNames: "weekview-day-header",
                                    titleRangeSeparator: "-",
                                },
                                day: {
                                    titleFormat: "DD MMM['] YYYY",
                                    allDaySlot: false,
                                    scrollTime: "09:00:00",
                                    slotDuration: "01:00:00",
                                    slotLabelClassNames: "day-slot-class",
                                    slotLabelFormat: "h  A",
                                    type: "daygrid",
                                    eventTimeFormat: "H : mm a",
                                    dayHeaderClassNames: "dayview-header",
                                    slotLaneClassNames: "dayview-slot-class",
                                    eventClassNames: "day-view-event-css",
                                },
                            }}
                            events={events}
                            eventClick={handleDetailShow}
                            themeSystem='bootstrapFontAwesome'
                            height='930px'
                            editable={false}
                            selectable='true'
                            weekNumberCalculation='ISO'
                            titleFormat={{ year: 'numeric', month: 'short' }}
                            headerToolbar={{ left: "title,", center: "prev,myCustomButton,next", right: "" }}
                        // timeZone={props?.userProfile?.timezone}
                        />
                    </div>
                </div>
            </div>

        </>
    )
}

const mapStateToProps = ({ commonData, advisorData, userProfileData, studentData }) => {
    const { scheduledEvents, listScheduledEventsStatus, connectedAdvisors, connectedAdvisorsStatus, paidSessionBooked, bookPaidSessionError, sessionBooked, booksessionerror, reScheduledEvents } = commonData;
    const { eventConfirmationStatus } = advisorData;
    const { advisorlistdata } = studentData
    const { userProfile } = userProfileData;
    return { scheduledEvents, listScheduledEventsStatus, eventConfirmationStatus, userProfile, advisorlistdata, connectedAdvisors, connectedAdvisorsStatus, paidSessionBooked, bookPaidSessionError, sessionBooked, booksessionerror, reScheduledEvents };
};

export default connect(mapStateToProps, { ConnectParent, setListScheduledEventStatus, getplanAdvisor, clearMessage, listScheduledEvents, confirmScheduledEventStatus, getConnectedAdvisors, bookSession, bookPaidSession, bookSessionStatus, bookPaidSessionErrorStatus, bookPaidSessionStatus, setConnectedAdvisorStatus, rescheduleEventStatus })(withRouter(Index));

