import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { Sidebar } from "../../componentsNew/Parent/parentSidebar";
import ChatBox from "../../components/ChatBox";
import UpcommingSession from "../../components/UpCommingSession";
import { Calendar } from "react-calendar";
import CalendarComp from "../../components/Calendar";
import { connect } from "react-redux";
import {
    getUserProfile, getUserRoles, getUniversities, getProfessions, getGender, getCountry,
    getStatus, clearMessage, getCourse, getCourses, getActivities, getActivity, getStudentGrades,
    getPhasetime, getInviteAdvisor, getGraduationyear, ConnectParent, getUserGoals, getPlanStatus, getnotification,
    listScheduledEvents, getChildProfile, getDetailedUserProfile, getGoalsFromStudent, getAdvisorCountUpdates
} from "../../redux/actions";
import { withRouter } from "../../redux/store/navigate";
import { Topbar } from "../../componentsNew/ParentViaChild/parentChildTopbar";
import { getUserData } from "../../utils/AuthUtils";
import axios from 'axios';


export const DashboardLayout = (props) => {
    let userProfile = useSelector((state) => state.userProfileData);
    const callAPIs = () => {
        const { getDetailedUserProfile } = props;
        if (localStorage.getItem("parentViaChild")) {
            let payload = {
                pointOfViewStudentId: localStorage.getItem("parentViaChild"),
                userId: localStorage.getItem("parentViaChild")
            };
            getDetailedUserProfile(payload);
            let getStudentId = {
                "studentId": localStorage.getItem("parentViaChild")
            }
            let studentId = localStorage.getItem("parentViaChild")
            props?.getGoalsFromStudent(getStudentId)
            props?.getAdvisorCountUpdates(studentId)
        }
    };
    useEffect(() => {

        if (getUserData('accessToken')) {
            axios.defaults.headers.common['Authorization'] = "Bearer " + getUserData('accessToken');
            props.getChildProfile()
            props.getUserProfile();

            let payload = {
                "pageNo": 1,
                "limit": 50
            }
            props.getnotification(payload)
            callAPIs()
        }
    }, [])

    useEffect(() => {        
        if (props.userProfile && props.userProfile && props.userProfile.isOnboarded !== undefined && !props.userProfile.isOnboarded) {
            props.navigate('/onboarding/roles');
        }
        if (props?.getchildstatus && props?.childprofile?.length > 0 && localStorage.getItem("parentViaChild") === undefined || localStorage.getItem("parentViaChild") === null ) {
           let ChildId = props?.childprofile[0]?.id
           if(ChildId){
            localStorage.setItem("parentViaChild", ChildId);
            localStorage.setItem("childName", props?.childprofile && props?.childprofile[0]?.fullName);
            callAPIs()
           }
        }
    }, [props.userProfile, props?.getchildstatus, props?.childprofile])

    console.log(props?.getchildstatus , props?.childprofile?.length > 0 , localStorage.getItem("parentViaChild") , "isgetusergoals")

    return (
        <div id="dashboard-layout switchLayout">
            <Sidebar userProfile={props?.userProfile} notification={props?.notificationdata} childprofile={props?.childprofile}
                userImage={props?.userProfile} callAPIs={callAPIs} />
            <div className="center-wrap-switch">
                <div className=''>
                    <Topbar userProfile={props.userProfile?.firstName} name={localStorage.getItem("childName") || props?.childprofile && props?.childprofile[0]?.fullName} />
                </div>

            </div>
            <div className="center-wrap">{userProfile && <Outlet />}</div>
            <div className="right-wrap">
                <div className="minicnt">
                    <Calendar prev2Label="Schedule" />
                </div>
                <div className="upcomecnt">
                    <h5>Upcoming Sessions</h5>
                    <UpcommingSession />
                </div>
            </div>
            <CalendarComp />
            <ChatBox />
        </div>
    );
};
const mapStateToProps = ({ userProfileData, commonData, studentData }) => {
    const { isUserOnboarded, userProfile, childprofile, getchildstatus } = userProfileData;
    const { notificationdata } = commonData;
    const { isgetusergoals, } = studentData;

    return { isUserOnboarded, userProfile, notificationdata, childprofile, isgetusergoals, getchildstatus }
};

export default connect(mapStateToProps, {
    getUserProfile, getUserRoles, getUniversities, getProfessions, getGender, getCountry,
    getStatus, clearMessage, getCourse, getCourses, getActivities, getActivity, getStudentGrades,
    getPhasetime, getInviteAdvisor, getGraduationyear, ConnectParent, getUserGoals, getPlanStatus, getnotification,
    listScheduledEvents, getChildProfile, getDetailedUserProfile, getGoalsFromStudent, getAdvisorCountUpdates
})(withRouter(DashboardLayout));