import React, { Component } from "react";
import { Link } from "react-router-dom";
import Select, { components } from "react-select";
import "../../../../assets/css/common.less";
import { connect } from "react-redux";
import { getUniversities, getProfessions, getParentGoals, getGoalsFromStudentStatus, setIsParentGoalStatus, setParentUserGoals, setParentUserGoalsStudent, getChildProfile, getUserProfile, setIsParentGoalSuccess, clearErrorList, clearMessage, getGoalsFromStudent, getChildProfilestatus } from "../../../../redux/actions";
import { Form } from "react-bootstrap";
import { withRouter } from "../../../../redux/store/navigate";
import CustomAlert from "../../../../components/Alert";
import Loader from "../../../../components/Loader";
import { universityApi } from "../../../../utils/AutoComplete";
import CreatableSelect from 'react-select/creatable';
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import CancelIcon from '@mui/icons-material/Cancel';
import Footer from "../../../../componentsNew/Common/Footer";



const { ValueContainer, Placeholder } = components;
const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>{props.selectProps.placeholder}</Placeholder>
            {React.Children.map(children, child => child && child.type !== Placeholder ? child : null)}
        </ValueContainer>
    );
};

const MultiValueRemove = (props) => {
    return (
        <components.MultiValueRemove {...props} >
            <CancelIcon style={{ "fill": "rgba(34, 34, 34, 0.67)" }} />
        </components.MultiValueRemove>
    );
};

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            dataLoaded: false,
            universities: false,
            universityCheckBox: false,
            professionsCheckBox: false,
            gPACheckBox: false,
            fields: {},
            errors: {},
            universityData: [],
            noOptionLength: "",
            selectedUniversityOption: null,
            selectedProfessionOption: null,
            isload: false,
          
        };
    }



    componentDidMount() {
        this.setState({ loading: true, dataLoaded: false ,  })

        // this.props.getChildProfile();
        // this.props.getParentGoals()

        let getStudentId = {
            "studentId": this.props.childprofile && this.props.childprofile[0]?.id
        }
        if (this.props.childprofile?.length > 0) {
            this.props.getGoalsFromStudent(getStudentId)
        } else if (this.props.childprofile?.length === 0 || this.props.childprofile?.length === undefined) {
            this.props.getParentGoals()


        }
        this.setInitialValues();
        this.props.clearMessage()
        this.props.clearErrorList();
        this.props.getChildProfile()
    }
    componentWillReceiveProps(newProps) {
        this.setState({
            errorList: newProps.errorList, listOfUniversities: newProps.universities,
        });
        if (newProps.getchildstatus === true && this.state.loading === true) {
            this.setState({ loading: false })
        } else if (newProps?.childprofile?.length === 0) {
            this.setState({ loading: false })
        }
    }

    componentDidUpdate() {
        this.setInitialValues();
        if (this.props.isParentGoalSuccess) {
            this.props.navigate('/onboarding/profile/parent');
        }
       
        if (this.props.getchildstatus) {
            if (this.props.childprofile && this.props.childprofile?.length > 0 && this.props.getchildstatus) {
                let getStudentId = { "studentId": this.props.childprofile[0]?.id }
                this.props.getGoalsFromStudent(getStudentId)
                this.props.getChildProfilestatus(false)
                this.setState({ loading: true })
            }
            else if (this.props.childprofile && this.props.childprofile?.length <= 0 && this.props.getchildstatus) {
                this.props.getParentGoals()
                this.props.getChildProfilestatus(false)
                this.setState({ loading: true })
            }
        }
        if (this.props.parentGoal && this.props.isParentGoalStatus) {
            this.setState({ loading: false, dataLoaded: false })
            this.props.getGoalsFromStudentStatus(false)
            this.setInitialValues()
            if(this.props.errorList){
                this.setState({ errorList: this.props.errorList }) 
            }
        }
        if (this.props.parentGoal && this.props.parentsstatus) {
            this.props.setIsParentGoalStatus(false)
            this.setState({ loading: false, dataLoaded: false })
            this.setInitialValues()
            if(this.props.errorList){
                this.setState({ errorList: this.props.errorList }) 
            }
        }

        // condition will call after the handle next
        if (this.props.isParentGoalSuccess) {
            let getStudentId = {
                "studentId": this.props.childprofile && this.props.childprofile[0]?.id
            }
            if (this.props.childprofile?.length > 0) {
                this.props.getGoalsFromStudent(getStudentId)
            } else if (this.props.childprofile?.length === 0 || this.props.childprofile?.length === undefined) {
                this.props.getParentGoals()
            }
            this.props.setIsParentGoalStatus(false)
        }
       
    }

    handleUniversityText = (e) => {
        let { errors, fields, listOfUniversities } = this.state;
        fields['universityName'] = e;

        this.setState({ fields });
        if (e) {
            this.props.getUniversities({ "searchString": e })
        } else {
            this.setState({ listOfUniversities: [] })
        }

    }

    handleSuggestChange = (value) => {
        let { fields, listOfUniversities } = this.state
        fields['universityName'] = value
        this.setState({ fields, listOfUniversities: [] })
    }

    renderSuggestions = () => {
        let { fields, listOfUniversities } = this.state
        if (listOfUniversities?.length === 0) {
            return null;
        }
        return (
            <ul className="px-1 py-2">
                {listOfUniversities?.map((item) => <li className="w-100 py-1 university-item" style={{ cursor: 'pointer', }} onClick={() => this.handleSuggestChange(item.university, 'universityName')}>{item.university}</li>)}
            </ul>

        );
    }
    onMenuOpen = () => {
        this.setState({ openSuggession: true })
    }
    onMenuClose = () => {
        this.setState({ openSuggession: false })
    }
    filterColors = (inputValue) => {
        let { listOfUniversities } = this.state
        listOfUniversities.filter((e) => e.university.toLowerCase().includes(inputValue.toLowerCase()));
        return listOfUniversities.map(item => ({ 'label': item.university, 'value': item.university }))
    };


    handleFocus = () => {
        const { selectedOption } = this.state;
        if (selectedOption && selectedOption.label) {
            this.setState({ inputValue: selectedOption.label });
        }
    };


    setInitialValues() {
        let { universityCheckBox, professionsCheckBox, gPACheckBox, fields, selectedUniversityOption, selectedProfessionOption, universityData } = this.state
        if (!this.state.dataLoaded) {

            if (this.props.userProfile && this.props.parentGoal && this.props.parentGoal.goals) {
                let goalesData = this.props.parentGoal.goals
                if (goalesData && goalesData.length > 0) {
                    universityCheckBox = false;
                    gPACheckBox = false;
                    professionsCheckBox = false;
                    this.refs.universityCheckBox.checked = false;
                    this.refs.gPACheckBox.checked = false;
                    this.refs.professionsCheckBox.checked = false;
                    goalesData && goalesData.map((data, key) => {
                        if (data == "university") {
                            universityCheckBox = true;
                            this.refs.universityCheckBox.checked = true;
                        } else if (data == "weighted" && "unweighted") {
                            gPACheckBox = true;
                            this.refs.gPACheckBox.checked = true;
                        } else if (data == "profession") {
                            professionsCheckBox = true;
                            this.refs.professionsCheckBox.checked = true;
                        }
                    })
                }
                // DATA VALUE MAPPING
                // GPA DATA
                let userProfileData = this.props.parentGoal;
                // fields["GPA"] = userProfileData.desiredGpa ? userProfileData.desiredGpa : "";
                fields["weighted"] = userProfileData.targetWeighted ? userProfileData.targetWeighted : "";
                fields["Unweighted"] = userProfileData.targetUnweighted ? userProfileData.targetUnweighted : "";
                // University DATA
                let interestedUniversities = userProfileData.universities;
                let universityOptionstions = [];
                interestedUniversities !== undefined && interestedUniversities.length > 0 && interestedUniversities.map((university, key) => {
                    var temp = { label: university, value: university }
                    universityOptionstions.push(temp);
                });
                selectedUniversityOption = universityOptionstions;
                // Profession DATA
                let interestedProfession = userProfileData?.interests || userProfileData?.professions;
                let professionOptionstions = [];
                interestedProfession !== undefined && interestedProfession.length > 0 && interestedProfession.map((profession, key) => {
                    var temp = { label: profession, value: profession }
                    professionOptionstions.push(temp);
                });
                selectedProfessionOption = professionOptionstions;
                // DATA VALUE MAPPING
                this.setState({ universityCheckBox, professionsCheckBox, gPACheckBox, selectedUniversityOption, selectedProfessionOption });
                this.setState({ dataLoaded: true, })
                // setTimeout(() => { this.setState({ loading: false })}, 2000);if
            }
            if (this.state.dataLoaded === true) {
                this.setState({ loading: false })
            }
        }

    }

    handleCheckboxChange(e, buttonName) {
        let { universityCheckBox, professionsCheckBox, gPACheckBox, errors } = this.state;
        if (buttonName === "universities") {
            universityCheckBox = e.target.checked
            errors["goal"] = ""
        } else if (buttonName === "profession") {
            professionsCheckBox = e.target.checked
            errors["goal"] = ""
        } else if (buttonName === "GPA") {
            gPACheckBox = e.target.checked
            errors["goal"] = ""
        }
        this.setState({ universityCheckBox, professionsCheckBox, gPACheckBox, errors })
    }

    handleChange(field, e) {
        let { errors } = this.state;
        let SelectedFields = this.state.fields;
        SelectedFields[field] = e.target.value;
        if (e.target.value.length >= 0) {
            errors[field] = "";
        }
        this.setState({ SelectedFields, errors: errors,  errorList : {}  });
    }

    handleSelectChange(options, name) {
        let { errors } = this.state;
        let { selectedUniversityOption, selectedProfessionOption } = this.state;
        switch (name) {
            case "university":
                selectedUniversityOption = options;
                errors['university'] = ""
                break;
            case "professions":
                if (options.length > 10) {
                    errors['profession'] = "Max upto 10 proffession allowed"
                } else {
                    if (options) {

                        if (options.at(-1)?.value?.length > 50 && options.at(-1)?.__isNew__ === true) {
                            errors['profession'] = "Max value upto 50 character"
                        }

                        else if (options.at(-1)?.value?.length < 50 && options.at(-1)?.__isNew__ === true) {
                            selectedProfessionOption = options
                            errors['profession'] = ""
                        }
                        else {
                            selectedProfessionOption = options
                            errors['profession'] = ""
                        }
                    }
                }
                break;
        }
        this.setState({ selectedUniversityOption, selectedProfessionOption });
    }


    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        let { selectedUniversityOption, selectedProfessionOption } = this.state;
        if (!this.state.universityCheckBox && !this.state.professionsCheckBox && !this.state.gPACheckBox) {
            formIsValid = false;
            errors["goal"] = "Please select any goal";
            this.setState({ selectedUniversityOption: null })
            this.setState({ selectedProfessionOption: null })
        }
        if (this.state.universityCheckBox) {
            if (selectedUniversityOption === null) {
                formIsValid = false;
                errors["university"] = "Please select any university";
            }
            else if (selectedUniversityOption.length === 0) {
                formIsValid = false;
                errors["university"] = "Please select any university";
            }

        }
        if (this.state.professionsCheckBox) {
            if (selectedProfessionOption === null) {
                formIsValid = false;
                errors["profession"] = "Please select any profession";
            }
            else if (selectedProfessionOption.length === 0) {
                formIsValid = false;
                errors["profession"] = "Please select any profession";
            }

        }
        // if (this.state.gPACheckBox && fields["GPA"] === undefined) {
        //     formIsValid = false;
        //     errors["GPA"] = "Please enter GPA";
        // }
        // if (this.state.gPACheckBox && typeof fields["GPA"] !== "undefined") {
        //     const decimalcaseRegExp = (/^[1-9]\.\d{1,2}$/);
        //     const digitcaseRegExp = (/^[1-9]$/);
        //     const floatcasenumber = decimalcaseRegExp.test(fields["GPA"]);
        //     const digitcasenumber = digitcaseRegExp.test(fields["GPA"]);
        //     if (!digitcasenumber && !floatcasenumber) {
        //         formIsValid = false;
        //         errors["GPA"] = "Invalid GPA";
        //     }
        //     if (this.state.gPACheckBox && fields["GPA"].length === 0) {
        //         formIsValid = false;
        //         errors["GPA"] = "Please enter GPA";
        //     }
        // }
        if (this.state.gPACheckBox) {
            if (!fields["weighted"]) {
                formIsValid = false;
                errors["weighted"] = "*Required";
            }
            if (!fields["Unweighted"]) {
                formIsValid = false;
                errors["Unweighted"] = "*Required";
            }
            if (fields["weighted"] !== "") {
                if (typeof fields["weighted"] !== "undefined") {
                    const decimalcaseRegExp = /^(?:[1-4](\.\d{1})?|5(\.0{1})?)$/;
                    const digitcaseRegExp = (/^[1-5]$/);
                    const floatcasenumber = decimalcaseRegExp.test(fields["weighted"]);
                    const digitcasenumber = digitcaseRegExp.test(fields["weighted"]);
                    if (!digitcasenumber && !floatcasenumber) {
                        formIsValid = false;
                        errors["weighted"] = "Invalid GPA";
                    }
                }
            }
            if (fields["Unweighted"] !== "") {
                if (typeof fields["Unweighted"] !== "undefined") {
                    const decimalcaseRegExp = /^(?:[1-3](\.\d{1})?|4(\.0{1})?)$/;
                    const digitcaseRegExp = (/^[1-4]$/);
                    const floatcasenumber = decimalcaseRegExp.test(fields["Unweighted"]);
                    const digitcasenumber = digitcaseRegExp.test(fields["Unweighted"]);
                    if (!digitcasenumber && !floatcasenumber) {
                        formIsValid = false;
                        errors["Unweighted"] = "Invalid GPA";
                    }
                }
            }
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    handleNext = async (e) => {
        if (this.handleValidation()) {
            let goals = [];
            let fields = this.state.fields;
            let { selectedUniversityOption, selectedProfessionOption } = this.state;
            let Unweightedgpa = fields["Unweighted"];
            let weightedgpa = fields["weighted"];
            if (this.state.universityCheckBox)
                goals.push("university");
            else
                selectedUniversityOption = [];

            if (this.state.professionsCheckBox)
                goals.push("profession");
            else
                selectedProfessionOption = [];

            if (this.state.gPACheckBox)
                goals.push("GPA");
            let selectedUniversity = [];
            selectedUniversityOption.forEach(element => {
                selectedUniversity.push(element.label || element);
            });
            let selectedProfession = [];
            selectedProfessionOption.forEach(element => {
                selectedProfession.push(element.label);
            });
            let values = {
                improveGpa: this.state.gPACheckBox,
                specificProfession: this.state.professionsCheckBox,
                specificUniversity: this.state.universityCheckBox,
                goalsSelected: goals,
                interestedUniversities: selectedUniversity,
                interestedProfession: selectedProfession,
            }
            if (values.improveGpa === true) {
                values["weighted"] = weightedgpa
                values["unweighted"] = Unweightedgpa
            }

            if (this.props.childprofile?.length > 0) {
                values["studentId"] = this.props?.childprofile[0]?.id
                this.props.setParentUserGoalsStudent(values)
            } else if (this.props.childprofile?.length === 0 || this.props?.childprofile?.length === undefined) {
                this.props.setParentUserGoals(values)
            }
            this.props.setIsParentGoalStatus(false)
        }
    }
    handleUnversityApi = (props) => {

        this.props.getUniversities({ "searchString": props })
    }

    loadOptions = (value, callback) => {
        // universityApi(inputText).then((res) => callback(res.data.data?.map((item) => ({ label: item.university, value: item.university }))));
        if (value === "") {
            this.setState({ universityData: [] });
        }
        this.setState({ isload: true });
        this.setState({ noOptionLength: value });
        let list = []
        if (value !== "") {
            universityApi(value).then((res) => {
                if (res.status === 200) {
                    res?.data?.data.map((item, index) => {
                        list.push({
                            label: item.university,
                            value: item.university
                        });

                    });
                    this.setState({ universityData: list });
                } else {
                    this.setState({ universityData: [] });
                }

                this.setState({ isload: false });
            })
        }

    }

    onTagsChange = (event, values) => {
        if (values === "") {
            this.setState({ universityData: [] });
        }
        let { errors } = this.state;
        this.setState({ selectedUniversityOption: values });
        errors['university'] = ""
    }


    render() {
        const { universities, professions } = this.props;
        let { universityCheckBox, professionsCheckBox, gPACheckBox, selectedUniversityOption, selectedProfessionOption } = this.state;
        let universityOptionstions = [];
        let professionOptionstions = [];
        universities !== undefined && universities.length > 0 && universities.map((university, key) => {
            var temp = { label: university.university, value: university.university }
            universityOptionstions.push(temp);
        });
        professions !== undefined && professions.length > 0 && professions.map((profession, key) => {
            var temp = { label: profession.profession, value: profession.profession }
            professionOptionstions.push(temp);
        });

        console.log(this.props.errorList, "this.props.errorList")
        return (
            <>
                {this.state.loading && <Loader />}

                <div className="role">
                    <div className="role_card parent-goal">
                        <h1 className="role_title text-center">What do you hope to gain from LeapBrains?</h1>
                        <p className="role_desc bluetext text-center">I want my child to...👇</p>
                        <div className="goals_page">
                            <div className="mb-2 role__btns">
                                <input type="checkbox" ref='universityCheckBox' defaultChecked={universityCheckBox} onClick={(e) => this.handleCheckboxChange(e, 'universities')} className="btn-check" name="options1" id="btncheck1" autoComplete="off" />
                                <label className="btn cta--role--btn w-mob-100" htmlFor="btncheck1">Get into specific universities</label>
                            </div>
                            <div className=" mb-2 role__btns">
                                <input type="checkbox" ref='professionsCheckBox' defaultChecked={professionsCheckBox} onClick={(e) => this.handleCheckboxChange(e, 'profession')} className="btn-check" name="options2" id="btncheck2" autoComplete="off" />
                                <label className="btn cta--role--btn w-mob-100" htmlFor="btncheck2">Get into specific professions</label>
                            </div>
                            <div className=" mb-2 role__btns">
                                <input type="checkbox" ref='gPACheckBox' defaultChecked={gPACheckBox} onClick={(e) => this.handleCheckboxChange(e, 'GPA')} className="btn-check" name="options3" id="btncheck3" autoComplete="off" />
                                <label className="btn cta--role--btn w-mob-100" htmlFor="btncheck3">Improve GPA</label>
                            </div>
                        </div>
                        {this.state.errors["goal"] && <span className="error-text text-center" style={{ display: "table", margin: "0 auto" }}>{this.state.errors["goal"]}</span>}
                        <p className=" text-center goalsDesc mt-2 mb-1">Specify one or more goals so we can connect you with advisors and students with similar goals</p>
                        <Form className="login_card_form mb-3" autoComplete="off">
                            <div className="goals__form">
                                {universityCheckBox &&
                                    <div className="row">
                                        <div className="col-md-12 mb-5 role__btns asyncmultiselect multinew d-block">
                                            <Autocomplete
                                                multiple
                                                // loading={this.state.isload}
                                                // noOptionsText={'There is no university related to your search'}
                                                className="goals__form__select"
                                                id="tags-filled"
                                                name="universityDropdown"
                                                type='text'
                                                clearOnBlur={false}
                                                filterSelectedOptions
                                                clearIcon
                                                ChipProps
                                                forcePopupIcon={false}
                                                freeSolo={true}
                                                openOnFocus={true}
                                                // open={this.state.noOptionLength.length > 0}
                                                options={this.state.universityData?.length > 0 ? this.state.universityData.map((option) => option?.label) : []}
                                                renderTags={(value, getTagProps) =>
                                                    value.map((option, index) => (
                                                        <Chip label={option} {...getTagProps({ index })} deleteIcon={<CancelIcon  sx={{fontSize :'20px !important'}}/>} />
                                                    ))
                                                }
                                                filterOptions={(options, state) => options}
                                                defaultValue={selectedUniversityOption !== null ? selectedUniversityOption?.map((option) => option?.label || option) : []}
                                                onChange={this.onTagsChange}
                                                onInputChange={async (event, value) => {
                                                    this.loadOptions(value)
                                                }}
                                                renderInput={(params) => (
                                                    <>
                                                        <TextField
                                                            {...params}
                                                            label="University"
                                                            size="small"
                                                            // focused={false} 
                                                            autoComplete='off'
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                onKeyDown: (e) => {
                                                                    if (e.key === 'Enter') {
                                                                        // e.stopPropagation();
                                                                        e.preventDefault();
                                                                    }
                                                                },
                                                            }}

                                                        />
                                                    </>
                                                )}
                                            />
                                        {this.state.errors["university"] && <span className="error-text">{this.state.errors["university"]}</span>}

                                        </div>
                                    </div>
                                }
                                {professionsCheckBox &&
                                    <>
                                        <div className="row">
                                            <div className="col-md-12 mb-5 role__btns asyncmultiselect">
                                                <CreatableSelect className="goals__form__select" noOptionsMessage={() => null} classNamePrefix="mySelect" options={professionOptionstions} id="professionsSelect"
                                                    closeMenuOnSelect={true} isClearable={false} isMulti components={{ ValueContainer: CustomValueContainer, DropdownIndicator: () => null, IndicatorSeparator: () => null, MultiValueRemove }}
                                                    placeholder="Professions" value={selectedProfessionOption} onChange={(value) => this.handleSelectChange(value, 'professions')}
                                                    styles={{
                                                        option: (provided, state) => ({ ...provided, cursor: 'pointer', }),
                                                        container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible" }), valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px', }),
                                                        placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10 })
                                                    }} />
                                                {this.state.errors["profession"] && <span className="error-text">{this.state.errors["profession"]}</span>}
                                            </div>
                                        </div>
                                    </>
                                }
                                {gPACheckBox &&
                                    <div className="row mb-5">
                                        <div className="col-md-6 col-sm-6 col-12 " style={{ "padding": "0px" }}>
                                            <div className="input-floating-label" style={{ "width": "95%" }} >
                                                <input refs="Unweighted" type="text" className={"textbox--primary textbox--rounded input"} name="Unweighted"
                                                    placeholder="Unweighted" onKeyPress={(event) => { if (!/[0-9.]/.test(event.key)) { event.preventDefault(); } }} onChange={this.handleChange.bind(this, "Unweighted")} value={this.state.fields["Unweighted"]} />
                                                <label>Target Unweighted GPA</label>
                                                {this.state.errorList["unweighted"] && <span className="error-text">{this.props.errorList["unweighted"] }</span>}
                                                {this.state.errors["Unweighted"] && <span className="error-text">{this.state.errors["Unweighted"]}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-12" style={{ "padding": "0px" }}>
                                            <div className="input-floating-label">
                                                <input refs="weighted" type="text" className={"textbox--primary textbox--rounded input"} name="weighted"
                                                    placeholder="weighted" onKeyPress={(event) => { if (!/[0-9.]/.test(event.key)) { event.preventDefault(); } }} onChange={this.handleChange.bind(this, "weighted")} value={this.state.fields["weighted"]} />
                                                <label>Target Weighted GPA</label>
                                                {this.state.errorList["weighted"] && <span className="error-text">{this.props.errorList["weighted"] }</span>}
                                                {this.state.errors["weighted"] && <span className="error-text">{this.state.errors["weighted"]}</span>}
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </Form>
                    </div>
                </div>
                {/* <div className="footer">
                    <div className="row m-0">
                        <div className="footer__left col-md-4 col-sm-4 col-4 text-center p-0">
                            {
                                this.props.userProfile?.roleLocked === false && (
                                    <p>
                                        <Link to="/onboarding/roles" className="footer__left__cta">
                                            <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M5.80474 0.195262C5.54439 -0.0650874 5.12228 -0.0650874 4.86193 0.195262L0.195262 4.86193C-0.0650873 5.12228 -0.0650873 5.54439 0.195262 5.80474L4.86193 10.4714C5.12228 10.7318 5.54439 10.7318 5.80474 10.4714C6.06509 10.2111 6.06509 9.78894 5.80474 9.52859L1.60948 5.33333L5.80474 1.13807C6.06509 0.877722 6.06509 0.455612 5.80474 0.195262Z" fill="#1B1C1E" />
                                            </svg>
                                            <span>Go back</span>
                                        </Link>
                                    </p>
                                )
                            }

                        </div>
                        <div className="footer__center col-md-4 col-sm-4 col-4 text-center">
                            <p>1 out of 8 steps</p>
                        </div>
                        <div className="footer__right col-md-4 col-sm-4 col-4 text-center">
                            <Link className="footer__cta" to="#" onClick={e => this.handleNext(e)}>Next step</Link>
                        </div>
                    </div>
                </div> */}
                <Footer goback={this.props.userProfile?.roleLocked === false ? false : true} reverseLink={"/onboarding/roles"}>
                    <Link className="footer__cta" to="#" onClick={(e) => this.handleNext(e)} >Create your profile
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.92893 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.0222 21.4135 8.08879 20.3147 6.4443C19.2159 4.79981 17.6541 3.51808 15.8268 2.76121C13.9996 2.00433 11.9889 1.8063 10.0491 2.19215C8.10929 2.578 6.32746 3.53041 4.92893 4.92893" stroke-width="2" className="rolestrokes" />
                            <path d="M15 12L15.7809 11.3753L16.2806 12L15.7809 12.6247L15 12ZM3 13C2.44771 13 2 12.5523 2 12C2 11.4477 2.44771 11 3 11V13ZM11.7809 6.3753L15.7809 11.3753L14.2191 12.6247L10.2191 7.6247L11.7809 6.3753ZM15.7809 12.6247L11.7809 17.6247L10.2191 16.3753L14.2191 11.3753L15.7809 12.6247ZM15 13H3V11H15V13Z" className="rolefills" />
                        </svg>
                    </Link>
                </Footer>
                {this.props.errorList &&
                    <CustomAlert />
                }
            </>
        );
    }
}

const mapStateToProps = ({ userProfileData, parentData, commonData }) => {
    const { message, errorList, universities, professions, loading } = commonData
    const { userProfile, childprofile, getchildstatus } = userProfileData;
    const { isParentGoalSuccess, parentGoal, isParentGoalStatus, isGoalStudent, parentsstatus } = parentData;
    return { message, errorList, universities, professions, isParentGoalStatus, parentGoal, childprofile, isParentGoalSuccess, userProfile, userProfileData, loading, isGoalStudent, getchildstatus, parentsstatus }
};

export default connect(mapStateToProps, { getUniversities, setIsParentGoalStatus, getProfessions, setParentUserGoalsStudent, getChildProfile, clearMessage, clearErrorList, getParentGoals, setParentUserGoals, getUserProfile, setIsParentGoalSuccess, getGoalsFromStudent, getChildProfilestatus, getGoalsFromStudentStatus })(withRouter(Index));