import React from 'react'
import { Tooltip } from '@mui/material';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import "./courseCard.less"
const Index = ({ menu, children, courseName, planName, courseType, semester, description, isApproved, isArchived, isDropsuggested, suggestionData, isPassedSem }) => {
    const checkIfSuggestionExist = (isApproved, isDropSuggested) => {
        if (!isPassedSem) {
            if (isApproved && isDropSuggested) {
                return '-unApproved';
            }
            else if (!isApproved && !isDropSuggested) {
                return '-suggested'
            }
        } else {
            return null
        }
    }
    return (
        <div className={`myWork-courseCard${checkIfSuggestionExist(isApproved, isDropsuggested) || ''}`}>

            <div className="myWork-courseCard__topSec">
                <div className="myWork-courseCard__topSec__titleSec">
                    <MenuBookOutlinedIcon className='myWork-courseCard__topSec__titleSec__iconLeft' />
                    <p className='myWork-courseCard__topSec__titleSec__title'>{courseName}</p>
                    <Tooltip title={planName} placement="top">
                        <LibraryBooksOutlinedIcon className='myWork-courseCard__topSec__titleSec__iconRight' />
                    </Tooltip>
                </div>
                {menu &&
                    <div className="myWork-courseCard__topSec__menuSec">
                        {menu}
                    </div>
                }
            </div>
            <div style={{ "marginTop": "5px", "marginBottom": "5px", "height": "10px" }}>
                {isArchived &&
                    <div className="myWork-planCard__midtop">
                        <div className='myWork-planCard__midtop__pill'>Archived</div>
                    </div>
                }
            </div>
            {isPassedSem &&
                <div className="myWork-courseCard__statusSec">
                    <div className="myWork-courseCard__statusSec__status">
                        <p className="myWork-courseCard__statusSec__status__successText">Completed</p>
                    </div>
                </div>
            }
            <div className="myWork-courseCard__midSec">
                <div className="myWork-courseCard__midSec__content">
                    <p className="myWork-courseCard__midSec__content__label">Course Level</p>
                    <p className="myWork-courseCard__midSec__content__value">{courseType}</p>
                </div>
                <div className="myWork-courseCard__midSec__content">
                    <p className="myWork-courseCard__midSec__content__label">Semester</p>
                    <p className="myWork-courseCard__midSec__content__value">{semester}</p>
                </div>
                {!isPassedSem &&
                    <p className="myWork-courseCard__midSec__value">{description}</p>
                }
            </div>
            <div className="myWork-courseCard__suggestionSec">
                {suggestionData}
            </div>
            <div className="myWork-courseCard__ctaSec">
                {children}
            </div>
        </div>
    )
}

export default Index