import axios from 'axios'
import { SUBSCRIBED_ADVISORS,SUBSCRIBED_ADVISORS_ERROR, SET_PAYMENT_DONE_STATUS, GET_PRICE_PACKAGE, ADD_COURSE, ADD_ACTIVITY, EDIT_COURSE, EDIT_ACTIVITY, DELETE_COURSE, 
    DELETE_ACTIVITY, ADD_PACKAGE, EDIT_PACKAGE, GET_PRICE_PACKAGES, ADD_PACKAGE_ERROR, ADD_OFFICE_HOURS, UPDATE_PRICE_PACKAGE_STATUS, GET_SUBSCRIBED_ADVISORS, 
    EVENT_CONFIRMATION_STATUS, LOAD_SUBSCRIBED_STUDENTS, LOAD_SUBSCRIBED_STUDENTS_STATUS, CREATE_STUDENT_PLAN, OFFER_EXTRA_SESSION, PROFILE_SERVICE_STATUS, GET_PRICE_PACKAGES_STATUS, 
    SUGGEST_TO_DROP_PLAN, UNDO_PLAN_SUGGESTION_STATUS, UNDO_COURSE_SUGGESTION_STATUS, SUGGEST_TO_DROP_COURSE, SUGGEST_TO_DROP_ACTIVITY, UNDO_ACTIVITY_SUGGESTION_STATUS, 
    EXPLORE_STUDENT_DATA, EXPLORE_STUDENT_STATUS, UNDO_PLAN_DROP_SUGGESTION_STATUS, UNDO_COURSE_DROP_SUGGESTION_STATUS, UNDO_ACTIVITY_DROP_SUGGESTION_STATUS, DELETE_PACKAGE_ERROR_STATUS, NEWLY_CREATED_PLAN, 
    LOAD_CURRENT_SUBSCRIBERS,
    LOAD_CURRENT_SUBSCRIBERS_SUCCESS,
    LOAD_PAST_SUBSCRIBERS_SUCCESS,
    LOAD_PAST_SUBSCRIBERS,
    UNDO_COURSE_SUGGESTION_ERROR,
    UNDO_ACTIVITY_SUGGESTION_ERROR
} from '../constants/AdvisorActionTypes';
import { ALERT_HIDE, ALERT_SHOW, CUSTOM_ALERT_CLOSE, CUSTOM_ALERT_SHOW, FETCH_ERROR, FETCH_START, FETCH_SUCCESS, } from '../constants/CommonTypes';
import { getUserProfile } from './Profile';
import { Edit_PLAN_STATUS, NEW_PLAN_ID } from '../constants/StudentActionTypes';
const BASE_URL = process.env.REACT_APP_BASE_URL;


export const setCreatePackageStatus = status => {
    return (dispatch) => {
        dispatch({ type: ADD_PACKAGE, payload: status });
    }
};
export const addPricePackage = requestParams => {
    return (dispatch) => {
        dispatch({ type: FETCH_START })
        axios.post(BASE_URL + '/user-service/v1/advisor/paymentpackages/add', requestParams).then(({ data }) => {
            const { headers: { statusCode, message } } = data
            if (statusCode === "200") {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: ADD_PACKAGE, payload: true });
                dispatch(getUserProfile());
                dispatch(getPricePackages());
            } else {
                dispatch({ type: FETCH_ERROR, payload: message });
                dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
                setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 5000)
            }
        }).catch((error) => {
            if (error.response) {
                const { headers: { message, statusCode }, errorlist, } = error.response.data;
                dispatch({ type: ADD_PACKAGE_ERROR, payload:  true })
                dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
                dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } })
                setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 5000)
            } else {
                dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
            }
        })
    }
}
export const addPricePackageStatus = status => {
    return (dispatch) => {
        dispatch({ type: ADD_PACKAGE, payload: status });
    }
};
export const setPricePackageStatus = status => {
    return (dispatch) => {
        dispatch({ type: ADD_PACKAGE_ERROR, payload: status });
    }
};
export const getPricePackages = () => {
    return (dispatch) => {
        axios.get(BASE_URL + '/user-service/v1/advisor/paymentpackages').then(({ data }) => {
            const { headers: { statusCode, message } } = data
            if (statusCode === "200") {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: GET_PRICE_PACKAGES_STATUS, payload: true });
                dispatch({ type: GET_PRICE_PACKAGES, payload: {data: data?.data} });
            } else {
                dispatch({ type: FETCH_ERROR, payload: message });
            }
        }).catch(function (error) {
            if (error.response) {
                const { headers: { message } } = error.response.data;
                dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
            } else {
                dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
            }
            console.log("Error****:", error.message);
        });
    }
};
export const setLoadPricePackageStatus = status => {
    return (dispatch) => {
        dispatch({ type: GET_PRICE_PACKAGES_STATUS, payload: status });
    }
};
export const DeletePriceSetting = (requestParams) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START })
        axios.post(BASE_URL + "/user-service/v1/advisor/paymentpackages/delete", requestParams).then(({ data }) => {
            const { headers: { statusCode, message } } = data
            if (statusCode === "200") {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: SET_PAYMENT_DONE_STATUS, payload: true });
                // dispatch(getUserProfile());
                dispatch(getPricePackages());
            } else {
                dispatch({ type: FETCH_ERROR, payload: message });
                dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
                setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
            }
        }).catch((error) => {
            if (error.response) {
                const { headers: { message, statusCode }, errorlist, } = error.response.data;
                dispatch({ type: DELETE_PACKAGE_ERROR_STATUS, payload: true });
                dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
                dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } })
                setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
            } else {
                dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
            }
        })
    }
}
export const setDeletePackageStatus = status => {
    return (dispatch) => {
        dispatch({ type: SET_PAYMENT_DONE_STATUS, payload: status });
    }
};
export const setDeletePackageErrorStatus = status => {
    return (dispatch) => {
        dispatch({ type: DELETE_PACKAGE_ERROR_STATUS, payload: status });
    }
};
export const EditPriceSetting = (id, requestParams) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START })
        axios.post(BASE_URL + (`/user-service/v1/advisor/paymentpackages/edit/${id}`), requestParams).then(({ data }) => {
            const { headers: { statusCode, message } } = data
            if (statusCode === "200") {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: EDIT_PACKAGE, payload: true });
                dispatch(getUserProfile());
                dispatch(getPricePackages());
            } else {
                dispatch({ type: FETCH_ERROR, payload: message });
                dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
                setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
            }
        }).catch((error) => {
            if (error?.response) {
                const { headers: { message, statusCode }, errorlist } = error.response.data;
                dispatch({ type: ADD_PACKAGE_ERROR, payload:  true })
                dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
                if (!errorlist) {
                    dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } })
                    setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 5000)
                }
            } else {
                dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
            }
            console.log("Error****:", error.message);
        })
    }
}
export const setEditPackageStatus = status => {
    return (dispatch) => {
        dispatch({ type: EDIT_PACKAGE, payload: status });
    }
};
export const getPricePackageID = requestParams => {
    return (dispatch) => {
        axios.get(BASE_URL + '/user-service/v1/advisor/paymentpackages/view/' + requestParams).then(({ data }) => {
            const { headers: { statusCode, message } } = data
            if (statusCode === "200") {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: GET_PRICE_PACKAGE, payload: data });
            } else {
                dispatch({ type: FETCH_ERROR, payload: message });
            }
        }).catch(function (error) {
            if (error.response) {
                const { headers: { message } } = error.response.data;
                dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
            } else {
                dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
            }
            console.log("Error****:", error.message);
        });
    }
};
export const updatePackageStatus = requestParams => {
    return (dispatch) => {
        axios.post(BASE_URL + '/user-service/v1/advisor/paymentpackages/updatestatus', requestParams).then(({ data }) => {
            const { headers: { statusCode } } = data
            if (statusCode === "200") {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: UPDATE_PRICE_PACKAGE_STATUS, payload: true });
                dispatch(getPricePackages());
            }
        }).catch(error => {
            if (error.response) {
                const { headers: { message, statusCode }, errorlist } = error.response.data;
                dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
                dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } })
                setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
            } else {
                dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
                dispatch({ type: ALERT_SHOW, payload: { msg: error.message, success: false } })
                setTimeout(() => { dispatch({ type: ALERT_HIDE }) }, 9000)

            }
        })
    }
}
export const manageProfileServices = requestParams => {
    return (dispatch) => {
        axios.post(BASE_URL + '/user-service/v1/advisor/profile/manageservices', requestParams).then(({ data }) => {
            const { headers: { statusCode, message } } = data
            if (statusCode === "200") {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: PROFILE_SERVICE_STATUS, payload: true });
                dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } })
                setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 5000);
            }
        }).catch(error => {
            if (error.response) {
                const { headers: { message, statusCode }, errorlist } = error.response.data;
                dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
                dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } })
                setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
            } else {
                dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
                dispatch({ type: ALERT_SHOW, payload: { msg: error.message, success: false } })
                setTimeout(() => { dispatch({ type: ALERT_HIDE }) }, 9000)

            }
        })
    }
}
export const setManageProfileServicesStatus = status => {
    return (dispatch) => {
        dispatch({ type: PROFILE_SERVICE_STATUS, payload: status });
    }
};
export const addCourse = (requestParams, data) => {
    console.log(data, "data");
    return (dispatch) => {
        dispatch({ type: FETCH_START })
        axios.post(BASE_URL + '/user-service/v1/advisor/profile/addcourse', requestParams).then(({ data }) => {
            const { headers: { statusCode, message } } = data
            if (statusCode === "200") {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: ADD_COURSE, payload: true });
                dispatch(getUserProfile());
            } else {
                dispatch({ type: FETCH_ERROR, payload: message });
                if (data) {
                    dispatch({ type: ALERT_SHOW, payload: { msg: message, success: false } })
                    setTimeout(() => { dispatch({ type: ALERT_HIDE }) }, 9000)
                } else {
                    dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
                    setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
                }
            }
        }).catch((error) => {
            if (error.response) {
                const { headers: { message, statusCode }, errorlist, } = error.response.data;
                dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
                if (data) {
                    dispatch({ type: ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } })
                    setTimeout(() => { dispatch({ type: ALERT_HIDE }) }, 9000)
                } else {
                    dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } })
                    setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
                }

            } else {
                dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
                dispatch({ type: ALERT_SHOW, payload: { msg: error.message, success: false } })
                setTimeout(() => { dispatch({ type: ALERT_HIDE }) }, 9000)

            }
        })
    }
}
export const setAddCourseStatus = status => {
    return (dispatch) => {
        dispatch({ type: ADD_COURSE, payload: status });
    }
};
export const addActivity = (requestParams, data) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START })
        axios.post(BASE_URL + '/user-service/v1/advisor/profile/addactivity', requestParams).then(({ data }) => {
            const { headers: { statusCode, message } } = data
            if (statusCode === "200") {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: ADD_ACTIVITY, payload: true });
                dispatch(getUserProfile());
            } else {
                dispatch({ type: FETCH_ERROR, payload: message });
                dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
                setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
            }
        }).catch((error) => {
            if (error.response) {
                const { headers: { message, statusCode }, errorlist, } = error.response.data;
                dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
                if (data) {
                    dispatch({ type: ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } })
                    setTimeout(() => { dispatch({ type: ALERT_HIDE }) }, 9000)
                } else {
                    dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } })
                    setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
                }
            } else {
                dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
                dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
                setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)

            }
        })
    }
}
export const setAddActivityStatus = status => {
    return (dispatch) => {
        dispatch({ type: ADD_ACTIVITY, payload: status });
    }
};
export const editCourse = (id, requestParams, data) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START })
        axios.post(BASE_URL + (`/user-service/v1/advisor/profile/editcourse/${id}`), requestParams).then(({ data }) => {
            const { headers: { statusCode, message } } = data
            if (statusCode === "200") {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: EDIT_COURSE, payload: true });
                dispatch(getUserProfile());
            } else {
                dispatch({ type: FETCH_ERROR, payload: message });
                dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
                setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
            }
        }).catch((error) => {
            if (error.response) {
                const { headers: { message, statusCode }, errorlist, } = error.response.data;
                dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
                if (data) {
                    dispatch({ type: ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } })
                    setTimeout(() => { dispatch({ type: ALERT_HIDE }) }, 9000)
                } else {
                    dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } })
                    setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
                }
            } else {
                dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
                dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
                setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
            }
        })
    }
}
export const setEditCourseStatus = status => {
    return (dispatch) => {
        dispatch({ type: EDIT_COURSE, payload: status });
    }
};
export const editActivity = (id, requestParams, data) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START })
        axios.post(BASE_URL + (`/user-service/v1/advisor/profile/editactivity/${id}`), requestParams).then(({ data }) => {
            const { headers: { statusCode, message } } = data
            if (statusCode === "200") {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: EDIT_ACTIVITY, payload: true });
                dispatch(getUserProfile());
                // dispatch({ type: FETCH_ERROR, payload: [] });
                // dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } })
                // setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
            } else {
                dispatch({ type: FETCH_ERROR, payload: message });
                dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
                setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
            }
        }).catch((error) => {
            if (error.response) {
                const { headers: { message, statusCode }, errorlist, } = error.response.data;
                dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
                if (data) {
                    dispatch({ type: ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } })
                    setTimeout(() => { dispatch({ type: ALERT_HIDE }) }, 9000)
                } else {
                    dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } })
                    setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
                }

            } else {
                dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
                dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
                setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
            }
        })
    }
}
export const setEditActivityStatus = status => {
    return (dispatch) => {
        dispatch({ type: EDIT_ACTIVITY, payload: status });
    }
};
export const deleteCourse = (id) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START })
        axios.post(BASE_URL + (`/user-service/v1/advisor/profile/deletecourse/${id}`)).then(({ data }) => {
            const { headers: { statusCode, message } } = data
            if (statusCode === "200") {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: DELETE_COURSE, payload: true });
                dispatch(getUserProfile());
            } else {
                dispatch({ type: FETCH_ERROR, payload: message });
            }
        }).catch((error) => {
            if (error.response) {
                const { headers: { message, statusCode }, errorlist, } = error.response.data;
                dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
                dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } })
                setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)

            } else {
                dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
                dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
                setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
            }
        })
    }
}
export const deleteActivity = (id) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START })
        axios.post(BASE_URL + (`/user-service/v1/advisor/profile/deleteactivity/${id}`)).then(({ data }) => {
            const { headers: { statusCode, message } } = data
            if (statusCode === "200") {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: DELETE_ACTIVITY, payload: true });
                dispatch(getUserProfile());

                // dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } })
                // setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
            } else {
                dispatch({ type: FETCH_ERROR, payload: message });
                // dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
                // setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
            }
        }).catch((error) => {
            if (error.response) {
                const { headers: { message, statusCode }, errorlist, } = error.response.data;
                dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
                dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } })
                setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)

            } else {
                dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
                dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
                setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
            }
        })
    }
}
export const setOfficeHours = requestParams => {
    return (dispatch) => {
        dispatch({ type: FETCH_START })
        axios.post(BASE_URL + '/user-service/v1/advisor/profile/updateofficehours', requestParams).then(({ data }) => {
            const { headers: { statusCode, message } } = data
            if (statusCode === "200") {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: ADD_OFFICE_HOURS, payload: true });
                dispatch(getUserProfile());
                dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } })
                setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 5000)
            } else {
                dispatch({ type: FETCH_ERROR, payload: message });
                dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
                setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 5000)
            }
        }).catch((error) => {
            if (error) {
                const { headers: { message, statusCode }, errorlist, } = error.response.data;
                dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
                dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } })
                setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)

            }
        })
    }
}
export const getSubscribedAdvisors = () => {
    return (dispatch) => {
        axios.get(BASE_URL + '/user-service/v1/subscription/subscribedadvisors').then(({ data }) => {
            const { headers: { statusCode, message } } = data
            if (statusCode === "200") {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: GET_SUBSCRIBED_ADVISORS, payload: data.data });
            } else {
                dispatch({ type: FETCH_ERROR, payload: message });
            }
        }).catch(function (error) {
            if (error.response) {
                const { headers: { message } } = error.response.data;
                dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
            } else {
                dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
            }
            console.log("Error****:", error.message);
        });
    }
};
export const SubScribeAdvisor = requestParams =>  {
    return (dispatch) => {
        axios.post(BASE_URL + '/user-service/v1/subscription/subscribetoadvisor', requestParams).then(({ data }) => {
            const { headers: { statusCode, message } } = data
            if (statusCode === "200") {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: SUBSCRIBED_ADVISORS, payload: true });
                dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } })
                setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 5000)
            } else {
                dispatch({ type: FETCH_ERROR, payload: message });
                dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
                setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 5000)
            }
        }).catch(function (error) {
            if (error) {
                const { headers: { message, statusCode }, errorlist, } = error.response.data;
                dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
                dispatch({ type: SUBSCRIBED_ADVISORS_ERROR, payload: true });
                dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } })
                setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)

            }
        });
    }
}
export const SubScribeAdvisorStatus = status => {
    return (dispatch) => {
      dispatch({ type: SUBSCRIBED_ADVISORS, payload: status });
    }
};
export const SubScribeAdvisorErrorStatus = status => {
    return (dispatch) => {
      dispatch({ type: SUBSCRIBED_ADVISORS_ERROR, payload: status });
    }
};
export const confirmScheduledEvent = (requestParams) => {
	return async (dispatch) => {
        dispatch({ type: FETCH_START });
        await axios.post(BASE_URL + '/events-service/v1/events/updateInvitationtatus', requestParams).then(({ data }) => {
            const { headers: { statusCode, message } } = data
            if (statusCode === "200") {
            dispatch({ type: FETCH_SUCCESS });
            dispatch({ type: EVENT_CONFIRMATION_STATUS, payload: true })
            } else {
            dispatch({ type: FETCH_ERROR, payload: message });
            dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
            setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 5000)
            }
        }).catch(function (error) {
            if (error.response) {
            const { headers: { message, statusCode }, errorlist } = error.response.data;
            dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
            dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
            setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }); }, 5000);
            } else {
            dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.headers.message } });
            dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
            setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }); }, 5000);
            }
        });
	}
}
export const confirmScheduledEventStatus = status => {
	return (dispatch) => {
		dispatch({ type: EVENT_CONFIRMATION_STATUS, payload: status });
	}
}
export const getSubscribedStudents = () => {
	return async (dispatch) => {
        dispatch({ type: FETCH_START });
        await axios.get(BASE_URL + '/user-service/v1/subscription/subscribedstudents').then(({ data }) => {
            const { headers: { statusCode, message } } = data
            if (statusCode === "200") {
            dispatch({ type: FETCH_SUCCESS });
            dispatch({ type: LOAD_SUBSCRIBED_STUDENTS, payload: {status: true, data: data.data} })
            } else {
            dispatch({ type: FETCH_ERROR, payload: message });
            dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
            setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 5000)
            }
        }).catch(function (error) {
            if (error.response) {
            const { headers: { message, statusCode }, errorlist } = error.response.data;
            dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
            dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
            setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }); }, 5000);
            } else {
            dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.headers.message } });
            dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
            setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }); }, 5000);
            }
        });
	}
}
export const getSubscribedStudentStatus = status => {
	return (dispatch) => {
		dispatch({ type: LOAD_SUBSCRIBED_STUDENTS_STATUS, payload: status });
	}
}
export const getCurrentSubscribers = () => {
	return async (dispatch) => {
        dispatch({ type: FETCH_START });
        await axios.get(BASE_URL + '/user-service/v1/subscription/currentsubscribers').then(({ data }) => {
            const { headers: { statusCode, message } } = data
            if (statusCode === "200") {
            dispatch({ type: FETCH_SUCCESS });
            dispatch({ type: LOAD_CURRENT_SUBSCRIBERS, payload: {status: true, data: data.data} })
            } else {
            dispatch({ type: FETCH_ERROR, payload: message });
            dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
            setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 5000)
            }
        }).catch(function (error) {
            if (error.response) {
            const { headers: { message, statusCode }, errorlist } = error.response.data;
            dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
            dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
            setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }); }, 5000);
            } else {
            dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.headers.message } });
            dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
            setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }); }, 5000);
            }
        });
	}
}
export const setCurrentSubscribersSuccessStatus = status => {
	return (dispatch) => {
		dispatch({ type: LOAD_CURRENT_SUBSCRIBERS_SUCCESS, payload: status });

	}
}
export const getPastSubscribers = () => {
	return async (dispatch) => {
        dispatch({ type: FETCH_START });
        await axios.get(BASE_URL + '/user-service/v1/subscription/pastsubscribers').then(({ data }) => {
            const { headers: { statusCode, message } } = data
            if (statusCode === "200") {
            dispatch({ type: FETCH_SUCCESS });
            dispatch({ type: LOAD_PAST_SUBSCRIBERS, payload: {status: true, data: data.data} })
            } else {
            dispatch({ type: FETCH_ERROR, payload: message });
            dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
            setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 5000)
            }
        }).catch(function (error) {
            if (error.response) {
            const { headers: { message, statusCode }, errorlist } = error.response.data;
            dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
            dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
            setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }); }, 5000);
            } else {
            dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.headers.message } });
            dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
            setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }); }, 5000);
            }
        });
	}
}
export const setPastSubscribersSuccessStatus = status => {
	return (dispatch) => {
		dispatch({ type: LOAD_PAST_SUBSCRIBERS_SUCCESS, payload: status });
	}
}
export const createPlanForStudent = (requestParams) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.post(BASE_URL + '/student-plans/v1/advisor/plans/create', requestParams).then(({ data }) => {
            const { headers: { statusCode, message } } = data
            if (statusCode === "200") {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: CREATE_STUDENT_PLAN, payload: true })
                dispatch({ type: NEWLY_CREATED_PLAN, payload: data?.planId })
            } else {
                dispatch({ type: FETCH_ERROR, payload: message });
                dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
                setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
            }
        }).catch((error) => {
            if (error.response) {
                console.log("error.response.data", error.response.data);
                const { headers: { message, statusCode }, errorlist, } = error.response.data;
                dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
                dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
                setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
            } else {
                dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
                dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
                setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
            }
            console.log("Error****:", error.message);
        });
    }
}
export const createPlanForStudentStatus = status => {
    return (dispatch) => {
      dispatch({ type: CREATE_STUDENT_PLAN, payload: status });
    }
};
export const resetNewlyCreatedPlanId = () => {
    return (dispatch) => {
      dispatch({ type: NEWLY_CREATED_PLAN, payload: undefined });
    }
};
export const editStudentPlan = (requestParams, id) => {
    return (dispatch) => {
      dispatch({ type: FETCH_START });
      axios.post(BASE_URL + '/student-plans/v1/plans/update/' + id, requestParams).then(({ data }) => {
        const { headers: { statusCode, message } } = data
        console.log(statusCode, "---StatusCode---",  );
        if (statusCode === "200") {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: Edit_PLAN_STATUS, payload: true })
        } else {
          dispatch({ type: FETCH_ERROR, payload: message });
          dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
          setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
        }
      }).catch(function (error) {
        if (error.response) {
          const { headers: { message, statusCode }, errorlist, } = error.response.data;
          dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
          dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
          setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
        } else {
          dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
          dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
          setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
        }
        console.log("Error****:", error.message);
      });
    }
}
export const editStudentPlanStatus = status => {
    return (dispatch) => {
      dispatch({ type: Edit_PLAN_STATUS, payload: status });
    }
};
export const offerExtraSession = (requestParams) => {
    return (dispatch) => {
      dispatch({ type: FETCH_START });
      axios.post(BASE_URL + '/user-service/v1/subscription/offerextrasession', requestParams).then(({ data }) => {
        const { headers: { statusCode, message } } = data
        if (statusCode === "200") {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: OFFER_EXTRA_SESSION, payload: true })
        } else {
          dispatch({ type: FETCH_ERROR, payload: message });
          dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
          setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
        }
      }).catch(function (error) {
        if (error.response) {
          const { headers: { message, statusCode }, errorlist } = error.response.data;
          dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
          dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message || error.message, success: false } })
          setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
        } else {
          dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
          dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
          setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
        }
        console.log("Error****:", error.message);
      });
    }
}
export const setOfferExtraSessionStatus = status => {
    return (dispatch) => {
      dispatch({ type: OFFER_EXTRA_SESSION, payload: status });
    }
};
export const suggestToDropPlan = (id) => {
    return (dispatch) => {
      dispatch({ type: FETCH_START });
      axios.post(BASE_URL + '/student-plans/v1/plans/delete/'+ id).then(({ data }) => {
        const { headers: { statusCode, message } } = data
        if (statusCode === "200") {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: SUGGEST_TO_DROP_PLAN, payload: true })
        } else {
          dispatch({ type: FETCH_ERROR, payload: message });
          dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
          setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
        }
      }).catch(function (error) {
        if (error.response) {
          const { headers: { message, statusCode }, errorlist, } = error.response.data;
          dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
          dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
          setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
        } else {
          dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
          dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
          setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
        }
        console.log("Error****:", error.message);
      });
    }
}
export const setSuggestToDropPlanStatus = status => {
    return (dispatch) => {
      dispatch({ type: SUGGEST_TO_DROP_PLAN, payload: status });
    }
};
export const undoPlanSuggestion = (id) => {
    return (dispatch) => {
      dispatch({ type: FETCH_START });
      axios.put(BASE_URL + '/student-plans/v1/plans/undoplanuggestion/'+ id).then(({ data }) => {
        const { headers: { statusCode, message } } = data
        if (statusCode === "200") {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: UNDO_PLAN_SUGGESTION_STATUS, payload: true, data: id })
        } else {
          dispatch({ type: FETCH_ERROR, payload: message });
          dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
          setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
        }
      }).catch(function (error) {
        if (error.response) {
          const { headers: { message, statusCode }, errorlist, } = error.response.data;
          dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
          dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
          setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
        } else {
          dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
          dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
          setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
        }
        console.log("Error****:", error.message);
      });
    }
}
export const setUndoPlanSuggestionStatus = status => {
    return (dispatch) => {
      dispatch({ type: UNDO_PLAN_SUGGESTION_STATUS, payload: status });
    }
};
export const undoPlanDropSuggestion = (id) => {
    return (dispatch) => {
      dispatch({ type: FETCH_START });
      axios.put(BASE_URL + '/student-plans/v1/plans/undodropplanuggestion/'+ id).then(({ data }) => {
        const { headers: { statusCode, message } } = data
        if (statusCode === "200") {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: UNDO_PLAN_DROP_SUGGESTION_STATUS, payload: true })
        } else {
          dispatch({ type: FETCH_ERROR, payload: message });
          dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
          setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 5000)
        }
      }).catch(function (error) {
        if (error.response) {
          const { headers: { message, statusCode }, errorlist, } = error.response.data;
          dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
          dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
          setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 5000)
        } else {
          dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
          dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
          setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 5000)
        }
        console.log("Error****:", error.message);
      });
    }
}
export const setUndoPlanDropSuggestionStatus = status => {
    return (dispatch) => {
      dispatch({ type: UNDO_PLAN_DROP_SUGGESTION_STATUS, payload: status });
    }
};
export const suggestToDropCourse = (id, requestParams) => {
    return (dispatch) => {
      dispatch({ type: FETCH_START });
      axios.post(BASE_URL + '/student-plans/v1/courses/delete/'+ id, requestParams).then(({ data }) => {
        const { headers: { statusCode, message } } = data
        if (statusCode === "200") {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: SUGGEST_TO_DROP_COURSE, payload: true })
        } else {
          dispatch({ type: FETCH_ERROR, payload: message });
          dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
          setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
        }
      }).catch(function (error) {
        if (error.response) {
          const { headers: { message, statusCode }, errorlist, } = error.response.data;
          dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
          dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
          setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
        } else {
          dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
          dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
          setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
        }
        console.log("Error****:", error.message);
      });
    }
}
export const setSuggestToDropCourseStatus = status => {
    return (dispatch) => {
      dispatch({ type: SUGGEST_TO_DROP_COURSE, payload: status });
    }
};
export const undoCourseSuggestion = (requestParams) => {
    return (dispatch) => {
      dispatch({ type: FETCH_START });
      axios.put(BASE_URL + '/student-plans/v1/courses/undosuggestion', requestParams).then(({ data }) => {
        const { headers: { statusCode, message } } = data
        if (statusCode === "200") {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: UNDO_COURSE_SUGGESTION_STATUS, payload: true })
        } else {
          dispatch({ type: FETCH_ERROR, payload: message });
          dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
          setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 5000)
        }
      }).catch(function (error) {
        if (error.response) {
          const { headers: { message, statusCode }, errorlist, } = error.response.data;
          dispatch({ type: UNDO_COURSE_SUGGESTION_ERROR, payload: true })
          dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
          dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
          setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 5000)
        } else {
          dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
          dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
          setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 5000)
        }
        console.log("Error****:", error.message);
      });
    }
}
export const setUndoCourseSuggestionStatus = status => {
    return (dispatch) => {
      dispatch({ type: UNDO_COURSE_SUGGESTION_STATUS, payload: status });
    }
};
export const setUndoCourseSuggestionErrorStatus = status => {
    return (dispatch) => {
      dispatch({ type: UNDO_COURSE_SUGGESTION_ERROR, payload: status });
    }
};
export const undoCourseDropSuggestion = (requestParams) => {
    return (dispatch) => {
      dispatch({ type: FETCH_START });
      axios.put(BASE_URL + '/student-plans/v1/courses/undodropsuggestion', requestParams).then(({ data }) => {
        const { headers: { statusCode, message } } = data
        if (statusCode === "200") {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: UNDO_COURSE_DROP_SUGGESTION_STATUS, payload: true })
        } else {
          dispatch({ type: FETCH_ERROR, payload: message });
          dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
          setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
        }
      }).catch(function (error) {
        if (error.response) {
          const { headers: { message, statusCode }, errorlist, } = error.response.data;
          dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
          dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
          setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
        } else {
          dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
          dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
          setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
        }
        console.log("Error****:", error.message);
      });
    }
}
export const setUndoCourseDropSuggestionStatus = status => {
    return (dispatch) => {
      dispatch({ type: UNDO_COURSE_DROP_SUGGESTION_STATUS, payload: status });
    }
};
export const suggestToDropActivity = (id, requestParams) => {
    return (dispatch) => {
      dispatch({ type: FETCH_START });
      axios.post(BASE_URL + '/student-plans/v1/activities/delete/'+ id, requestParams).then(({ data }) => {
        const { headers: { statusCode, message } } = data
        if (statusCode === "200") {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: SUGGEST_TO_DROP_ACTIVITY, payload: true })
        } else {
          dispatch({ type: FETCH_ERROR, payload: message });
          dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
          setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
        }
      }).catch(function (error) {
        if (error.response) {
          const { headers: { message, statusCode }, errorlist, } = error.response.data;
          dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
          dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
          setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
        } else {
          dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
          dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
          setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
        }
        console.log("Error****:", error.message);
      });
    }
}
export const setSuggestToDropActivityStatus = status => {
    return (dispatch) => {
      dispatch({ type: SUGGEST_TO_DROP_ACTIVITY, payload: status });
    }
};
export const undoActivitySuggestion = (requestParams) => {
    return (dispatch) => {
      dispatch({ type: FETCH_START });
      axios.put(BASE_URL + '/student-plans/v1/activities/undosuggestion', requestParams).then(({ data }) => {
        const { headers: { statusCode, message } } = data
        if (statusCode === "200") {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: UNDO_ACTIVITY_SUGGESTION_STATUS, payload: true })
        } else {
          dispatch({ type: FETCH_ERROR, payload: message });
          dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
          setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 5000)
        }
      }).catch(function (error) {
        if (error.response) {
          const { headers: { message, statusCode }, errorlist, } = error.response.data;
          dispatch({ type: UNDO_ACTIVITY_SUGGESTION_ERROR, payload: true });
          dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
          dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
          setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 5000)
        } else {
          dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
          dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
          setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 5000)
        }
        console.log("Error****:", error.message);
      });
    }
}
export const setUndoActivitySuggestionStatus = status => {
    return (dispatch) => {
      dispatch({ type: UNDO_ACTIVITY_SUGGESTION_STATUS, payload: status });
    }
};
export const setUndoActivitySuggestionErrorStatus = status => {
    return (dispatch) => {
      dispatch({ type: UNDO_ACTIVITY_SUGGESTION_ERROR, payload: status });
    }
};
export const undoActivityDropSuggestion = (requestParams) => {
    return (dispatch) => {
      dispatch({ type: FETCH_START });
      axios.put(BASE_URL + '/student-plans/v1/activities/undodropsuggestion', requestParams).then(({ data }) => {
        const { headers: { statusCode, message } } = data
        if (statusCode === "200") {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: UNDO_ACTIVITY_DROP_SUGGESTION_STATUS, payload: true })
        } else {
          dispatch({ type: FETCH_ERROR, payload: message });
          dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
          setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
        }
      }).catch(function (error) {
        if (error.response) {
          const { headers: { message, statusCode }, errorlist, } = error.response.data;
          dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
          dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
          setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
        } else {
          dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
          dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
          setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
        }
        console.log("Error****:", error.message);
      });
    }
}
export const setUndoActivityDropSuggestionStatus = status => {
    return (dispatch) => {
      dispatch({ type: UNDO_ACTIVITY_DROP_SUGGESTION_STATUS, payload: status });
    }
};
export const getRecommendedStudentsForAdvisorsInDetail = requestParams => {
    return async (dispatch) => {
        dispatch({ type: FETCH_START });
        return await axios.post(BASE_URL + '/user-service/v1/search/suggestedstudents/foradvisors/detailed', requestParams)
            .then(({ data }) => {
                const { headers: { statusCode, message } } = data
                if (statusCode === "200") {
                    dispatch({ type: FETCH_SUCCESS });
                    dispatch({ type: EXPLORE_STUDENT_DATA, payload: data?.data, status: true })
                } else {
                    dispatch({ type: FETCH_ERROR, payload: message });
                    dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
                    setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 5000)
                }
            }).catch(function (error) {
                if (error.response) {
                    const { headers: { message, statusCode }, errorlist, } = error.response.data;
                    dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
                    dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
                    setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 5000)
                } else {
                    dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
                    dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
                    setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 5000)
                }
                console.log("Error****:", error.message);
            });
}
    
} 
export const setRecommendedStudentsForAdvisorInDetailStatus = status => {
    return (dispatch) => {
      dispatch({ type: EXPLORE_STUDENT_STATUS, payload: status });
    }
};
