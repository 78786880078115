import React, { useEffect, useState } from 'react'
import "./recomendedStudents.less";
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import StarIcon from '@mui/icons-material/Star';
import PersonIcon from '@mui/icons-material/Person';
import PillSection from "../../../PillSection"
import { useImageUrl } from '../../../../../utils/UseImageURL';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import StarBorderIcon from '@mui/icons-material/StarBorder';


const Index = (props) => {
    let { name, country, avatarUrl, school, universities, professions, matchParam, courseAndActivities, saveProfileIcon, currentlyIn, data, children,userProfile } = props
    const imgUrl = useImageUrl(avatarUrl)

    const mappedData = courseAndActivities.map(item => {
        if (item.activityName) {
            return item.activityName;
        } else if (item.courseName) {
            return item.courseName;
        }
        return null;
    });

    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const cardStyle = {
        backgroundColor: 'white',
        width: userProfile?.currentRole === "student" 
            ? (width <= 400 ? '260px' : '320px') 
            : 'auto',
    };

    return (
        <>
            <div className='recommendedAdvisorCard'  >
                <div className="recommendedAdvisorCard__topSec">
                    {avatarUrl ?
                        <img className="recommendedAdvisorCard__topSec__avatarImage"
                            src={imgUrl} alt="userprofile" loading='lazy'
                        />
                        :
                        <PersonIcon className="recommendedAdvisorCard__topSec__userIcon" color="action" />
                    }
                    <div className='recommendedAdvisorCard__topSec__userInfo'>
                        <p className='recommendedAdvisorCard__topSec__userInfo__primaryText'>{name}</p>
                        <p className='recommendedAdvisorCard__topSec__userInfo__secondaryText'>{country}</p>
                    </div>
                    <div>
                        <p className="rating-heading d-flex"  >
                            <StarBorderIcon sx={{ fontSize: '15px', fill: '#E64A19', marginTop : '6px' }} /><span style={{ "marginLeft": "5px" }} >{data?.advisorReviewSummary?.averageRating.toFixed(1) || 0}{"/5.0"}</span>
                        </p>
                    </div>
                </div>
                <div className='bookmark' style={{ marginLeft: 'auto', cursor: "pointer" }} >{saveProfileIcon}</div>

                <div className="recommendedAdvisorCard__midSec">
                    <div className="recommendedAdvisorCard__midSec__educationSec">
                        {currentlyIn === "highschool" && (
                            <p className='advisor-subText'>  <HomeOutlinedIcon sx={{ fontSize: '14px', fill: "#212224" }} /> Attending  {data?.highschoolName}</p>
                        )}
                        {currentlyIn === "university" && (
                            <>
                                <p className='advisor-subText'>  <SchoolOutlinedIcon sx={{ fontSize: '14px', fill: "#212224" }} /> Attending  {data?.universityName}</p>
                            </>
                        )}
                        {currentlyIn === "professional" && (
                            <>
                                <p className='advisor-subText'>  <WorkOutlineOutlinedIcon sx={{ fontSize: '14px', fill: "#212224" }} /> Professional  {data?.targetProfession}</p>
                            </>
                        )}
                    </div>
                    <p className="recommendedAdvisorCard__midSec__label">
                        Services offered
                    </p>
                    <div className="recommendedAdvisorCard__midSec__goalsSec">
                        {data?.noOfFreeConsultation &&
                            <div className="recommendedAdvisorCard__midSec__goalsSec__targetUniversitySec">
                                <StarIcon className='recommendedAdvisorCard__midSec__goalsSec__targetUniversitySec__starIcon' />
                                <p className='recommendedAdvisorCard__midSec__goalsSec__targetUniversitySec__label'>{data?.noOfFreeConsultation} free session</p>
                            </div>
                        }
                        {data?.helpWithPlan === true &&
                            <div className="recommendedAdvisorCard__midSec__goalsSec__targetUniversitySec">
                                <StarIcon className='recommendedAdvisorCard__midSec__goalsSec__targetUniversitySec__starIcon' />
                                <p className='recommendedAdvisorCard__midSec__goalsSec__targetUniversitySec__label'> Create and manage plan</p>
                            </div>
                        }
                        {courseAndActivities?.length > 0 &&
                            <>
                                <div className="recommendedAdvisorCard__midSec__goalsSec__targetUniversitySec">
                                    <StarIcon className='recommendedAdvisorCard__midSec__goalsSec__targetUniversitySec__starIcon' />
                                    <p className='recommendedAdvisorCard__midSec__goalsSec__targetUniversitySec__label'>Mentoring for</p>
                                </div>
                                <PillSection data={mappedData} maxWidth={220} maxHeight={100} />
                            </>
                        }
                    </div>
                </div>
                <div className="recommendedAdvisorCard__buttonSec">
                    {children}
                </div>
            </div>
        </>
    )
}

export default Index