import React, { useEffect, useState } from 'react'
import '../milestone.less'
import { Button } from 'react-bootstrap'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Tabs, Tab } from "@mui/material";
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { connect } from 'react-redux';
import { DeleteMileStoneNotesStatus, CreateNotesStatus, updateReadUpdateStatus, DeleteMileStoneResourceStatus, GetMilestoneByCourse, GetMilestoneByActivity, GetMileStoneByCourseStatus, GetMileStoneByActivityStatus, createMilestonforCourseStatus } from "../../../../../redux/actions";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Loader from '../../../../../components/Loader';
import CourseDetails from './courseDetails'
import AddMilestone from '../../../../../componentsNew/Common/addMilestone';
import CustomAlert from '../../../../../components/CustomAlert';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <Typography component="div" role="tabpanel" hidden={value !== index} id={`scrollable-auto-tabpanel-${index}`} aria-labelledby={`scrollable-auto-tab-${index}`} {...other} >
            {value === index && (
                <Box p={3}>{children}</Box>
            )}
        </Typography>
    );
}
TabPanel.propTypes = { children: PropTypes.node, index: PropTypes.any.isRequired, value: PropTypes.any.isRequired };
function a11yProps(index) {
    return { id: `scrollable-auto-tab-${index}`, "aria-controls": `scrollable-auto-tabpanel-${index}` };
}

const Index = (props) => {
    const params = useParams();
    const location = useLocation();
    const PhaseData = location?.state?.courseData;
    const StudentView = location?.state?.studentView ?? false;
    const [loading, setloading] = useState(false);
    const [tabs, setTabs] = useState(0);
    const [milestoneshow, setmilestoneshow] = useState(false)
    const navigate = useNavigate();
    const Openmilestone = () => setmilestoneshow(true);
    const Closemilestone = () => setmilestoneshow(false);
    const handleClick = () => {
        Closemilestone()
    };

    useEffect(() => {
        if (PhaseData?.courseName && !PhaseData?.activityName) {
            setloading(true);
            let payload = {
                "planId": params.id,
                "courseId": location?.pathname?.split('/').pop()
            };
            props?.GetMilestoneByCourse(payload);
        } else if (PhaseData?.activityName && !PhaseData?.courseName) {
            setloading(true);
            let payload = {
                "planId": params.id,
                "activityId": location?.pathname?.split('/').pop()
            };
            props?.GetMilestoneByActivity(payload);
        }
    }, []);
    useEffect(() => {
        if (props.createCourseMilestoneStatus) {
            props.createMilestonforCourseStatus(false)
            let payload = {
                "planId": params.id,
                "courseId": location?.pathname?.split('/').pop()
            };
            props?.GetMilestoneByCourse(payload);
            Closemilestone()
        }
    }, [props.createCourseMilestoneStatus])

    useEffect(() => {
        if (PhaseData?.courseName) {
            setloading(true)
            let payload = {
                "planId": params.id,
                "courseId": location?.pathname?.split('/').pop()
            }
            props?.GetMilestoneByCourse(payload)
        }
        if (PhaseData?.activityName) {
            setloading(true)
            let payload = {
                "planId": params.id,
                "activityId": location?.pathname?.split('/').pop()
            }
            props?.GetMilestoneByActivity(payload)
        }
    }, [props?.isMileStoneCourseStatus, props?.isactivityMilestoneStatus])

    useEffect(() => {
        let payload = {
            "planId": location?.state?.courseData?.planId,
            "courseId": location?.state?.courseData?.id
        }
        if (props?.isDeleteResource) {
            props?.DeleteMileStoneResourceStatus(false)
            props?.GetMilestoneByCourse(payload)
        }
        if (props?.deleteNotes) {
            props?.DeleteMileStoneNotesStatus(false)
            props?.GetMilestoneByCourse(payload)
        }
        if (props?.isMileStoneCourseStatus) {
            props?.GetMileStoneByCourseStatus(false);
        }
        if (props?.isUpdateNotes) {
            props?.CreateNotesStatus(false)
            props?.GetMilestoneByCourse(payload)
        }
        if (props?.isReplyNotes) {
            props?.GetMilestoneByCourse(payload)
        }
        if (props?.viewedUpdate) {
            props?.updateReadUpdateStatus(false)
            props?.GetMilestoneByCourse(payload)
        }
    }, [props?.isDeleteResource, props?.deleteNotes, props?.isUpdateNotes, props?.isReplyNotes, props?.viewedUpdate])

    // Tabs Change
    const handleSwitchTabs = (event, newValue) => {
        setTabs(newValue);
    }
    const handlegoBack = (e) => {
        e.preventDefault();
        navigate(-1)
    }

    return (
        <>
            {/* {loading && <Loader />} */}
            <div style={{ "width": "100%", "display": "flex", "justifyContent": "center", }}> {<CustomAlert />}</div>
            {milestoneshow &&
                <AddMilestone show={milestoneshow} onHide={() => Closemilestone()} handleClose={handleClick} />
            }
            <div className="milestone_Page">
                <div className="milestone_Page_Top">
                    <div className="milestone_Page_Top_Desktop">
                        <div className="milestone_Page_Top_Desktop_Title">
                            <p><span id="gobackbtn" onClick={(e) => handlegoBack(e)} ><KeyboardBackspaceIcon /></span>Course:{" "}{PhaseData?.courseName}</p>
                        </div>
                        <div className="milestone_Page_Top_Desktop_Btncnt">
                            <Button className="milestone_Page_Top_Desktop_Btncnt_PlanBtn">Enable for puchase for $4.99</Button>
                        </div>
                    </div>
                    <div className="milestone_Page_Top_Mobile">
                        <div className="milestone_Page_Top_Mobile__Section">
                            <div className="milestone_Page_Top_Mobile__Section_Title">
                                <p>My Work</p>
                            </div>
                        </div>
                        <div className="milestone_Page_Top_Mobile_Btncnt">
                            <Button className="milestone_Page_Top_Mobile_Btncnt_PlanBtn">Enable for puchase for $4.99</Button>
                        </div>
                    </div>
                </div>
                {props?.isCourseMileStone?.length > 0 &&
                    <div className="milestone_Page_Mid">
                        <div className="milestone_Page_Mid_Desktop">
                            <div className="milestone_Page_Mid_Desktop_TabCnt">
                                <Tabs value={tabs} onChange={handleSwitchTabs} variant="scrollable" scrollButtons allowScrollButtonsMobile aria-label="scrollable force tabs example">
                                    {props?.isCourseMileStone && props?.isCourseMileStone?.map((milestone) => {
                                        return (
                                            <Tab wrapped key={milestone?.id} label={milestone?.milestoneTitle} disableRipple className="Tab" />
                                        )
                                    })}
                                </Tabs>
                                {!StudentView &&
                                    <span onClick={Openmilestone} style={{ "cursor": "pointer" }} id="createmilestone">
                                        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect width="25" height="25" rx="5" fill="#1C84EE" />
                                            <path d="M12.5 6.25C12.7072 6.25 12.9059 6.33231 13.0524 6.47882C13.1989 6.62534 13.2812 6.82405 13.2812 7.03125V11.7188H17.9688C18.176 11.7188 18.3747 11.8011 18.5212 11.9476C18.6677 12.0941 18.75 12.2928 18.75 12.5C18.75 12.7072 18.6677 12.9059 18.5212 13.0524C18.3747 13.1989 18.176 13.2812 17.9688 13.2812H13.2812V17.9688C13.2812 18.176 13.1989 18.3747 13.0524 18.5212C12.9059 18.6677 12.7072 18.75 12.5 18.75C12.2928 18.75 12.0941 18.6677 11.9476 18.5212C11.8011 18.3747 11.7188 18.176 11.7188 17.9688V13.2812H7.03125C6.82405 13.2812 6.62534 13.1989 6.47882 13.0524C6.33231 12.9059 6.25 12.7072 6.25 12.5C6.25 12.2928 6.33231 12.0941 6.47882 11.9476C6.62534 11.8011 6.82405 11.7188 7.03125 11.7188H11.7188V7.03125C11.7188 6.82405 11.8011 6.62534 11.9476 6.47882C12.0941 6.33231 12.2928 6.25 12.5 6.25Z" fill="white" />
                                        </svg>
                                    </span>
                                }
                            </div>
                        </div>
                    </div>
                }
                {props?.isCourseMileStone?.length > 0 ? (
                    <div className="mywork_Page_Btm">
                        {props?.isCourseMileStone?.map((milestoneData, index) => {
                            return (
                                <TabPanel value={tabs} index={index}>
                                    <CourseDetails data={milestoneData} StudentView={StudentView} />
                                </TabPanel>
                            )
                        })}
                    </div>
                ) : (
                    <>
                        {!StudentView &&
                            <div className='MileStoneEmptyPage' >
                                <div className='MileStoneEmptyPage__Card'>
                                    <div className='MileStoneEmptyPage__Card__Titlecnt'>
                                        <p>You do not have created any Milestones for this course</p>
                                    </div>
                                    <div className='MileStoneEmptyPage__Card__btncnt' id="createmilestone" onClick={Openmilestone}>
                                        <p>Create Milestone</p>
                                    </div>
                                </div>
                            </div>
                        }
                    </>
                )}
            </div>
        </>
    )
}
const mapStateToProps = ({ studentData }) => {
    const { isDeleteResource, deleteNotes, isUpdateNotes, isReplyNotes, isCourseMileStone, viewedUpdate, isMileStoneCourseStatus, isactivityMilestoneStatus, createCourseMilestoneStatus } = studentData;
    return { isDeleteResource, deleteNotes, isUpdateNotes, isReplyNotes, isCourseMileStone, viewedUpdate, isMileStoneCourseStatus, isactivityMilestoneStatus, createCourseMilestoneStatus };
};

export default connect(mapStateToProps, { DeleteMileStoneNotesStatus, CreateNotesStatus, updateReadUpdateStatus, DeleteMileStoneResourceStatus, GetMilestoneByCourse, GetMilestoneByActivity, GetMileStoneByCourseStatus, GetMileStoneByActivityStatus, createMilestonforCourseStatus })(Index);     
