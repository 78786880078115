import React from "react";
import { useNavigate } from 'react-router';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "../../assets/css/owlCarousel.less";

const Index = (props) => {

    let navigate = useNavigate();

    let { CarouselData,CarouselOption } = props;

    const handleNavigate = () => {
        navigate('/advisor/transaction')
    }

    var CarouselList =
            CarouselData &&
            CarouselData.length > 0 &&
            CarouselData.map((element, key) => {
                return (
                   <div className="owl__item mt-4" >
                        <div className="advisor-connection-card" onClick={handleNavigate}>
                            <div className="advisor-connection-card__image">
                                <svg
                                    viewBox="0 0 48 48"
                                    height="48"
                                    width="48"
                                >
                                    {element.image}
                                </svg>
                            </div>
                            <div className="advisor-connection-card__title">
                                {element.title}
                            </div>
                            <div className="advisor-connection-card__counter">
                                {element.counter}
                                <span className="float-rights advisor-connection-card__next">
                                    <svg
                                        width="17"
                                        height="17"
                                        viewBox="0 0 17 17"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M2.95361 8.89844H13.9536"
                                            stroke="black"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M9.45361 4.39844L13.9536 8.89844L9.45361 13.3984"
                                            stroke="black"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </div> 
                );
            });
    return (
        <>
            {CarouselData && CarouselData.length > 0 && (
                <OwlCarousel
                    className="owl owl-OwlCarousel owl-theme"
                    {...CarouselOption}
                >
                    {CarouselList}
                </OwlCarousel>
            )}
        </>
    )
}

export default Index;
