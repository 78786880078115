import React, { Component } from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Link } from "react-router-dom";

class Index extends Component {
    constructor(props) {
        super(props);
    }
    state = {};
    handleBack(e) {
        e.preventDefault();
        this.props.handleClose(false);
    }
    render() {
        return (
            <>
                <div className="dashboardtop">
                    <Breadcrumb>
                        <Breadcrumb.Item
                            onClick={(e) => this.handleBack(e)}
                            active
                            className="cursorshow"
                        >
                            Dashboard
                        </Breadcrumb.Item>

                        <Breadcrumb.Item active className="text-dark">
                            Transaction list
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className="main-wrap d-flex pg-top ">
                    <span
                        onClick={(e) => this.handleBack(e)}
                        className="pt-2 arrow-view cursorshow"
                    >
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M13.3333 8.00065C13.3333 7.63246 13.0348 7.33398 12.6666 7.33398H3.33325C2.96506 7.33398 2.66659 7.63246 2.66659 8.00065C2.66659 8.36884 2.96506 8.66732 3.33325 8.66732H12.6666C13.0348 8.66732 13.3333 8.36884 13.3333 8.00065Z"
                                fill="#1B1C1E"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8.47149 2.86128C8.21114 2.60093 7.78903 2.60093 7.52868 2.86128L2.86201 7.52794C2.60166 7.78829 2.60166 8.2104 2.86201 8.47075L7.52868 13.1374C7.78903 13.3978 8.21114 13.3978 8.47149 13.1374C8.73184 12.8771 8.73184 12.455 8.47149 12.1946L4.27622 7.99935L8.47149 3.80409C8.73184 3.54374 8.73184 3.12163 8.47149 2.86128Z"
                                fill="#1B1C1E"
                            />
                        </svg>
                    </span>
                    <h3 className="network-top-text mb-4 ms-2 pt-1 none-text">
                        Transactions list
                        <p className="sub-heading">
                            Here you can check all of your transaction’s on
                            LeapBrains Platform
                        </p>
                    </h3>
                </div>
            </>
        );
    }
}

export default Index;
