import React from 'react';
import { Card, Button } from '@mui/material';
import { styled } from '@mui/system';

const StyledCard = styled(Card)({
    display: 'flex',
    alignItems: 'center',
    padding: '10px 10px',
    borderRadius: '10px',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    marginBottom: '10px',
    boxShadow: 'none',
    justifyContent: 'space-between',
});

const NumberCircle = styled('div')({
    width: '30px',
    height: '30px',
    minWidth: '30px', // Ensures the width doesn't shrink
    minHeight: '30px', // Ensures the height doesn't shrink
    borderRadius: '50%',
    backgroundColor: '#f44336', // Red color
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    fontWeight: 'bold',
    marginRight: '15px',
    flexShrink: 0, // Prevents flexbox from shrinking the circle
});


const FreeText = styled('span')({
    color: '#4caf50', // Green color
    fontWeight: 'bold',
    marginRight: '5px',
});

const TextContainer = styled('div')({
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
});

const BookNowComponent = () => {
    return (
        <StyledCard>
            <NumberCircle>1</NumberCircle>
            <TextContainer>
                <FreeText>free</FreeText>
                <span>session with brian</span>
            </TextContainer>

            <Button
                className="btn cta--rounded cta-primary"
                style={{
                    borderRadius: '10px',
                    marginLeft: 'auto', // This will push the button to the end
                    textTransform: 'none',
                    backgroundColor: '#1e88e5',
                    color: 'white',
                }}
            >
                <span>Book now ↻</span>
            </Button>
        </StyledCard>
    );
};

export default BookNowComponent;
