import React from "react";
import { Route, Routes } from "react-router-dom";
import DashboardLayout from "../layouts/Dashboard";
import DefaultLayout from "../layouts/Default";
import { RequireAuth } from "../utils/AuthUtils";

import Dashboard from "../pages/Advisor/Dashboard";

import TransactionList from '../pages/Advisor/DashboardNew/TransactionList';
import Library from "../pages/Advisor/Library";
import MyNetwork from "../pages/Advisor/MyNetwork";
import ExploreStudents from "../pages/Advisor/MyNetwork/ExploreStudents";
import MyProfile from "../pages/Advisor/MyProfile";
import Subscriptions from "../pages/Advisor/MyWork/Subscriptions";
import MyWork from "../pages/Advisor/MyWork";
import SemesterDetails from "../pages/Advisor/MyWork/SemesterDetails";
import CourseMilestone from "../pages/Advisor/MyWork/MilestoneDetail/CourseMilestone";
import ActivityMilestone from "../pages/Advisor/MyWork/MilestoneDetail/ActivityMilestone";
import Notification from '../pages/Advisor/Notification';
import Schedule from "../pages/Advisor/Schedule";
// import NetworkParentProfile from "../pages/Parent/MyNetwork/parent-profile"

const AdvisorRoutes = () => (
  <Routes>
    {/* Advisor DASHBOARD */}
    <Route exact path="advisor" element={<DashboardLayout />} >
      <Route path="dashboard" element={<RequireAuth><Dashboard /></RequireAuth>} />
      <Route path="transaction" element={ <TransactionList/>} />
    </Route>
    {/* Advisor otherpages */}
    <Route exact path="advisor" element={<DefaultLayout />} >
      <Route path="myStudents" element={<RequireAuth><Subscriptions/></RequireAuth>} />
      <Route path="myStudents/:id" element={<RequireAuth><MyWork/></RequireAuth>} />
      <Route path="myStudents/:id/plan/:planId" element={<RequireAuth><SemesterDetails/></RequireAuth>} />
      <Route path="myStudents/:id/plan/:planId/courseMilestone" element={<RequireAuth><CourseMilestone /></RequireAuth>} />
      <Route path="myStudents/:id/plan/:planId/activityMilestone" element={<RequireAuth><ActivityMilestone /></RequireAuth>} />
      {/* <Route path="mywork/student" element={<RequireAuth> <MyWork /></RequireAuth>} />
      <Route path="mywork/student/:id" element={<RequireAuth><SectionMyWorks /></RequireAuth>} />
      <Route path="mywork/student/plan/:id" element={<RequireAuth><PhaseDetail /></RequireAuth>} />
      <Route path="mywork/student/plan/:id/course" element={<RequireAuth><MilestoneDetails /></RequireAuth>} />
      <Route path="mywork/student/plan/:id/activity" element={<RequireAuth><ActivityMilestoneDetails /></RequireAuth>} />
      <Route path="mywork/student/plan/:id/course/mile" element={<RequireAuth><PlanCourseDetail /></RequireAuth>} /> */}
      <Route path="myprofile" element={<RequireAuth><MyProfile /></RequireAuth>} />
      <Route path="mynetwork" element={<RequireAuth><MyNetwork /></RequireAuth>} />
      <Route path="mynetwork/exploreStudents" element={<RequireAuth><ExploreStudents/></RequireAuth>} />
      <Route path="library" element={<RequireAuth><Library /></RequireAuth>} />
      <Route exact path="schedule" element={<RequireAuth><Schedule /></RequireAuth>} />
      <Route exact path="notification" element={<RequireAuth><Notification /></RequireAuth>} />
    </Route>
  </Routes>

);

export default AdvisorRoutes;