import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "./advMyProfile.less";
import CustomAlert from "../../../components/CustomAlert";
import PersonalInfo from "../MyProfile/PersonalInfo";
import CurrentStatus from "../MyProfile/CurrentStatus";
import CourseActivity from "../MyProfile/CourseActivity";
import OfficeHours from "../MyProfile/OfficeHours";
import PriceSetting from "../MyProfile/PriceSetting";
import Select, { components } from "react-select";
import { clearMessage } from '../../../redux/actions'
import { Box, Grid, Tab, Tabs, Typography } from "@mui/material";
import {
    ContactEmergencyOutlined,
    LocalAtmOutlined,
    PersonOutlineOutlined,
    QueryBuilderOutlined,
    SchoolOutlined,
} from "@mui/icons-material";
import PersonIcon from '@mui/icons-material/Person';
import Pricing from "./Pricing";
import { useLocation } from "react-router-dom";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            className="Advisor-MyProfile__mainCnt"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
             {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
             )}
        </div>
    );
}
const Menulists = [
    {
        value: 0,
        icon: <PersonOutlineOutlined />,
        label: "Personal Settings"
    },
    {
        value: 1,
        icon: <ContactEmergencyOutlined />,
        label: "Current Status"
    },
    {
        value: 2,
        icon: <SchoolOutlined />,
        label: "Courses & Activities"
    },
    {
        value: 3,
        icon: <LocalAtmOutlined />,
        label: "Pricing Packages"
    },
    {
        value: 4,
        icon: <QueryBuilderOutlined />,
        label: "Office hours"
    }
]
const { ValueContainer, Placeholder } = components;
const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>{props.selectProps.placeholder}</Placeholder>
            {React.Children.map(children, child => child && child.type !== Placeholder ? child : null)}
        </ValueContainer>
    );
};

const Index = (props) => {
    const [tab, setTab] = useState(0);
    const { state } = useLocation();

    useEffect(() => {
        if (state?.tab === "currentStatus") {
            setTab(1)
        } else {
            setTab(0)
        }
    }, [])
    

    const changeTab = (e, newValue) => {
        setTab(newValue);
        props.clearMessage()
    };
    const handleSelectChange = (e) => {
        setTab(e?.value);
        props.clearMessage()
    }
    
    return (
        <div className="Advisor-MyProfile__mainCnt">
            <div className="alertComponent">
                {Object.keys(props.errorList).length === 0 && <CustomAlert />}
            </div>
            <Grid container spacing={5}>
                <Grid item xs={12} sm={12} md={12} lg={11} xl={10}>
                    <div className="Advisor_Profile_Sec">
                        <PersonIcon className="Advisor_Profile_Sec__profileIcon" />
                        <p className="Profile_Title">
                        Your Advisor profile</p>
                    </div>
                    <div className="Advisor_Profile_Sec_Mobile">
                        <PersonIcon className="Advisor_Profile_Sec_Mobile__profileIcon" />
                        <p className="Profile_Title">Your Advisor profile</p>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={11} xl={10}>
                    <Box className="__Top_Mobile"
                        sx={{
                            maxWidth: { xs: "90%",sm: "90%", md: "100%" },
                            bgcolor: "#fff", borderRadius: "10px",border: "none",marginInline: "auto",
                        }}
                    >
                        <div className="input-floating-label mb-0" style={{zIndex: 10}}>
                            <Select options={Menulists} onChange={handleSelectChange} isSearchable= {false}
                                defaultValue={Menulists[0]} value={Menulists[tab]}
                                getOptionLabel={e => (
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        {e.icon}
                                        <span style={{ marginLeft: 10 }}>{e.label}</span>
                                    </div>
                                )}
                                placeholder=" " components={{ ValueContainer: CustomValueContainer, IndicatorSeparator: () => null }}
                                styles={{ option: (provided, state) => ({ ...provided, cursor: 'pointer', }), container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible" }), valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px' }), placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10, display: state.isFocused || state.isSelected || state.selectProps.inputValue ? 'none' : 'block', }) }}
                            />
                        </div>
                    </Box>
                    <Box className="tab_card"
                        sx={{
                            maxWidth: { xs: "90%",sm: "90%", md: "100%", lg: "100%" },
                            bgcolor: "#fff", borderRadius: "10px",border: "1px solid #CDCDCD",marginInline: "auto",
                        }}
                    >
                        <Tabs 
                            value={tab} onChange={changeTab} variant="scrollable" scrollButtons 
                            allowScrollButtonsMobile aria-label="scrollable force tabs example"
                        >
                            <Tab
                                icon={<PersonOutlineOutlined />}
                                iconPosition="start"
                                label="Personal Settings"
                                disableRipple
                                className="personalSettings"
                                id="personalSettings"
                            />
                            <Tab
                                icon={<ContactEmergencyOutlined />}
                                iconPosition="start"
                                label="Current Status"
                                disableRipple
                                className="currentStatus"
                                id="currentStatus"
                            /> 
                            {
                                props?.userProfile?.advisorProfile?.helpWithSpecificCourseActivity &&
                                <Tab
                                    icon={<SchoolOutlined />}
                                    iconPosition="start"
                                    label="Courses & Activities"
                                    disableRipple 
                                    className="coursesActivities"
                                    id="coursesActivities"
                                />
                            }
                            <Tab
                                icon={<LocalAtmOutlined />}
                                iconPosition="start"
                                label="Pricing Packages"
                                disableRipple
                                className="pricingPackages"
                                id="pricingPackages"
                            />
                            <Tab
                                icon={<QueryBuilderOutlined />}
                                iconPosition="start"
                                label="Office hours"
                                disableRipple
                                className="officeHours"
                                id="officeHours"
                            />
                        </Tabs>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={11} xl={10}>
                    <Box
                        sx={{
                            maxWidth: { xs: "90%",sm: "90%", md: "100%", lg: "100%" },
                            bgcolor: "none", borderRadius: "10px",marginInline: "auto",
                        }}
                    >
                        <TabPanel value={tab} index={0}>
                            <PersonalInfo />
                        </TabPanel>
                        <TabPanel value={tab} index={1}>
                            <CurrentStatus />
                        </TabPanel>
                        { props?.userProfile?.advisorProfile?.helpWithSpecificCourseActivity &&
                            <TabPanel value={tab} index={2}>
                                <CourseActivity />
                            </TabPanel>
                        }
                        <TabPanel value={tab} index={props?.userProfile?.advisorProfile?.helpWithSpecificCourseActivity ? 3 : 2 }>
                            {/* <PriceSetting /> */}
                            <Pricing />
                        </TabPanel>
                        <TabPanel value={tab} index={props?.userProfile?.advisorProfile?.helpWithSpecificCourseActivity ? 4 : 3 }>
                            <OfficeHours />
                        </TabPanel>
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
};

const mapStateToProps = ({ commonData, userProfileData }) => {
    const { message, errorList } = commonData;
    const { userProfile } = userProfileData;
    return {  message, errorList, userProfile };
  };
  export default connect(mapStateToProps, { clearMessage })(Index);